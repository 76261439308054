import { getInitialsFromUserName } from '../../utils/String'
import { ImageRender } from '../../components'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'

const ImageAvatarFallback = (props) => {
  const {
    name,
    profilePic,
    style,
    openImage = () => {},
    s3Obj,
    ...rest
  } = props
  const initials = getInitialsFromUserName(name)
  return profilePic ? (
    <Box
      sx={{
        ...style,
        overflow: 'hidden',
        borderRadius: '50%'
      }}
    >
      <ImageRender
        style={{
          width: '100%',
          height: '100%',
          textAlign: 'center',
          objectFit: 'cover',
          color: 'transparent',
          textIndent: '10000px'
        }}
        fallback={<Avatar sx={style}>{initials}</Avatar>}
        showLoader={false}
        src={profilePic}
        s3Obj={s3Obj}
        effect="blur"
        onClick={() => {
          openImage(profilePic)
        }}
        {...rest}
      />
    </Box>
  ) : (
    <Avatar sx={style}>{initials}</Avatar>
  )
}

export default ImageAvatarFallback
