import {
  FETCH_COLLECTIONS,
  ADD_TO_COLLECTIONS,
  UPDATE_ADDED_COLLECTIONS,
  UPDATE_ACTIVE_COLLECTION,
  UPDATE_CHILDREN_COLLECTIONS,
  UPDATE_COLLECTIONS,
  SET_COLLECTIONS_EXPANDED,
  DELETE_COLLECTION,
  REFRESH_COLLECTIONS
} from '../../actionTypes'
import _ from 'lodash'

const initialState = {
  collections: null,
  expanded: {},
  activeCollection: {
    id: 'all',
    label: 'All Files',
    type: 'defaultPrelist',
    serverType: 'all'
  }
}

function reducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case FETCH_COLLECTIONS: {
      return {
        ...state,
        collections: payload,
        expanded: {}
      }
    }
    case REFRESH_COLLECTIONS: {
      let collections = _.cloneDeep(state.collections)
      const setA = new Set(collections.map((item) => item.id))
      const setB = new Set(payload.map((item) => item.id))
      collections = collections.filter((item) => setB.has(item.id))
      payload.forEach((item) => {
        if (!setA.has(item.id)) {
          collections.push(item)
        }
      })
      return {
        ...state,
        collections
      }
    }
    case ADD_TO_COLLECTIONS: {
      const { id, parent_id } = payload
      const collections = _.cloneDeep(state.collections)
      if (!collections) {
        return {
          ...state,
          collections: [payload]
        }
      }
      if (parent_id) {
        addChildToParent(parent_id, payload, collections)
        return {
          ...state,
          collections
        }
      } else {
        return {
          ...state,
          collections: [...collections, payload]
        }
      }
    }
    case UPDATE_ADDED_COLLECTIONS: {
      const newpayload = _.cloneDeep(payload)
      const { id, parent_id } = newpayload
      const collections = _.cloneDeep(state.collections)
      if (parent_id) {
        updateChildToParent(parent_id, newpayload, collections)
        return {
          ...state,
          collections
        }
      } else {
        const index = collections.findIndex((item) => item.id === id)
        if (index !== -1) {
          collections[index] = newpayload
        }
        return {
          ...state,
          collections
        }
      }
    }
    case UPDATE_ACTIVE_COLLECTION: {
      return {
        ...state,
        activeCollection: payload
      }
    }
    case UPDATE_CHILDREN_COLLECTIONS: {
      const { children, parent_id } = payload
      const collections = _.cloneDeep(state.collections)
      updateChildrenToParent(parent_id, children, collections)
      if (state.activeCollection.id === parent_id) {
        const activeCollection = _.cloneDeep(state.activeCollection)
        activeCollection.children = children
        return {
          ...state,
          collections,
          activeCollection
        }
      }
      return {
        ...state,
        collections
      }
    }
    case UPDATE_COLLECTIONS: {
      const { id } = payload
      const collections = _.cloneDeep(state.collections)
      let activeCollection = _.cloneDeep(state.activeCollection)
      if (id === activeCollection.id) {
        activeCollection = { ...activeCollection, ...payload }
        const { is_public, access_config } = payload
        updateNestedObject(activeCollection, 'is_public', is_public)
        updateNestedObject(activeCollection, 'access_config', access_config)
      }
      updateCollection(id, payload, collections)
      return {
        ...state,
        collections,
        activeCollection
      }
    }
    case SET_COLLECTIONS_EXPANDED: {
      return {
        ...state,
        expanded: { ...payload }
      }
    }
    case DELETE_COLLECTION: {
      const { id } = payload
      const collections = _.cloneDeep(state.collections)
      deleteNodeById(id, collections)
      const activeCollection = _.cloneDeep(state.activeCollection)
      const { nesting = [], id: activeId } = activeCollection || {}
      if (activeId === id || nesting.includes(id)) {
        activeCollection.id = 'all'
        activeCollection.label = 'All Files'
        activeCollection.type = 'defaultPrelist'
        activeCollection.serverType = 'all'
        return {
          ...state,
          collections,
          activeCollection
        }
      }
      return {
        ...state,
        collections
      }
    }

    default:
      return state
  }
}

function addChildToParent(parentId, newChild, nestedObject) {
  function recursiveAddChild(nodes) {
    for (const node of nodes) {
      if (node.id === parentId) {
        node.children = node.children || []
        node.hasChildren = true
        node.children.push(newChild)
        return true
      }
      if (node.children && recursiveAddChild(node.children)) {
        return true
      }
    }
    return false
  }
  recursiveAddChild(nestedObject)
}

function updateChildToParent(parentId, newChild, nestedObject) {
  function recursiveAddChild(nodes) {
    for (const node of nodes) {
      if (node.id === parentId) {
        const children = node.children || []
        const index = children.findIndex((item) => item.id === newChild.id)
        if (index !== -1) {
          children[index] = newChild
        }
        node.children = children
        return true
      }
      if (node.children && recursiveAddChild(node.children)) {
        return true
      }
    }
    return false
  }
  recursiveAddChild(nestedObject)
}

function updateChildrenToParent(parentId, newChildren, nestedObject) {
  function recursiveAddChild(nodes) {
    for (const node of nodes) {
      if (node.id === parentId) {
        node.children = newChildren
        node.hasChildren = newChildren.length > 0
        return true
      }
      if (node.children && recursiveAddChild(node.children)) {
        return true
      }
    }
    return false
  }
  recursiveAddChild(nestedObject)
}

function updateCollection(id, obj, nestedObject) {
  function recursiveSearch(nodes) {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].id === id) {
        nodes[i] = { ...nodes[i], ...obj }
        const { is_public, access_config } = obj
        updateNestedObject(nodes[i], 'is_public', is_public)
        updateNestedObject(nodes[i], 'access_config', access_config)
        return true
      }
      if (nodes[i].children && recursiveSearch(nodes[i].children)) {
        return true
      }
    }
    return false
  }
  recursiveSearch(nestedObject)
}

function updateNestedObject(obj, propName, propValue) {
  obj[propName] = propValue
  if (obj.children && obj.children.length > 0) {
    for (const child of obj.children) {
      updateNestedObject(child, propName, propValue)
    }
  }
}

function deleteNodeById(nodeId, nestedObject) {
  function recursiveDeleteNode(nodes, parentNode) {
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]
      if (node.id === nodeId) {
        nodes.splice(i, 1)
        if (parentNode) {
          parentNode.hasChildren = nodes.length > 0
        }
        return true
      }
      if (node.children && recursiveDeleteNode(node.children, node)) {
        return true
      }
    }
    return false
  }
  recursiveDeleteNode(nestedObject)
}

export default reducer
