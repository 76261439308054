import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    background: '#fff',
    color: '#000'
  },
  fileWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '100%',
    width: '100%'
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E5E5E5'
  },
  contentWrapper: {
    height: '100%',
    width: '100%'
  },
  toolbarWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '70px',
    justifyContent: 'center'
  },
  title: {
    fontSize: '20px',
    color: '#000'
  },
  subtitle: {
    fontSize: '14px',
    color: '#797979'
  }
}))

export { useStyles }
