export const getInitialsFromUserName = (userName) => {
  const names = userName?.split(' ')
  let initials = names?.[0]?.substring(0, 1).toUpperCase()
  if (names?.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase()
  }
  return initials
}

export const checkArrOfObjHasAtleastOneValue = (arr) => {
  if (arr) {
    for (let i = 0; i < arr.length; i++) {
      const obj = arr[i]
      for (const key in obj) {
        if (
          obj.hasOwnProperty(key) &&
          obj[key] !== undefined &&
          obj[key] !== null &&
          obj[key] !== '' &&
          obj[key] !== [] &&
          obj[key] !== {}
        ) {
          return true
        }
      }
    }
  }
  return false
}
