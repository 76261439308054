import React, { useState } from 'react'

import IconButton from '@mui/material/IconButton'
import Box from '@material-ui/core/Box'
import Grid from '@mui/material/Grid'
import { useStyles } from './styles'
import _ from 'lodash'
import ImageIcon from '@mui/icons-material/Image'
import Badge from '@mui/material/Badge'
import Tooltip from '@mui/material/Tooltip'
import { resumeStyle } from './resumeStyle'
import { checkArrOfObjHasAtleastOneValue } from '../../utils/String'
import { Button, ImageAvatarFallback } from '../../components'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { checkUserRoleSuperUser, checkUserRoleViewer } from '../../utils/User'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import DownloadIcon from '@mui/icons-material/Download'
import { PlaylistRemove } from '@mui/icons-material'
import {
  updateParsedResumeData,
  uploadResumeImage
} from '../../store/People/Actions/UpdatePeople/UpdatePeople'
import ReactContentEditable from '../../components/ReactContentEditable'
import DisplayTags from './DisplayTags'

const ResumeViewer = ({
  selectedResumeData,
  openImage,
  s3Obj,
  selectedPeopleData = {},
  selectedResumeIndex,
  handleMasterMappingDelete,
  setShowTagDrawer,
  tagState,
  handlePDFViewer,
  downloadImage,
  pageType = ''
}) => {
  const isSuperUser = checkUserRoleSuperUser()
  const [isSaving, setIsSaving] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [selectedPerson, setSelectedPerson] = useState({
    ...selectedPeopleData
  })
  const [selectedResume, setSelectedResume] = useState({
    ...selectedResumeData
  })
  const [selectedResumeParsedData, setResumeParsedData] = useState({
    ...selectedResumeData?.resume_data
  })

  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const {
    thumbnail_url = '',
    profile_picture_location,
    screenshots = [],
    proposal_name,
    proposal_id,
    page_number,
    tags = []
  } = selectedResume

  const [profilePic, setProfilePic] = useState(profile_picture_location)

  const imagePayload = _.isEmpty(screenshots) ? thumbnail_url : screenshots
  const disabled = status === 'merging' || isSaving
  const { leftPane, rightPane } = resumeStyle
  const classes = useStyles()

  const handleResumeOnEdit = (key, value, index, childKey) => {
    const parsedData = _.cloneDeep(selectedResumeParsedData)
    if (key === 'name') {
      key = 'emp_name'
    } else if (key === 'role') {
      key = 'emp_job_title'
      index = 0
      childKey = 'job_title'
      parsedData.emp_job_title = [
        {
          job_title: ''
        }
      ]
    }
    if (childKey) {
      parsedData[key][index][childKey] = value
    } else {
      parsedData[key] = value
    }

    setResumeParsedData({
      ...parsedData
    })
  }

  const saveResumeChanges = () => {
    setLoading(true)
    const body = {
      id: selectedResume.id,
      resume_data: selectedResumeParsedData
    }

    dispatch(
      updateParsedResumeData(body, () => {
        setLoading(false)
        setEditMode(false)
      })
    )
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const uploadProfilePic = async (e) => {
    const file = e.target.files[0]
    const base64 = await convertBase64(file)

    const body = {
      profile_picture_location: base64,
      proposal_id
    }

    dispatch(
      uploadResumeImage(body, (res) => {
        console.log('res', res)
        // setIsSaving(false)

        setProfilePic(res.data.profile_picture_location)
      })
    )
  }

  return (
    <Box
      id="resume_parent"
      sx={{
        wordBreak: 'break-word',
        height: '100%'
      }}
    >
      {pageType !== 'dashboard' && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            fontWeight: '600',
            fontSize: '16px',
            padding: '6px 10px',
            paddingTop: '3px',
            borderBottom: '1px solid #e4e4e7',
            minHeight: '32px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}
          >
            {editMode ? (
              <p
                style={{
                  // position: 'absolute',
                  right: 0,
                  top: 0,
                  margin: 0,
                  fontSize: '10px',
                  background: 'rgb(106 36 245)',
                  color: 'white',
                  fontWeight: '600',
                  padding: '5px 10px',
                  borderRadius: '5px'
                }}
              >
                EDIT MODE ON
              </p>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 5
                }}
              >
                <Tooltip title="View Proposal">
                  <p
                    style={{
                      margin: '0',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 3,
                      cursor: 'pointer'
                    }}
                    onClick={() => handlePDFViewer(proposal_id, page_number)}
                  >
                    {proposal_name}{' '}
                    {/* <Launch
                      sx={{
                        width: '16px',
                        height: '16px'
                      }}
                    /> */}
                  </p>
                </Tooltip>

                {/* <DisplayTags
                  tags={tags}
                  tagState={tagState}
                  setShowTagDrawer={setShowTagDrawer}
                /> */}
              </div>
            )}
            {isSaving ? (
              <IconButton>
                <CircularProgress size={24} />
              </IconButton>
            ) : editMode ? (
              <div>
                <Button
                  style={{
                    padding: '2px 10px'
                  }}
                  className={classes.tertiaryButton}
                  onClick={() => setEditMode(false)}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    padding: '2px 10px',
                    borderRadius: '4px',
                    backgroundColor: '#18181b',
                    color: 'white',
                    marginLeft: '5px'
                  }}
                  onClick={() => saveResumeChanges()}
                >
                  {loading ? (
                    <CircularProgress
                      sx={{
                        color: 'white',
                        padding: '4px'
                      }}
                      size={16}
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            ) : (
              <div>
                {isSuperUser && (
                  <>
                    <Tooltip title="Edit Resume">
                      <IconButton
                        disabled={disabled}
                        onClick={() => {
                          setEditMode(!editMode)
                        }}
                      >
                        <EditIcon
                          sx={{
                            width: '16px',
                            height: '16px'
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </>
                )}

                {/* <Tooltip title="View Screenshot">
                  <IconButton
                    disabled={disabled}
                    onClick={() => openImage(imagePayload)}
                  >
                    <ImageIcon
                      sx={{
                        width: '16px',
                        height: '16px'
                      }}
                    />
                  </IconButton>
                </Tooltip> */}

                <Tooltip title="Download Screenshot">
                  <IconButton
                    disabled={disabled}
                    onClick={() => downloadImage(imagePayload, selectedResume)}
                  >
                    <DownloadIcon
                      sx={{
                        width: '16px',
                        height: '16px'
                      }}
                    />
                  </IconButton>
                </Tooltip>

                <Tooltip title={"Not " + selectedPerson?.name + "?"}>
                  <IconButton
                    disabled={disabled}
                    onClick={() =>
                      handleMasterMappingDelete(
                        selectedPerson,
                        selectedResumeIndex,
                        'remove'
                      )
                    }
                  >
                    <PlaylistRemove
                      sx={{
                        width: '16px',
                        height: '16px'
                      }}
                    />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Not a Resume?">
                  <IconButton
                    disabled={disabled}
                    onClick={() =>
                      handleMasterMappingDelete(
                        selectedPerson,
                        selectedResumeIndex,
                        'delete'
                      )
                    }
                  >
                    <DeleteIcon
                      sx={{
                        width: '16px',
                        height: '16px'
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </Box>
        </Box>
      )}
      <Box
        sx={{
          // border: editMode ? '1px solid rgb(106 36 245)' : '1px solid #d4d4d8',
          borderRadius: '4px',
          padding: '20px 40px',
          textAlign: 'justify',
          position: 'relative',
          height: '90vh',
          overflow: 'auto'
        }}
      >
        {!_.isEmpty(selectedResumeParsedData) && (
          <Grid container>
            <Grid item sm={6} md={4} lg={3} sx={{ padding: '20px' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '15px',
                  padding: '10px 0px'
                }}
              >
                <Badge>
                  {/* <label htmlFor="profile">
                    x
                    <input
                      id="profile"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={(e) => {
                        uploadProfilePic(e)
                      }}
                    />
                  </label> */}
                  <ImageAvatarFallback
                    name={name}
                    profilePic={profilePic}
                    style={{
                      height: '180px',
                      width: '180px',
                      fontSize: '40px'
                    }}
                    openImage={openImage}
                    s3Obj={s3Obj}
                  />
                </Badge>
              </Box>

              {Object.keys(leftPane)?.map((key, index) => {
                const { label, style, type, location, children } =
                  leftPane[key] || {}
                const { value: labelValue, style: labelStyle } = label || {}
                if (type === 'string') {
                  const displayValue = selectedResumeParsedData[key]
                  return (
                    displayValue && (
                      <Box key={index}>
                        {label && <Box style={labelStyle}>{labelValue}</Box>}
                        <Box style={style}>
                          <ReactContentEditable
                            disabled={!editMode}
                            style={style}
                            className="contentEditableStyle"
                            html={displayValue}
                            onChange={(e) => {
                              console.log('e', key, e.currentTarget.innerText)
                              handleResumeOnEdit(key, e.currentTarget.innerText)
                            }}
                            // placeholder={placeholder}
                            // disabled={saving}
                          />
                        </Box>
                      </Box>
                    )
                  )
                } else if (type === 'array') {
                  const displayValueMap =
                    location === 'people'
                      ? selectedPeopleData[key]
                      : selectedResumeParsedData[key] || []
                  const exists =
                    checkArrOfObjHasAtleastOneValue(displayValueMap)
                  return (
                    exists && (
                      <Box key={index}>
                        {label && <Box style={labelStyle}>{labelValue}</Box>}
                        {displayValueMap &&
                          displayValueMap?.map((displayValueObj, index2) => {
                            return (
                              <Box key={index2}>
                                {Object.keys(displayValueObj || {})?.map(
                                  (childKey, index3) => {
                                    const { style: childStyle } =
                                      children[childKey] || {}
                                    const childValue =
                                      displayValueObj[childKey] || ''
                                    return (
                                      childValue && (
                                        <Box style={childStyle} key={index3}>
                                          <ReactContentEditable
                                            disabled={!editMode}
                                            style={style}
                                            className="contentEditableStyle"
                                            html={childValue}
                                            onChange={(e) =>
                                              handleResumeOnEdit(
                                                key,
                                                e.currentTarget.innerText,
                                                index2,
                                                childKey
                                              )
                                            }
                                            // placeholder={placeholder}
                                            // disabled={saving}
                                          />
                                        </Box>
                                      )
                                    )
                                  }
                                )}
                              </Box>
                            )
                          })}
                      </Box>
                    )
                  )
                }
                return <></>
              })}
            </Grid>
            <Grid item sm={6} md={8} lg={9} sx={{ padding: '20px' }}>
              {Object.keys(rightPane)?.map((key, index) => {
                const { label, style, type, location, children } =
                  rightPane[key] || {}
                const { value: labelValue, style: labelStyle } = label || {}

                if (type === 'string' && selectedResumeParsedData[key]) {
                  const displayValue = selectedResumeParsedData[key]
                  return (
                    displayValue && (
                      <Box key={key + index}>
                        {label && <Box style={labelStyle}>{labelValue}</Box>}
                        <Box style={style}>
                          <ReactContentEditable
                            disabled={!editMode}
                            style={style}
                            className="contentEditableStyle"
                            html={displayValue}
                            onChange={(e) => {
                              handleResumeOnEdit(key, e.currentTarget.innerText)
                            }}
                            // placeholder={placeholder}
                            // disabled={saving}
                          />
                        </Box>
                      </Box>
                    )
                  )
                } else if (type === 'array') {
                  const displayValueMap =
                    location === 'people'
                      ? selectedPeopleData[key]
                      : selectedResumeParsedData[key]
                  const exists =
                    checkArrOfObjHasAtleastOneValue(displayValueMap)
                  return (
                    exists && (
                      <Box key={index}>
                        {label && <Box style={labelStyle}>{labelValue}</Box>}
                        {displayValueMap &&
                          displayValueMap?.map((displayValueObj, index2) => {
                            return (
                              <Box key={index2}>
                                {Object.keys(displayValueObj || {})?.map(
                                  (childKey, index3) => {
                                    const { style: childStyle } =
                                      children[childKey] || {}
                                    const childValue =
                                      displayValueObj[childKey] || ''
                                    return (
                                      childValue && (
                                        <Box
                                          style={childStyle}
                                          key={childKey + index3}
                                        >
                                          <ReactContentEditable
                                            disabled={!editMode}
                                            style={style}
                                            className="contentEditableStyle"
                                            html={childValue}
                                            onChange={(e) => {
                                              handleResumeOnEdit(
                                                key,
                                                e.currentTarget.innerText,
                                                index2,
                                                childKey
                                              )
                                            }}
                                            // placeholder={placeholder}
                                          />
                                        </Box>
                                      )
                                    )
                                  }
                                )}
                              </Box>
                            )
                          })}
                      </Box>
                    )
                  )
                }
                return <></>
              })}
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  )
}

export default ResumeViewer
