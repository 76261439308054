import { useEffect, useState } from 'react'
import * as React from 'react'
import { useStyles } from './styles'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'
import Grid from '@mui/material/Grid'
import Tab from '../../components/Tabs'
import ErrorInSights from './ErrorInSights'
import {
  Viewer,
  SpecialZoomLevel,
  Position,
  Tooltip,
  MinimalButton,
  Icon
} from '@react-pdf-viewer/core'
import { searchPlugin } from './customKeywordSearch'
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation'
import { highlightPlugin } from '@react-pdf-viewer/highlight'
import AIGeneration from './AIGeneration'
import { cloneDeep, findIndex } from 'lodash'
import { ReactComponent as Robot } from '../../assets/images/robot.svg'
import SvgIcon from '@material-ui/core/SvgIcon'
import {
  analyticsErrorUpdate,
  getWinStrategy,
  ShareAnalytics
} from '../../store/api'
import AIGeneratePlugin from './AIGeneratePlugin'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as BackImg } from '../../assets/images/back.svg'
import Box from '@material-ui/core/Box'
import { Section, SectionFixed, Container } from '../../components/Container'
import ChatBot from '../../containers/ChatBot'
import Charts from './Charts'
import { Resizable } from 'react-resizable'
import { useResizeDetector } from 'react-resize-detector'
import Loader from '../../components/Loader'
// import MarkdownIt from 'markdown-it';
import markdownit from 'markdown-it'
import { exampleQuestions } from './questions'
import { checkUserRoleSuperUser, checkUserRoleViewer } from '../../utils/User'
import { toast } from 'react-toastify'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MUIToolTip from '@mui/material/Tooltip'
import SendIcon from '@mui/icons-material/Send'
import ShareIcon from '@mui/icons-material/Share'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import Popover from '@mui/material/Popover'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import TextField from '@mui/material/TextField'
import { Button } from '../../components'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import Error from '../../components/Error'
import PublicWinStrategy from './PublicWinStrategy'
import Compliance from './AnalyticsCompliance'
import InfoIcon from '@mui/icons-material/Info'
import { useSelector, useDispatch } from 'react-redux'

const PDFViewer = (props) => {
  const {
    url,
    isAnalytics,
    metaErrorData = [],
    PDFData,
    setShowPDF = () => {},
    shareType = 'private',
    shareId = '',
    inProcess
  } = props
  let { pageNumber } = props
  pageNumber = pageNumber - 1 > 0 ? pageNumber - 1 : pageNumber
  const classes = useStyles()
  const {
    webviewer,
    page,
    infoWrapper,
    infoContainer,
    borderBot,
    titleContainer,
    iconButton,
    icon,
    pdfTitle
  } = classes
  const toolbarPluginInstance = toolbarPlugin()
  const { Toolbar, renderDefaultToolbar } = toolbarPluginInstance
  const [currentHighlighted, setCurrentHighlighted] = useState()
  const [errorData, setErrorData] = useState(metaErrorData)
  const [activeTab, setActiveTab] = useState(0)
  const [aiPlugin, setAIPlugin] = useState(null)
  const [showError, setShowError] = useState(!!isAnalytics)
  const [selectedErrorType, setSelectedErrorType] = useState(
    Object.keys(metaErrorData || {})?.[0]
  )
  const [pdfCurrentPage, setPdfCurrentPage] = useState(0)
  const { document_name = '', id = '' } = PDFData
  const { width, height, ref } = useResizeDetector()
  const [pdfWidth, setPdfWidth] = useState(0)
  const [toolbarWidth, setToolbarWidth] = useState(0)
  const [initialPromptList, setInitialPromptList] = useState({})
  const [chatTextBox, setChatTextBox] = useState('')
  const exampleQuestionKeys = Object.keys(exampleQuestions)
  const isSuperUser = checkUserRoleSuperUser()
  const [chatLoading, setChatLoading] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [openShareDialog, setOpenShareDialog] = useState(false)
  const [shareableLink, setShareableLink] = useState('')
  const [customValue, setCustomValue] = useState('14')
  const [linkLoading, setLinkLoading] = useState(false)
  const [isCopied, setIsCopied] = useState(false)
  const [selectedOption, setSelectedOption] = useState('custom')

  const isUserViewer = checkUserRoleViewer()
  const currentUserName = useSelector((state) => state.authenticate.user?.domain?.user_name || '')

  useEffect(() => {
    const handleWinStrategy = async () => {
      const res = await getWinStrategy({ id })
      if (res.status === 200) {
        setInitialPromptList(res.data.result)
      }
    }
    if (id && shareType === 'private') {
      handleWinStrategy()
    }
  }, [id])

  useEffect(() => {
    handleOnResize()
  }, [width])

  const handleOnResize = (event, obj) => {
    if (event && obj) {
      const newEWidth = obj.size.width
      const newSWidth = width - obj.size.width - 1
      if ((newSWidth / width) * 100 > 30 && (newEWidth / width) * 100 > 30) {
        setPdfWidth(newEWidth)
        setToolbarWidth(newSWidth)
      }
    } else {
      if (pdfWidth && toolbarWidth) {
        const sWidth = toolbarWidth
        const eWidth = width - sWidth - 1
        setPdfWidth(eWidth)
        setToolbarWidth(sWidth)
      } else {
        const eWidth = Math.round((60 / 100) * width)
        const sWidth = width - eWidth - 1
        setPdfWidth(eWidth)
        setToolbarWidth(sWidth)
      }
    }
  }

  const pdfName = document_name
  const renderHighlightTarget = (props) =>
    !showError && (
      <div
        style={{
          background: '#eee',
          display: 'flex',
          position: 'absolute',
          left: `${props.selectionRegion.left}%`,
          top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
          transform: 'translate(0, 8px)',
          zIndex: 1
        }}
      >
        {shareType === 'private' && (
          <div
            style={{
              display: 'flex',
              /* flexDirection: 'column', */
              background: 'black',
              color: 'white',
              borderRadius: '5px',
              alignItems: 'center'
            }}
          >
            <div style={{ padding: '5px' }}>
              <SvgIcon>
                <Robot />
              </SvgIcon>
            </div>
            <div
              onClick={(e) => setAIPlugin({ e, props })}
              style={{
                borderLeft: '1px white solid',
                padding: '0px 7px',
                cursor: 'pointer'
              }}
            >
              AI Generate
            </div>
          </div>
        )}
      </div>
    )

  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget
  })

  const transform = (slot, showAIPlugin) => {
    return {
      ...slot,
      Open: () => <></>,
      OpenMenuItem: () => <></>,
      Print: () => <></>,
      PrintMenuItem: () => <></>,
      Download: () => <></>,
      DownloadMenuItem: () => <></>,
      SwitchScrollMode: () => <></>,
      SwitchScrollModeMenuItem: () => <></>
    }
  }

  const renderToolbar = (Toolbar) => {
    const TOOLTIP_OFFSET = { left: 50, top: 0 }
    return (
      <>
        {isAnalytics && (
          <Tooltip
            target={
              <MinimalButton
                onClick={() => {
                  setShowError(!showError)
                }}
              >
                <div style={{ color: showError ? '#6A6A6A' : 'black' }}>
                  <SvgIcon>
                    <Robot />
                  </SvgIcon>
                </div>
              </MinimalButton>
            }
            offset={TOOLTIP_OFFSET}
            position={Position.BottomCenter}
            content={() => `AI Generate ${showError ? 'Off' : 'On'}`}
          />
        )}
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
      </>
    )
  }

  const handleDataChange = async (errorObj, status) => {
    const { id } = PDFData
    let errorObjs = [] // here errorObj can be either a single object or a list of objects, here we handle that
    if (Array.isArray(errorObj)) {
      errorObjs = errorObj
    } else {
      errorObjs.push(errorObj)
    }
    const objs = []
    errorObjs.forEach((errorObj) => {
      const { id: error_id, errorType, pageNo } = errorObj
      const obj = {
        error_type: errorType,
        page_number: pageNo,
        id,
        error_id,
        status
      }
      objs.push(obj)
    })
    const res = await analyticsErrorUpdate(objs)
    if (res.status === 200) {
      const newObj = cloneDeep(errorData)
      errorObjs.forEach((errorObj) => {
        const { id: error_id, errorType, pageNo } = errorObj
        const index = findIndex(newObj[errorType], { id: error_id })
        if (index > -1) {
          newObj[errorType][index].status = status
        }
      })
      setErrorData(newObj)
    }
  }

  const combinedArr = Object.values(errorData).reduce((acc, arr) => {
    return [...acc, ...arr]
  }, [])

  useEffect(() => {
    highlight(combinedArr)
  }, [errorData, selectedErrorType])

  const searchPluginInstance = searchPlugin({
    keyword: combinedArr,
    handleDataChange,
    showError,
    selectedErrorType
  })

  const { clearHighlights, highlight } = searchPluginInstance

  const pageNavigationPluginInstance = pageNavigationPlugin()
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
    renderToolbar
  })

  const { jumpToPage, CurrentPageLabel } = pageNavigationPluginInstance

  const setGoToPage = (pageIndex) => {
    if (pageIndex > -1 && pageIndex !== 'new') {
      jumpToPage(pageIndex - 1)
    }
  }

  const handleErrorTypeSelection = (type) => {
    setSelectedErrorType(type)
  }

  // const md = new MarkdownIt(); // this is used to convert textToHtml
  const md = markdownit({
    html: true,
    breaks: true
  })

  const convertToHTML = (markdownText) => {
    return md.render(markdownText) // this is used to convert textToHtml
  }

  const processText = (inputText) => {
    const removeDoubleLines = inputText.replace(/\n\n/g, ' \n ') // this function removes two new line characters and changes it to only one
    return removeDoubleLines
  }

  const handleExampleQuestionGeneration = async (data) => {
    if (isSuperUser) {
      toast.warning('Anthropic is being used, be careful of tokens.')
    }
    setChatTextBox(data)
  }

  const prefixElements = () => {
    return (
      <Box
        sx={{
          background: '#fff',
          borderRadius: '5px',
          padding: '20px',
          marginTop: '5px',
          maxWidth: '58rem',
          margin: 'auto',
          overflow: 'auto'
        }}
      >
        {Object.entries(initialPromptList).map(([key, value], index) => {
          if (!value) return <></>
          value = processText(value)
          value = convertToHTML(value)
          return (
            <Accordion
              key={index}
              defaultExpanded={index === 0}
              square={true}
              style={{
                marginBottom: '16px',
                backgroundColor: '#f0f3f9',
                border: '1px solid #ccc'
              }}
              onClick={(e) => {
                trackEvent('win_strategy', 'SUCCESS', {}, { key })
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                style={{ marginBottom: '0px' }}
              >
                <Box
                  sx={{ fontWeight: 'bold' }}
                  style={{ marginBottom: '-5px' }}
                >
                  {key === 'features_themes'
                    ? 'Features/Themes'
                    : _.startCase(key)}
                </Box>
              </AccordionSummary>
              <AccordionDetails style={{ paddingTop: '0px', marginTop: '0px' }}>
                <Box
                  dangerouslySetInnerHTML={{ __html: value }}
                  sx={{
                    paddingTop: '0px',
                    marginTop: '0px',
                    marginLeft: '0px',
                    paddingLeft: '0px'
                  }}
                ></Box>
              </AccordionDetails>
            </Accordion>
          )
        })}

        <Accordion
          square={true}
          style={{
            marginBottom: '16px',
            backgroundColor: '#f0f3f9',
            border: '1px solid #ccc'
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ fontWeight: 'bold' }}>Example questions : </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {isAnalytics &&
                exampleQuestionKeys.map((key, index) => (
                  <Box key={index} sx={{ whiteSpace: 'pre-wrap' }}>
                    <MUIToolTip title={'Ask this question'}>
                      <IconButton
                        disabled={chatLoading}
                        onClick={() =>
                          handleExampleQuestionGeneration(exampleQuestions[key])
                        }
                      >
                        <SendIcon />
                      </IconButton>
                    </MUIToolTip>
                    <span style={{ fontWeight: 'bold' }}>{key} : </span>
                    {exampleQuestions[key]}
                  </Box>
                ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    )
  }

  const pdfToolTab = [
    {
      label: 'Suggestions',
      children: <ErrorInSights
        jumpToPage={jumpToPage}
        isFocused={activeTab === 0}
        words={errorData}
        handleDataChange={handleDataChange}
        handleErrorTypeSelection={handleErrorTypeSelection}
        selectedErrorType={selectedErrorType}
        shareType={shareType}
        editingDisabled={isUserViewer && (currentUserName !== PDFData?.created_by_user)} />
    },
    {
      label: 'Insights',
      children: (
        <Charts
          currentPage={pdfCurrentPage}
          isFocused={activeTab === 1}
          PDFData={PDFData}
        />
      )
    },
    {
      label: <span style={{ display: 'flex' }}>Win Strategy&nbsp;</span>,
      children:
        shareType === 'private' ? (
          <ChatBot
            trackerId={PDFData?.id}
            chatType="analytics"
            prefixElements={prefixElements}
            chatTextBox={chatTextBox}
            relayLoading={(loading) => setChatLoading(loading)}
            textBoxPlaceholder={
              'Ask some questions about this analytics document'
            }
          />
        ) : (
          <PublicWinStrategy id={shareId} />
        )
    },
    {
      label: (
        <span style={{ display: 'flex' }}>
          Compliance&nbsp;
          <span
            style={{
              border: '1px solid',
              fontSize: '10px',
              opacity: '1',
              color: 'rgb(6, 69, 173)',
              background: 'rgb(219, 234, 255)',
              borderRadius: '4px',
              padding: '4px 5px'
            }}
          >
            BETA
          </span>
        </span>
      ),
      children: (
        <Compliance
          {...props}
          isFocused={activeTab === 3}
          setGoToPage={setGoToPage}
        />
      )
    }
  ]

  const handleShareDialog = (event) => {
    setAnchorEl(event.currentTarget)
    setOpenShareDialog(true)
  }

  const handleCloseShareDialog = () => {
    setShareableLink('')
    setIsCopied(false)
    setOpenShareDialog(false)
    setAnchorEl(null)
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareableLink)
    setIsCopied(true)
  }

  const handleCustomValueChange = (event) => {
    setCustomValue(event.target.value)
  }

  const handleCreateShareLink = async () => {
    setLinkLoading(true)
    const data = {
      rfx_id: PDFData?.id,
      valid_for_days: parseInt(customValue, 10)
    }
    const res = await ShareAnalytics(data)
    // const res = { status: 404 }
    if (res?.status === 200) {
      if (process.env.REACT_APP_ENV === 'production') {
        setShareableLink(
          'https://app.joist.ai/share-analytics/' + res.data.data.id
        )
        trackEvent('share_analytics', 'SUCCESS', {}, data)
      } else if (process.env.REACT_APP_ENV === 'staging') {
        setShareableLink(
          'https://uat3.joist.ai/share-analytics/' + res.data.data.id
        )
        trackEvent('share_analytics', 'SUCCESS', {}, data)
      } else if (process.env.REACT_APP_ENV === 'local') {
        setShareableLink(
          'http://localhost:3000/share-analytics/' + res.data.data.id
        )
        trackEvent('share_analytics', 'SUCCESS', {}, data)
      } else {
        setShareableLink(
          'some error occured while producing the link, please refresh.'
        )
        trackEvent('share_analytics', 'FAILED', {}, data)
      }

      setLinkLoading(false)
    } else {
      setShareableLink(
        'some error occured while producing the link, please refresh.'
      )
      setLinkLoading(false)
      trackEvent('share_rfx', 'FAILED', {}, data)
    }
    // setOpenShareDialog(false);
  }

  const extraButtonsOnTab =
    shareType === 'private'
      ? [
          {
            label: <span style={{ display: 'flex' }}>Share</span>,
            icon: <ShareIcon style={{ fontSize: '15px' }} />,
            onClick: handleShareDialog
            // name: 'share'
          }
        ]
      : []

  const changeTab = (e) => {
    setActiveTab(e)
  }

  return isAnalytics ? (
    <Box
      container
      className={page}
      ref={ref}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
      }}
    >
      {pdfWidth && toolbarWidth ? (
        <>
          <Resizable
            width={pdfWidth}
            axis="x"
            onResize={handleOnResize}
            resizeHandles={['e']}
          >
            <Box
              sx={{
                width: pdfWidth - 1 + 'px',
                height: '100%',
                borderRight: '1px solid #EEEEEE'
              }}
              className={webviewer}
            >
              <Container>
                <SectionFixed>
                  <Box className={borderBot}>
                    <Box className={titleContainer}>
                      {window?.history?.state && (
                        <Box className={iconButton}>
                          <IconButton onClick={() => setShowPDF(false)}>
                            <SvgIcon className={icon}>
                              <BackImg />
                            </SvgIcon>
                          </IconButton>
                        </Box>
                      )}
                      {inProcess ? (
                        <Box className={pdfTitle}>
                          {pdfName} (in process)
                          <MUIToolTip
                            title={
                              'This analysis of this file is still in process, refresh after some time to get the latest analysis.'
                            }
                          >
                            <IconButton
                              disableRipple
                              sx={{
                                padding: '0px'
                              }}
                            >
                              <InfoIcon
                                sx={{
                                  fontSize: '18px'
                                }}
                              />
                            </IconButton>
                          </MUIToolTip>
                        </Box>
                      ) : (
                        <Box className={pdfTitle}>{pdfName}</Box>
                      )}
                    </Box>
                  </Box>
                </SectionFixed>
                <Section overFlow>
                  <Viewer
                    plugins={[
                      toolbarPluginInstance,
                      defaultLayoutPluginInstance,
                      searchPluginInstance,
                      pageNavigationPluginInstance,
                      highlightPluginInstance
                    ]}
                    fileUrl={url}
                    initialPage={pageNumber}
                    defaultScale={SpecialZoomLevel.PageFit}
                    onPageChange={(page) => {
                      setPdfCurrentPage(page?.currentPage)
                    }}
                  />
                  {aiPlugin && shareType === 'private' && (
                    <AIGeneratePlugin
                      aiPlugin={aiPlugin}
                      {...props}
                      setAIPlugin={setAIPlugin}
                      setActiveTab={setActiveTab}
                      setCurrentHighlighted={setCurrentHighlighted}
                    />
                  )}
                </Section>
              </Container>
            </Box>
          </Resizable>
          <Box
            className={infoContainer}
            sx={{
              width: toolbarWidth + 'px',
              height: '100%'
            }}
          >
            <Box
              className={infoWrapper}
              sx={{
                width: '100%',
                '& .MuiTabs-flexContainer': {
                  overflow: 'auto'
                }
              }}
            >
              {shareType === 'private' && (
                <Box>
                  <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleCloseShareDialog}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                  >
                    <DialogContent style={{}}>
                      {linkLoading ? (
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Loader
                            loading={linkLoading}
                            caption={'creating a custom link just for you!'}
                          />
                        </Grid>
                      ) : (
                        <>
                          {shareableLink === '' ? (
                            <>
                              {selectedOption === 'custom' && (
                                <Box
                                  style={{
                                    fontSize: '18px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingBottom: '10px'
                                  }}
                                >
                                  Number of days the link should be valid -
                                  <TextField
                                    label=""
                                    type="number"
                                    value={customValue}
                                    onChange={handleCustomValueChange}
                                    style={{
                                      marginLeft: '8px',
                                      marginRight: '8px',
                                      maxWidth: '80px'
                                    }}
                                    InputProps={{
                                      inputProps: {
                                        style: { paddingRight: '0px' }
                                      }
                                    }}
                                  />
                                  days
                                </Box>
                              )}
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCreateShareLink}
                                style={{ float: 'right' }}
                              >
                                Create Link
                              </Button>
                            </>
                          ) : (
                            <>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  value={shareableLink}
                                  InputProps={{
                                    readOnly: true
                                  }}
                                  style={{ marginRight: '8px' }}
                                />
                                <Button
                                  onClick={copyToClipboard}
                                  variant="outlined"
                                  startIcon={<FileCopyIcon />}
                                  style={{}}
                                >
                                  {isCopied ? 'Copied!' : 'Copy'}
                                </Button>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </DialogContent>
                  </Popover>
                </Box>
              )}
              <Tab
                data={pdfToolTab}
                activeTab={activeTab}
                isFlex={true}
                sectionOverFlow={true}
                tabChangeCallBack={(e) => changeTab(e)}
                extraButtons={extraButtonsOnTab}
              />
            </Box>
          </Box>
        </>
      ) : (
        <></>
      )}
    </Box>
  ) : (
    <Grid container className={page}>
      <Grid item xs md lg className={webviewer}>
        <Container>
          <SectionFixed>
            <Box className={borderBot}>
              <Box className={titleContainer}>
                {window?.history?.state && (
                  <Box className={iconButton}>
                    <IconButton onClick={() => setShowPDF(false)}>
                      <SvgIcon className={icon}>
                        <BackImg />
                      </SvgIcon>
                    </IconButton>
                  </Box>
                )}
                <Box className={pdfTitle}>{pdfName}</Box>
              </Box>
            </Box>
          </SectionFixed>
          <Section overFlow>
            <Viewer
              plugins={[
                toolbarPluginInstance,
                defaultLayoutPluginInstance,
                searchPluginInstance,
                pageNavigationPluginInstance,
                highlightPluginInstance
              ]}
              fileUrl={url}
              initialPage={pageNumber}
              defaultScale={SpecialZoomLevel.PageFit}
              onPageChange={(page) => {
                setPdfCurrentPage(page?.currentPage)
              }}
            />
            {aiPlugin && shareType === 'private' && (
              <AIGeneratePlugin
                aiPlugin={aiPlugin}
                {...props}
                setAIPlugin={setAIPlugin}
                setActiveTab={setActiveTab}
                setCurrentHighlighted={setCurrentHighlighted}
              />
            )}
          </Section>
        </Container>
      </Grid>
    </Grid>
  )
}

export default PDFViewer
