import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  draftWrapper: {
    width: '100%',
    height: '100%'
  },
  flexContainer: {
    display: 'flex',
    height: '100%',
    width: '100%'
  },
  editorContainer: {
    width: '100%',
    height: '100%'
  },
  sidePaneContainer: {
    width: '100%',
    height: '100%'
  },
  headerContainer: {
    padding: '0px 10px'
  },
  draftName: {
    fontSize: '16px',
    fontWeight: 'bold',
    maxHeight: '50px',
    overflow: 'auto',
    padding: '2px'
  },
  draftInfoItem: {
    fontSize: '14px',
    fontWeight: 'normal',
    display: 'flex',
    marginTop: '10px',
    color: '#666666'
  },
  draftInfoLabel: {
    fontWeight: 'bold',
    marginRight: '5px'
  },
  draftInfoValue: {
    fontWeight: 'normal'
  },
  status: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#666666'
  },
  logsContainer: {
    marginTop: '10px',
    backgroundColor: '#f0f0f0',
    borderRadius: '5px',
    maxHeight: '100px',
    overflow: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: '7px',
    padding: '10px'
  },
  logsTools: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '5px'
  },
  log: {
    padding: '10px'
  },
  timestamp: {
    fontStyle: 'italic'
  },
  logText: {
    fontSize: '14px',
    color: '#666666'
  },
  retryContainer: {
    color: '#575757',
    fontSize: '14px',
    background: 'lightgray',
    textAlign: 'center',
    cursor: 'pointer'
  },
  storyContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '10px',
    marginBottom: '10px'
  },
  story: {
    border: '1px solid #EEEEEE',
    borderRadius: '5px',
    width: 'calc(100% - 20px)',
    padding: '10px',
    color: '#666666',
    marginBottom: '10px',
    backgroundColor: '#FFFFFF',
    cursor: 'pointer !important'
  },
  storyToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px'
  },
  addNewStory: {
    display: 'flex',
    gap: '3px'
  },
  storyDisabled: {
    opacity: '0.5 !important',
    backgroundColor: '#f0f0f0'
  },
  storyTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: 'black'
  },
  subSection: {
    fontSize: '14px',
    marginBottom: '5px',
    marginLeft: '5px'
  },
  sectionContainer: {
    border: '1px solid #EEEEEE',
    borderRadius: '5px',
    width: 'calc(100% - 42px)',
    margin: '10px',
    padding: '10px',
    color: '#666666'
  },
  sectionSlider: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  sectionHeaderInput: {
    marginBottom: '15px',
    '& input': {
      fontSize: '14px',
      padding: '6.5px 10px'
    }
  },
  sectionButtons: {
    display: 'flex',
    gap: '5px',
    marginBottom: '15px'
  },
  sectionLabel: {
    fontSize: '16px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '5px',
    color: 'black',
    '& button': {
      padding: '0'
    }
  },
  subSectionInput: {
    marginBottom: '10px',
    '& div': {
      width: '-webkit-fill-available'
    },
    '& button': {
      marginLeft: '5px'
    }
  },
  noStories: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#666666',
    textAlign: 'center'
  },
  subProject: {
    marginTop: '10px',
    marginBottom: '5px',
    display: 'flex'
  },
  subProjectContainer: {
    border: '1px solid #EEEEEE',
    borderRadius: '5px',
    padding: '5px 8px',
    width: '-webkit-fill-available'
  },
  subProjectName: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#000000',
    fontWeight: 'normal',
    '& button': {
      padding: '0'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '16px'
    }
  },
  subProjectReason: {
    fontSize: '14px',
    marginTop: '5px',
    fontWeight: 'normal',
    color: '#666666',
    display: 'flex',
    gap: '5px',
    '& .MuiSvgIcon-root': {
      fontSize: '16px'
    }
  },
  sectionPeopleLabel: {
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '10px',
    color: 'black',
    '& button': {
      padding: '0'
    }
  },
  sectionPeopleName: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#666666',
    textAlign: 'center'
  },
  sectionPeopleSelect: {
    marginTop: '10px',
    marginBottom: '5px',
    width: '100%'
  },
  sectionPeopleCount: {
    color: '#666666',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    '& .MuiSvgIcon-root': {
      fontSize: '16px'
    }
  },
  allLogText: {
    height: '100px',
    overflow: 'auto',
    width: '100%'
  },
  historyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%'
  },
  historyContainer: {
    padding: '10px',
    backgroundColor: '#f0f0f0',
    borderRadius: '5px'
  },
  historyContent: {
    color: 'black',
    marginBottom: '10px'
  },
  historyMeta: {
    marginTop: '10px',
    display: 'flex',
    gap: '10px',
    color: '#666666',
    fontSize: '14px'
  },
  pullWrapper: {
    color: '#6b6b6b6b',
    textAlign: 'center'
  }
})

export { useStyles }
