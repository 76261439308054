import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'

const ReactSelect = (props) => {
  const { styles = {}, isCreatable = false, ...rest } = props
  const style = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    control: (baseStyles, state) => {
      if (state?.isFocused) {
        return {
          ...baseStyles,
          maxHeight: '100px',
          overflow: 'auto',
          borderColor: 'black !important',
          boxShadow: 'none !important'
        }
      }
      return {
        ...baseStyles,
        maxHeight: '100px',
        overflow: 'auto',
        boxShadow: 'none !important'
      }
    },
    menuList: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '14px ',
      paddingBottom: '0px !important',
      height: '100%',
      overflow: 'auto'
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      borderBottom: '1px solid #D9D9D9 !important',
      padding: '5px 10px !important',
      wordBreak: 'break-word',
      display: 'flex',
      alignItems: 'center',
      color: '#000000 !important'
    }),
    valueContainer: (baseStyles, state) => ({
      ...baseStyles,
      maxHeight: '98px',
      overflow: 'auto'
    }),
    ...styles
  }
  return isCreatable ? (
    <CreatableSelect
      menuPortalTarget={document.body}
      styles={style}
      {...rest}
    />
  ) : (
    <Select menuPortalTarget={document.body} styles={style} {...rest} />
  )
}

export default ReactSelect
