import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import Switch from '@mui/material/Switch'
import { useStyles } from './styles'
import {
  Box,
  Grid,
  Typography,
  Collapse,
  ButtonGroup,
  Tooltip
} from '@mui/material'
import Button from '../../components/Button'
import { getbg } from './errorCode'
import { Container, Section, SectionFixed } from '../../components/Container'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import List from '@mui/material/List'
import { useResizeDetector } from 'react-resize-detector'
import _ from 'lodash'
import { defaultErrorTypes } from './DefaultErrorTypes'
import ErrorCardDetail from './ErrorCardDetail'

const AnalyticCorrection = (props) => {
  const { errorObj, isFocused, jumpToPage, handleDataChange, errorType, handleErrorType, shareType = 'private', editingDisabled = 'false' } = props
  const classes = useStyles()
  const {
    dot,
    buttonWrapper,
    errorContainer,
    buttonGroupWrapper,
    replacementWrapper,
    collapseWrapper,
    correctionContainer,
    descWrapper,
    errorWrapper,
    keywordWrapper,
    pageNoWrapper,
    accordianTitle,
    groupCount,
    toggleSwitchScroll,
    buttonHighlight1,
    buttonHighlight2,
    contentWrapper,
    groupingErrorCard
  } = classes
  const [expandedId, setExpandedId] = useState(-1)
  const [innerExpandedId, setInnerExpandedId] = useState(-1)
  const [view, setView] = useState('corrections')
  const [reverse, setReverse] = useState(false)
  const { width, height, ref } = useResizeDetector()
  const desiredErrorOrder = [
    'spell_check',
    'grammar_check',
    'passive_voice_detection',
    'split_infinitives',
    'long_sentence',
    'inconsistent_usage',
    'exaggerations',
    'overused_words',
    'clarity_and_conciseness',
    'jargon_and_technical_terms',
    'duplicate_detection',
    'acronym',
    'dates',
    'email ids',
    'contact numbers',
    'currency_values',
    'people names',
    'locations',
    'organizations',
    'words_to_avoid',
    'wordy_phrases'
  ]

  let groupedArr = {}

  if (errorType === 'all') {
    const combinedArr = Object.values(errorObj).reduce((acc, arr) => {
      return [...acc, ...arr]
    }, [])
    groupedArr = combinedArr.reduce((acc, obj) => {
      const { keyword, status } = obj
      if (status === 'complete') {
        acc.defined = acc.defined || {}
        acc.defined[keyword] = acc.defined[keyword] || { keyword, items: [] }
        acc.defined[keyword].items.push(obj)
      } else if (status === 'ignore') {
        acc.ignored = acc.ignored || {}
        acc.ignored[keyword] = acc.ignored[keyword] || { keyword, items: [] }
        acc.ignored[keyword].items.push(obj)
      } else {
        acc.undefined = acc.undefined || {}
        acc.undefined[keyword] = acc.undefined[keyword] || {
          keyword,
          items: []
        }
        acc.undefined[keyword].items.push(obj)
      }
      return acc
    }, {})
  } else if (errorType) {
    groupedArr = errorObj[errorType].reduce((acc, obj) => {
      const { keyword, status } = obj
      if (status === 'complete') {
        acc.defined = acc.defined || {}
        acc.defined[keyword] = acc.defined[keyword] || { keyword, items: [] }
        acc.defined[keyword].items.push(obj)
      } else if (status === 'ignore') {
        acc.ignored = acc.ignored || {}
        acc.ignored[keyword] = acc.ignored[keyword] || { keyword, items: [] }
        acc.ignored[keyword].items.push(obj)
      } else {
        acc.undefined = acc.undefined || {}
        acc.undefined[keyword] = acc.undefined[keyword] || {
          keyword,
          items: []
        }
        acc.undefined[keyword].items.push(obj)
      }
      return acc
    }, {})
  }

  const analyticsDefined = Object.values(groupedArr.defined || {})
  const analyticsUndefined = Object.values(groupedArr.undefined || {})
  const analyticsIgnored = Object.values(groupedArr.ignored || {})

  const handleInnerClick = (i) => {
    setInnerExpandedId(innerExpandedId === i ? -1 : i)
    /* setExpandedId(-1) */
  }

  const handleExpandClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i)
    setInnerExpandedId(-1)
  }

  const errorList =
    view === 'corrections'
      ? analyticsUndefined
      : view === 'resolved'
      ? analyticsDefined
      : analyticsIgnored
  const dotbg = getbg(errorType)

  useEffect(() => {
    if (isFocused) {
      if (width > 500) {
        setReverse(false)
      } else {
        setReverse(true)
      }
    }
  }, [width])

  useEffect(() => {
    if (errorObj && errorObj[errorType] && errorObj[errorType].length > 0) {
      console.log(desiredErrorOrder)
      console.log(errorObj)
      // get the first error type that has some errors
      const firstErrorType = desiredErrorOrder.find(
        (key) => errorObj[key].length > 0
      )
      console.log(firstErrorType)
      if (firstErrorType) {
        handleErrorType(firstErrorType)
      }
    }
  }, [])

  return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexWrap: 'wrap' }} ref={ref}>
            {!reverse
              ? <>
                    <Box sx={{ width: 'calc(60% - 1px)', height: '100%', borderRight: '1px solid #E5E5E5' }}>
                        <Container>
                            <Section overFlow>
                                <Box className={toggleSwitchScroll} sx={{ width: '100%', height: '100%' }}>
                                    <Container>
                                        <SectionFixed>
                                            <ButtonGroup className={buttonGroupWrapper} >
                                                <Button className={view === 'corrections' ? buttonHighlight1 : buttonHighlight2} variant={view === 'corrections' ? 'contained' : 'outlined'} onClick={() => setView('corrections')}>Corrections</Button>
                                                <Button className={view === 'resolved' ? buttonHighlight1 : buttonHighlight2} variant={view === 'resolved' ? 'contained' : 'outlined'} onClick={() => setView('resolved')}>Resolved</Button>
                                                <Button className={view === 'ignored' ? buttonHighlight1 : buttonHighlight2} variant={view === 'ignored' ? 'contained' : 'outlined'} onClick={() => setView('ignored')}>Ignored</Button>
                                            </ButtonGroup>
                                        </SectionFixed>
                                        <Section overFlow>
                                            {
                                                errorList.map((obj, i) => (
                                                    <ErrorCardDetail key={i} jumpToPage={jumpToPage} handleDataChange={handleDataChange} errorType={errorType} obj={obj} i={i} shareType={shareType} editingDisabled={editingDisabled} />
                                                ))
                                            }
                                        </Section>
                                    </Container>
                                </Box>
                            </Section>
                        </Container>
                    </Box>
                    <Box sx={{ width: '40%', height: '100%' }}>
                        <Container>
                            <Section overFlow>
                                <List sx={{
                                  padding: '12px',
                                  '& .Mui-selected': {
                                    backgroundColor: '#0000001A'
                                  }
                                }}>
                                    {desiredErrorOrder.filter(key => errorObj.hasOwnProperty(key)).map((key, i) => {
                                      const bg = getbg(key)
                                      if (errorObj[key].length > 0) {
                                        return (
                                                <ListItem disablePadding key={key}>
                                                    <ListItemButton selected={key === errorType} onClick={() => handleErrorType(key)}>
                                                        <ListItemText primary={
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <Box className={dot} sx={{ background: bg }} />
                                                                <Box sx={{ ml: '5px' }}>{defaultErrorTypes[key] ? defaultErrorTypes[key] : _.startCase(key)}
                                                                    <Box component={'span'} sx={{ color: '#00000080' }}>&nbsp;({errorObj[key].length})</Box>
                                                                </Box>
                                                            </Box>
                                                        } />
                                                    </ListItemButton>
                                                </ListItem>
                                        )
                                      } else return null
                                    })}
                                </List>
                            </Section>
                        </Container>
                    </Box>
                </>
              : <>
                    <Box sx={{ width: '100%', height: '100%' }}>
                        <Container>
                            <Box>
                                <ButtonGroup className={buttonGroupWrapper} >
                                    <Button className={view === 'corrections' ? buttonHighlight1 : buttonHighlight2} variant={view === 'corrections' ? 'contained' : 'outlined'} onClick={() => setView('corrections')}>Corrections</Button>
                                    <Button className={view === 'resolved' ? buttonHighlight1 : buttonHighlight2} variant={view === 'resolved' ? 'contained' : 'outlined'} onClick={() => setView('resolved')}>Resolved</Button>
                                    <Button className={view === 'ignored' ? buttonHighlight1 : buttonHighlight2} variant={view === 'ignored' ? 'contained' : 'outlined'} onClick={() => setView('ignored')}>Ignored</Button>
                                </ButtonGroup>
                                {
                                    errorList.map((obj, i) => (
                                        <ErrorCardDetail key={i} jumpToPage={jumpToPage} handleDataChange={handleDataChange} errorType={errorType} obj={obj} i={i} shareType={shareType} editingDisabled={editingDisabled}/>
                                    ))
                                }
                            </Box>
                            <List sx={{
                              padding: '12px',
                              '& .Mui-selected': {
                                backgroundColor: '#0000001A'
                              }
                            }}>
                                {desiredErrorOrder.filter(key => errorObj.hasOwnProperty(key)).map((key, i) => {
                                  const bg = getbg(key)
                                  if (errorObj[key].length > 0) {
                                    return (
                                            <ListItem disablePadding key={key + toString(i)}>
                                                <ListItemButton selected={key === errorType} onClick={() => handleErrorType(key)}>
                                                    <ListItemText primary={
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <Box className={dot} sx={{ background: bg }} />
                                                            <Box sx={{ ml: '5px' }}>{defaultErrorTypes[key] ? defaultErrorTypes[key] : _.startCase(key)}
                                                                <Box component={'span'} sx={{ color: '#00000080' }}>&nbsp;({errorObj[key].length})</Box>
                                                            </Box>
                                                        </Box>
                                                    } />
                                                </ListItemButton>
                                            </ListItem>
                                    )
                                  } else return null
                                })}
                            </List>

                        </Container>
                    </Box>

                </>
            }
        </Box>
  )
}

export default AnalyticCorrection
