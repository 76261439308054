import { ROUTES } from '../../config/routes'
import { ReactComponent as Analytics } from '../../assets/images/Analytics.svg'
import { ReactComponent as Library } from '../../assets/images/Library.svg'
import { ReactComponent as Folder } from '../../assets/images/Folder.svg'
import { ReactComponent as Settings } from '../../assets/images/settings.svg'
import { ReactComponent as Home } from '../../assets/images/home.svg'
import { ReactComponent as SideBar_Chat_Icon } from '../../assets/images/SideBar_Chat_Icon.svg'
import { ReactComponent as Workspace } from '../../assets/svg/Workspace.svg'
import {
  AnalyticsIcon,
  BookIcon,
  BucketIcon,
  ChatIcon,
  FolderIcon,
  HomeIcon,
  SettingsIcon,
  WorkspaceIcons
} from '../Icons/Icons'
import { checkDomainAccess } from '../../utils/User'

const {
  LIBRARY_KNOWLEDGE,
  LIBRARY_PEOPLE_RESUME,
  LIBRARY_PROJECTS,
  LIBRARY_CLIENTS_RESUME,
  SETTINGS_PROFILE,
  WORKSPACE,
  ANALYTICS_HOME_ANALYTICS,
  DASHBOARD,
  FILES
} = ROUTES
const menuItems = [
  {
    name: 'Dashboard',
    icon: (
      <HomeIcon
        style={{
          width: '18px',
          height: '18px'
        }}
      />
    ),
    route: DASHBOARD,
    toolTip: 'Home'
  },
  {
    name: 'Files',
    icon: (
      <FolderIcon
        style={{
          width: '18px',
          height: '18px'
        }}
      />
    ),
    route: FILES,
    toolTip: 'Files'
  },
  {
    name: 'Library',
    icon: (
      <BookIcon
        style={{
          width: '18px',
          height: '18px'
        }}
      />
    ),
    id: '3',
    route: LIBRARY_KNOWLEDGE,
    toolTip: 'Library',
    submenu: [
      {
        name: 'Knowledge',
        route: LIBRARY_KNOWLEDGE,
        toolTip: 'Knowledge'
      }
    ]
  },
  {
    name: 'Workspace',
    icon: (
      <WorkspaceIcons
        style={{
          width: '18px',
          height: '18px'
        }}
      />
    ),
    route: WORKSPACE,
    toolTip: 'Workspaces'
  },
  {
    name: 'Analytics',
    icon: (
      <AnalyticsIcon
        style={{
          width: '18px',
          height: '18px'
        }}
      />
    ),
    route: ANALYTICS_HOME_ANALYTICS,
    toolTip: 'Analytics'
  },
  {
    name: 'Chat',
    icon: (
      <ChatIcon
        style={{
          width: '18px',
          height: '18px'
        }}
      />
    ),
    route: 'chat/new',
    toolTip: 'Chats'
  },
  {
    name: 'Settings',
    icon: (
      <SettingsIcon
        style={{
          width: '18px',
          height: '18px'
        }}
      />
    ),
    route: SETTINGS_PROFILE,
    submenu: [],
    toolTip: 'Setting'
  }
]

const menuAnnotator = [
  {
    name: 'Dashboard',
    icon: <Home />,
    route: DASHBOARD,
    toolTip: 'Dashboard'
  },
  {
    name: 'Files',
    icon: <Folder />,
    route: FILES,
    toolTip: 'Files'
  },
  {
    name: 'Library',
    icon: <Library />,
    route: LIBRARY_KNOWLEDGE,
    toolTip: 'Library'
  },
  {
    name: 'Settings',
    icon: <Settings />,
    route: SETTINGS_PROFILE,
    toolTip: 'Setting'
  }
]

const superUserTab = [
  {
    label: 'Profile Details',
    index: 0,
    route: '/settings/profile'
  },
  {
    label: 'Domain Settings',
    index: 1,
    route: '/settings/domain'
  },
  {
    label: 'Users',
    index: 2,
    route: '/settings/users'
  },
  {
    label: 'Chat Settings',
    index: 3,
    route: '/settings/chat'
  },
  {
    label: 'Tag Center',
    index: 4,
    route: '/settings/tagcenter'
  },
  {
    label: 'Change Domain',
    index: 5,
    route: '/settings/changedomain'
  },
  {
    label: 'Create New Domain',
    index: 6,
    route: '/settings/createdomain'
  },
  {
    label: 'Create Identity Providers',
    index: 7,
    route: '/settings/identityproviders'
  }
]

const annotatorTab = [
  {
    label: 'Profile Details',
    index: 0,
    route: '/settings/profile'
  },
  {
    label: 'Change Domain',
    index: 1,
    route: '/settings/changedomain'
  }
]

const adminTab = [
  {
    label: 'Profile',
    name: 'Profile',
    index: 0,
    route: '/settings/profile'
  },
  {
    label: 'Domain Settings',
    name: 'Domain Settings',
    index: 1,
    route: '/settings/domain'
  },
  {
    label: 'Users',
    index: 2,
    route: '/settings/users'
  },
  {
    label: 'Chat Settings',
    index: 3,
    route: '/settings/chat'
  },
  {
    label: 'Tag Center',
    index: 4,
    route: '/settings/tagcenter'
  }
]

const userTab = [
  {
    label: 'Profile Details',
    index: 0,
    route: '/settings/profile'
  }
]

export {
  menuItems,
  menuAnnotator,
  superUserTab,
  annotatorTab,
  adminTab,
  userTab
}
