import { UPDATE_COLLECTIONS } from '../../../actionTypes'
import { putCollections } from '../../../api'
import { toast } from 'react-toastify'

const collections = (req, callback) => async (dispatch) => {
  dispatch({
    type: UPDATE_COLLECTIONS,
    payload: { ...req, status: 'loading' },
    callback
  })
  const res = await putCollections(req)
  if (res.status === 200) {
    dispatch({
      type: UPDATE_COLLECTIONS,
      payload: { ...req, status: 'active' }
    })
  } else {
    toast.error('Unable to update collection')
    dispatch({
      type: UPDATE_COLLECTIONS,
      payload: { ...req, status: 'active' }
    })
  }
}

export default collections
