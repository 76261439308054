export const resumeStyle = {
  rightPane: {
    emp_name: {
      style: { fontSize: '30px', fontWeight: '600' },
      type: 'string',
      location: 'people',
      placeHolder: 'Name'
    },
    role: {
      style: { fontSize: '20px', fontWeight: '600', marginTop: '10px' },
      type: 'string',
      location: 'people',
      placeHolder: 'Title'
    },
    emp_professional_summary: {
      style: { fontSize: '16px', marginTop: '5px', fontWeight: '400' },
      type: 'string',
      location: 'resume',
      placeHolder: 'Professional Summary'
    },
    emp_project_experience: {
      label: {
        style: { fontSize: '20px', marginTop: '25px', fontWeight: '600' },
        value: 'Project Experience'
      },
      location: 'resume',
      type: 'array',
      children: {
        project_name: {
          style: { fontSize: '16px', marginTop: '16px', fontWeight: '600' },
          placeHolder: 'Project Name'
        },
        project_location: {
          style: {
            fontSize: '14px',
            // marginTop: '5px',
            fontWeight: '400',
            fontStyle: 'italic'
          },
          placeHolder: 'Project Location'
        },
        project_value: {
          style: {
            fontSize: '16px',
            fontWeight: '400',
            fontStyle: 'italic'
          },
          placeHolder: 'Project Value'
        },
        project_duration: {
          style: {
            fontSize: '16px',
            fontWeight: '400',
            fontStyle: 'italic'
          },
          placeHolder: 'Project Duration'
        },
        project_description: {
          style: {
            fontSize: '14px',
            fontWeight: '400',
            textAlign: 'justify'
          },
          placeHolder: 'Project Description'
        },
        project_building_specifications: {
          style: {
            fontSize: '16px',
            fontWeight: '400',
            textAlign: 'justify'
          },
          placeHolder: 'Project Building Specifications'
        },
        project_achievements: {
          style: {
            fontSize: '16px',
            fontWeight: '400',
            textAlign: 'justify'
          },
          placeHolder: 'Project Achievements'
        }
      }
    }
  },
  leftPane: {
    emp_year_experience: {
      label: {
        style: { fontSize: '16px', marginTop: '25px', fontWeight: '600' },
        value: 'Experience'
      },
      type: 'array',
      location: 'resume',
      children: {
        company_name: {
          style: { fontSize: '14px', marginTop: '10px', textAlign: 'left' },
          placeHolder: 'Company Name'
        },
        years_worked: {
          style: { fontSize: '16px', marginTop: '5px' },
          placeHolder: 'Years Worked'
        }
      }
    },
    emp_education: {
      label: {
        style: { fontSize: '20px', marginTop: '25px', fontWeight: '600' },
        value: 'Education'
      },
      type: 'array',
      location: 'resume',
      children: {
        degree: {
          style: { fontSize: '16px', marginTop: '10px' },
          placeHolder: 'Degree'
        },
        university_name: {
          style: { fontSize: '16px', marginTop: '5px' },
          placeHolder: 'University Name'
        },
        location: {
          style: { fontSize: '16px', marginTop: '5px' },
          placeHolder: 'Location'
        },
        year: {
          style: { fontSize: '16px', marginTop: '5px' },
          placeHolder: 'Year'
        }
      }
    },
    emp_job_title: {
      label: {
        style: { fontSize: '16px', marginTop: '25px', fontWeight: '600' },
        value: 'Job Titles'
      },
      type: 'array',
      location: 'resume',
      children: {
        job_title: {
          style: { fontSize: '14px', marginTop: '10px', textAlign: 'left' },
          placeHolder: 'Job Title'
        },
        duration: {
          style: { fontSize: '16px', marginTop: '5px' },
          placeHolder: 'Duration'
        }
      }
    },
    emp_skills: {
      label: {
        style: { fontSize: '16px', marginTop: '25px', fontWeight: '600' },
        value: 'Skills'
      },
      type: 'string',
      placeHolder: 'Skills',
      location: 'resume',
      style: { fontSize: '16px', marginTop: '5px' }
    },
    emp_certifications: {
      label: {
        style: { fontSize: '16px', marginTop: '25px', fontWeight: '600' },
        value: 'Certifications'
      },
      type: 'array',
      location: 'resume',
      children: {
        name: {
          style: { fontSize: '14px', marginTop: '10px' },
          placeHolder: 'Name'
        },
        description: {
          style: { fontSize: '14px', marginTop: '5px' },
          placeHolder: 'Description'
        }
      }
    },
    emp_achievements: {
      label: {
        style: { fontSize: '20px', marginTop: '25px', fontWeight: '600' },
        value: 'Achievements'
      },
      type: 'string',
      location: 'resume',
      placeHolder: 'Achievements',
      style: { fontSize: '16px', marginTop: '5px' }
    },
    emp_responsibilities: {
      label: {
        style: { fontSize: '20px', marginTop: '25px', fontWeight: '600' },
        value: 'Responsibilities'
      },
      type: 'string',
      location: 'resume',
      placeHolder: 'Responsibilities',
      style: { fontSize: '16px', marginTop: '5px' }
    },
    emp_contact_information: {
      label: {
        style: { fontSize: '20px', marginTop: '25px', fontWeight: '600' },
        value: 'Contact information'
      },
      type: 'string',
      placeHolder: 'Contact Information',
      location: 'resume',
      style: { fontSize: '16px', marginTop: '5px' }
    }
  }
}

export const newResumeTemplate = {
  emp_name: '',
  emp_education: [
    {
      degree: '',
      university_name: '',
      location: '',
      year: ''
    }
  ],
  emp_job_title: [
    {
      job_title: '',
      duration: ''
    }
  ],
  emp_skills: '',
  emp_certifications: [
    {
      name: '',
      description: ''
    }
  ],
  emp_achievements: '',
  emp_responsibilities: '',
  emp_contact_information: '',
  emp_year_experience: [
    {
      company_name: '',
      years_worked: ''
    }
  ],
  emp_project_experience: [
    {
      project_name: '',
      project_location: '',
      project_value: '',
      project_description: '',
      project_duration: '',
      project_building_specifications: '',
      project_achievements: ''
    }
  ],
  emp_professional_summary: ''
}
