import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  getWorkspace,
  workspaceActionsFiles,
  workspaceActionsDrafts,
  getAllProposalNames,
  getAllRfxNames
} from '../../../store/api'
import {
  Loader,
  Container,
  Section,
  SectionFixed,
  ContentEditable,
  Tabs,
  Button,
  ImageGallery,
  ReactSelect,
  TextInput
} from '../../../components'
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  DialogActions,
  DialogContent
} from '@mui/material'
import { useStyles } from './styles'
import { toast } from 'react-toastify'
import { updateWorkspace } from '../../../store/Workspace/Actions'
import { useSelector, useDispatch } from 'react-redux'
import WorkspaceListFiles from '../../../sections/WorkspaceListFiles'
import WorkspaceListDrafts from '../../../sections/WorkspaceListDrafts'
import FileSelector from '../../../sections/FileSelector'
import CloseIcon from '@material-ui/icons/Close'
import ResourceFileView from '../../../sections/ResourceFileView'
import {
  checkUserRoleSuperUser,
  checkUserRoleAdmin,
  checkDomainAccess
} from '../../../utils/User'
import { initalizeS3 } from '../../../utils/AWS'
import { v4 as uuidv4 } from 'uuid'

const Workspace = () => {
  const { workspaceId } = useParams()
  const dispatch = useDispatch()
  const classes = useStyles()

  const [workspaceData, setWorkspaceData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loadingError, setLoadingError] = useState(null)
  const {
    workspace,
    workspace_files = [],
    workspace_drafts = []
  } = workspaceData || {}
  const { name, description } = workspace || {}
  const [activeTab, setActiveTab] = useState(0)
  const [addFile, setAddFile] = useState(false)
  const [addDraft, setAddDraft] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [imageGallery, setImageGallery] = useState([])
  const isSuperUser = checkUserRoleSuperUser()
  const isAdmin = checkUserRoleAdmin()
  const [s3Obj, sets3Obj] = useState({})
  const [draftConfig, setDraftConfig] = useState({})
  const [proposals, setProposals] = useState([])
  const [rfxs, setRfxs] = useState([])
  const showWorkspaceAutomation = checkDomainAccess('workspace_automation')

  const currentUserName = useSelector(
    (state) => state.authenticate.user?.domain?.user_name || ''
  )

  useEffect(() => {
    async function initalizeData() {
      const s3 = await initalizeS3()
      sets3Obj(s3)
      const proposalRes = await getAllProposalNames()
      if (proposalRes.status === 200) {
        const { proposal_names } = proposalRes.data
        const formattedProposals = proposal_names.map((proposal) => {
          return {
            label: proposal.name,
            value: proposal.id
          }
        })
        setProposals(formattedProposals)
      }
      const rfxRes = await getAllRfxNames()
      if (rfxRes.status === 200) {
        const { rfx_list } = rfxRes.data
        const formattedRfxs = rfx_list.map((rfx) => {
          return {
            label: rfx.document_name,
            value: rfx.id
          }
        })
        setRfxs(formattedRfxs)
      }
    }
    initalizeData()
  }, [])

  const setFiles = (files) => {
    setWorkspaceData({
      ...workspaceData,
      workspace_files: files
    })
  }
  const setDrafts = (drafts) => {
    setWorkspaceData({
      ...workspaceData,
      workspace_drafts: drafts
    })
  }

  useEffect(() => {
    const fetchWorkspace = async () => {
      const res = await getWorkspace(workspaceId)
      if (res.status === 200) {
        setWorkspaceData(res.data)
        setLoading(false)
      } else {
        const apiError = 'Failed to load workspace'
        toast.error(apiError)
        setLoadingError(apiError)
      }
    }
    fetchWorkspace()
  }, [workspaceId])

  if (loading) {
    return (
      <Loader
        loading={loading}
        flex
        error={loadingError}
        caption={'loading your workspace'}
      />
    )
  }

  const handleSave = async (field, value) => {
    const request = {
      id: workspace.id,
      mode: 'update'
    }
    if (field === 'name') {
      request.workspace_name = value
      if (value === name) {
        return
      }
    } else if (field === 'description') {
      request.description = value
      if (value === description) {
        return
      }
    }
    const onSuccess = () => {
      setWorkspaceData({
        ...workspaceData,
        workspace: {
          ...workspace,
          [field]: value
        }
      })
    }
    const onFailure = () => {
      setWorkspaceData({
        ...workspaceData,
        workspace: {
          ...workspace,
          [field]: workspace[field]
        }
      })
    }
    dispatch(updateWorkspace(request, onSuccess, onFailure))
  }

  const tabs = showWorkspaceAutomation
    ? [
        {
          label: 'Files',
          children: (
            <WorkspaceListFiles
              files={workspace_files}
              setFiles={setFiles}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              workspace_id={workspaceId}
            />
          )
        },
        {
          label: 'Drafts',
          children: (
            <WorkspaceListDrafts
              drafts={workspace_drafts}
              setDrafts={setDrafts}
              workspace_id={workspaceId}
            />
          )
        }
      ]
    : [
        {
          label: 'Drafts',
          children: (
            <WorkspaceListDrafts
              drafts={workspace_drafts}
              setDrafts={setDrafts}
              workspace_id={workspaceId}
            />
          )
        }
      ]

  const handleCreateDraft = async () => {
    if (!draftConfig?.name) {
      toast.error('Draft name is required')
      return
    }
    let metadata = null
    if (draftConfig?.rfx || draftConfig?.ref_proposals) {
      if (!draftConfig?.rfx || !draftConfig?.ref_proposals) {
        toast.error('RFX and Reference Proposals is required')
        return
      }
      metadata = {
        rfx: draftConfig?.rfx,
        ref_proposals: draftConfig?.ref_proposals
      }
    }
    setAddDraft(false)
    const req = {
      id: uuidv4(),
      workspace_id: workspaceId,
      document_name: draftConfig?.name,
      type: metadata ? 'storyboard' : 'draft',
      metadata,
      created_by_user_name: currentUserName,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      mode: 'add',
      rowLoading: true
    }
    setDrafts([...workspace_drafts, req])
    const res = await workspaceActionsDrafts(req)
    if (res.status === 200) {
      const newDrafts = [...workspace_drafts, req].map((draft) => {
        if (draft.id === req.id) {
          draft.rowLoading = false
        }
        return draft
      })
      setDrafts(newDrafts)
    } else {
      toast.error('Failed to create draft')
      const newDrafts = [...workspace_drafts].filter(
        (draft) => draft.id !== req.id
      )
      setDrafts(newDrafts)
    }
  }
  const handleAddNewFile = () => {
    if (tabs[activeTab].label === 'Files') {
      setAddFile(true)
    } else {
      setAddDraft(true)
      setDraftConfig({})
    }
  }

  const onSelectCallback = async (selectedFiles) => {
    setAddFile(null)
    const req = []
    selectedFiles.forEach((file, i) => {
      req.push({
        id: uuidv4(),
        workspace_id: workspaceId,
        file_id: file.id,
        file_type: file.file_type,
        file_name: file.file_name,
        added_by_user_name: currentUserName,
        added_at: new Date().toISOString(),
        mode: 'add',
        rowLoading: true
      })
    })
    setWorkspaceData({
      ...workspaceData,
      workspace_files: [...workspace_files, ...req]
    })
    const res = await workspaceActionsFiles(req)
    if (res.status === 200) {
      const { data } = res
      const { errors = [], duplicates = [] } = data
      const removeIds = []
      if (!_.isEmpty(errors)) {
        const errorStr = errors.map((e) => e.file_name).join(', ')
        const errorIds = errors.map((e) => e.id)
        removeIds.push(...errorIds)
        toast.error(`Failed to add the following files: ${errorStr}`)
      }
      if (!_.isEmpty(duplicates)) {
        const duplicateStr = duplicates.map((d) => d.file_name).join(', ')
        const duplicateIds = duplicates.map((d) => d.id)
        removeIds.push(...duplicateIds)
        toast.warning(
          `The following files are already in the workspace: ${duplicateStr}`
        )
      }
      const newFiles = [...workspace_files, ...req]
        .filter((file) => !removeIds.includes(file.id))
        .map((file) => {
          if (file.rowLoading) {
            file.rowLoading = false
          }
          return file
        })
      setWorkspaceData({
        ...workspaceData,
        workspace_files: newFiles
      })
    } else {
      toast.error('Failed to add files')
    }
  }

  return (
    <Container>
      <SectionFixed>
        <Box className={classes.workspaceHeader}>
          <ContentEditable
            html={name || ''}
            stripHTML
            placeholder={'Workspace Name'}
            onBlur={(e) => {
              handleSave('name', e.target.innerText)
            }}
            className={classes.workspaceName}
          />
          <ContentEditable
            html={description || ''}
            placeholder={'Workspace Description'}
            stripHTML
            onChange={(e) => {
              setWorkspaceData({
                ...workspaceData,
                workspace: { ...workspace, description: e.target.value }
              })
            }}
            onBlur={(e) => {
              handleSave('description', e.target.innerText)
            }}
            className={classes.workspaceDescription}
          />
        </Box>
      </SectionFixed>
      <Section overFlow>
        <Tabs
          sectionOverFlow
          data={tabs}
          isFlex={true}
          activeTab={activeTab}
          tabChangeCallBack={setActiveTab}
          extraContent={
            <Box className={classes.newButton}>
              <Button onClick={() => handleAddNewFile()}>
                {tabs[activeTab].label === 'Files'
                  ? 'Add File'
                  : 'Create Draft'}
              </Button>
            </Box>
          }
        />
      </Section>
      <Dialog
        fullWidth
        maxWidth="md"
        disableEnforceFocus={true}
        sx={{ margin: '5vh' }}
        open={addDraft}
        onClose={() => setAddDraft(false)}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }}
      >
        <Box className={classes.fileViwerWrapper}>
          <DialogTitle>
            <Box className={classes.splashTextStyle}>Create Draft</Box>
          </DialogTitle>
          <DialogContent>
            <Box className={classes.dailogContentTitle}>
              Draft Name<sup>*</sup>
            </Box>
            <TextInput
              value={draftConfig?.name || ''}
              onChange={(e) => {
                setDraftConfig({
                  ...draftConfig,
                  name: e.target.value
                })
              }}
            />
            {showWorkspaceAutomation && (
              <>
                <Box className={classes.dailogContentTitle}>Select an RFx</Box>
                <ReactSelect
                  options={rfxs}
                  placeholder="Select RFX"
                  isMulti={false}
                  isClearable
                  value={draftConfig?.rfx || null}
                  onChange={(data) => {
                    setDraftConfig({
                      ...draftConfig,
                      rfx: data
                    })
                  }}
                />
                <Box className={classes.dailogContentTitle}>
                  Select Reference Proposals
                </Box>
                <ReactSelect
                  options={proposals}
                  placeholder="Select Proposals"
                  isMulti={true}
                  isClearable
                  value={draftConfig?.ref_proposals || null}
                  onChange={(data) => {
                    setDraftConfig({
                      ...draftConfig,
                      ref_proposals: data
                    })
                  }}
                />
              </>
            )}
          </DialogContent>
          <DialogActions sx={{ marginTop: '20px' }}>
            <Button
              className={classes.splashBtn}
              onClick={() => {
                handleCreateDraft()
              }}
            >
              Create
            </Button>
            <Button
              onClick={() => {
                setAddDraft(false)
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog
        fullScreen
        disableEnforceFocus={true}
        sx={{ margin: '5vh' }}
        open={addFile}
        onClose={() => setAddFile(false)}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }}
      >
        <Box className={classes.fileViwerWrapper}>
          <Container>
            <SectionFixed>
              <DialogTitle>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box>Add Files</Box>
                  <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => setAddFile(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </DialogTitle>
            </SectionFixed>
            <Section overFlow>
              <FileSelector
                setResourceFiles={setSelectedFile}
                onSelectCallback={onSelectCallback}
                disableFiles={workspace_files}
              />
            </Section>
          </Container>
        </Box>
      </Dialog>
      <Dialog
        fullScreen
        sx={{ margin: '5vh' }}
        disableEnforceFocus={true}
        open={!!selectedFile}
        onClose={() => setSelectedFile(null)}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }}
      >
        <Box className={classes.fileViwerWrapper}>
          <ResourceFileView
            s3Obj={s3Obj}
            file={selectedFile}
            setFile={setSelectedFile}
            onClose={() => setSelectedFile(null)}
            isSuperUser={isSuperUser}
            x
            isAdmin={isAdmin}
            setImageGallery={setImageGallery}
          />
        </Box>
      </Dialog>
      {imageGallery.length > 0 && (
        <ImageGallery
          images={imageGallery}
          onClose={() => setImageGallery([])}
          options={{ initialViewIndex: 0 }}
        />
      )}
    </Container>
  )
}

export default Workspace
