import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import { useStyles } from './styles'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import ContentCopyIcon from '@mui/icons-material/ContentCopyOutlined'
import Delete from '@mui/icons-material/DeleteOutline'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import {
  deleteLibraryDocument,
  updateLibraryDocument
} from '../../store/Library/Actions'
import { copyHTMLContent } from '../../utils/CopyHTML'
import AddToCollection from './AddToCollection'
import { IconButton } from '@mui/material'
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Button, TextLineLimiter, useConfirmation } from '../../components'
import SvgIcon from '@material-ui/core/SvgIcon'
import { ReactComponent as SimilarIcon } from '../../assets/images/Similar_Icon.svg'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { checkUserRoleSuperUser, checkUserRoleViewer } from '../../utils/User'
import SuggestionSource from './SuggestionSource'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { useColor } from '../../ThemeContext'
import VisibilityIcon from '@mui/icons-material/Visibility'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import moment from 'moment'
import ReactDOM from 'react-dom/client'
import { toast } from 'react-toastify'

const cleanMarkTags = (htmlString) => {
  return htmlString.replace(/<\/?mark[^>]*>/g, '')
}

const LibraryCard = (props) => {
  const {
    userId = '',
    enableDrag = false,
    onDrag = () => {},
    result_type = '',

    onDragEnd = () => {},
    data,
    onClick,
    index,
    setCurrentCollection,
    isDeleteable = true,
    isAddToCollection = true,
    handleVariation,
    showVariations = false,
    onPDFClick,
    onTitleClick,
    disableEdit = false,
    src = '',
    isSuggestion = false,
    onSuggestion = () => {},
    selectedImages = [],
    setSelectedImages = () => {},
    s3Obj = {},
    setSelectedImageIndex = () => {},
    variationsLabel = 'Similar Results',
    disabled = false
  } = props

  const {
    id,
    metadata_ = {},
    collections = {},
    variationsFromApiKnowledge = []
  } = data
  const variationsCount = variationsFromApiKnowledge.length
  const isSuperUser = checkUserRoleSuperUser()
  const { src: cardSource = 'user' } = metadata_ || {}
  const { ConfirmDialog, showConfirmDialog } = useConfirmation()
  let {
    document_html = '',
    document_name = '',
    narrative_content = '',
    narrative_title = ''
  } = data
  document_html = document_html || narrative_content
  document_html = cleanMarkTags(document_html)
  document_name = document_name || narrative_title
  document_name = cleanMarkTags(document_name)
  const collectionPublic = []
  const collectionPrivate = []
  Object.keys(collections || {}).forEach((key) => {
    const { name, visibility, visible_users } = collections[key]
    if (visibility === 'public') {
      collectionPublic.push(name)
    } else if (visibility === 'private' && visible_users.includes(userId)) {
      collectionPrivate.push(name)
    }
  })

  const classes = useStyles({
    disabled,
    isBodyClickable: !!onClick,
    result_type,
    isTagClickable: !!setCurrentCollection
  })
  const {
    card,
    titleName,
    footerEnd,
    content,
    tagsDetail,
    expandContentDetails,
    titleContainer,
    collapseContent,
    expandContent,
    listActionsContainer,
    tagContainer,
    tagItems,
    expandCollapseContainer,
    contentDisplay,
    moreContentDisplay,
    newLineCharaters,
    defaultHeight,
    titleLink,
    dragIndicator
  } = classes
  const [showSuggestionSource, setShowSuggestionSource] = useState(false)
  const dispatch = useDispatch()

  const isUserViewer = checkUserRoleViewer()

  const handleDeleteClick = (val, collections) => {
    const data = {
      id: val,
      collections
    }
    showConfirmDialog({
      onConfirm: async () => {
        dispatch(deleteLibraryDocument(data))
      },
      confirmationMessageTitle: `Are you sure you want to delete the knowledge "${document_name}" ?`,
      confirmationMessage:
        'This action will also remove the knowledge from all the collections.'
    })
  }

  const extractContent = (html) => {
    return new DOMParser().parseFromString(html, 'text/html').documentElement
      .textContent
  }

  const copyText = (html) => {
    trackEvent(mixpanelEvents.KNOWLEDGE_CONTENT_COPIED, 'SUCCESS', {}, {})
    copyHTMLContent(html)
    toast.success('Copied')
  }

  const stripLinks = (html) => {
    return html
      ?.replaceAll('<a', '<span')
      ?.replaceAll('</a>', '</span>')
      ?.replaceAll('class="PlaygroundEditorTheme__link"', '')
  }

  const onClickCard = () => {
    if (onClick && !window.getSelection().toString() && !disabled) {
      onClick(id)
    }
  }
  const handleDragEnd = () => {
    const ghost = document.getElementById('drag-ghost')
    if (ghost?.parentNode) {
      ghost.parentNode.removeChild(ghost)
    }
    onDragEnd()
  }

  const handleCollectionUpdate = (newValues, newCollections) => {
    const { id } = data
    if (id) {
      const params = {
        id,
        collections: newValues,
        collectionValues: newCollections
      }
      dispatch(updateLibraryDocument(params))
    }
  }

  const handleTagClick = (e, tag) => {
    e.stopPropagation()
    if (setCurrentCollection && !disabled) {
      setCurrentCollection(tag)
    }
  }

  const renderSource = () => {
    const {
      proposal_filename = '',
      pageIndex = 0,
      proposal_id
    } = metadata_ || {}
    const path = `/view/pdf/${proposal_id}/${pageIndex}`
    const onClick = (e) => {
      if (onPDFClick && !disableEdit) {
        onPDFClick(metadata_)
      } else {
        e.stopPropagation()
        window.open(path)
      }
    }
    return (
      proposal_filename && (
        <Box
          sx={{ color: 'grey' }}
          className={titleLink}
          onClick={(e) => onClick(e)}
        >
          <Box sx={{ fontSize: '14px' }}>
            (Page&nbsp;{pageIndex}) {proposal_filename}
          </Box>
        </Box>
      )
    )
  }

  const handleTitleClick = () => {
    if (onTitleClick && !disableEdit) {
      const { id, narrative_title } = data
      onTitleClick(id, narrative_title)
    }
  }

  const handleDrag = (event, data) => {
    const { narrative_title, narrative_content } = data
    const newContent = `<html> <body> <b>${narrative_title}</b> <br>${narrative_content}</body></html>`
    event.dataTransfer.setData('text/html', newContent)
    event.dataTransfer.setData('dragcustomrichtext', JSON.stringify(true))
    const elem = document.createElement('div')
    elem.id = 'drag-ghost'
    const name = cleanMarkTags(narrative_title)
    const jsxContent = (
      <Box
        sx={{
          background: '#DBEAFF',
          display: 'flex',
          alignItems: 'center',
          width: '300px',
          position: 'absolute',
          top: '-9999px',
          padding: '10px',
          gap: '10px',
          fontSize: '14px',
          color: '#797979',
          '& .MuiSvgIcon-root': {
            fontSize: '30px !important'
          }
        }}
      >
        <Box>
          <span dangerouslySetInnerHTML={{ __html: name }} />
        </Box>
      </Box>
    )
    const root = ReactDOM.createRoot(elem)
    root.render(jsxContent)
    document.body.appendChild(elem)
    event.dataTransfer.setDragImage(elem, 0, 0)
  }

  const isEditorDrag = src === 'editor'
  const { selectedColor } = useColor()

  return (
    <>
      <div
        className={card}
        id={id}
        key={index}
        onClick={() => onClickCard()}
        draggable={!disabled && (enableDrag || isEditorDrag)}
        onDragStart={(event) => {
          if (isEditorDrag) {
            handleDrag(event, data)
          } else {
            onDrag(event, data)
          }
        }}
        onDragEnd={() => handleDragEnd()}
      >
        <Box className={titleContainer}>
          <Box className={titleName}>
            <Box>
              {result_type === 'narrative' ? (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div
                    className={onTitleClick ? titleLink : ''}
                    onClick={() => handleTitleClick()}
                  >
                    <TextLineLimiter
                      content={
                        <span
                          dangerouslySetInnerHTML={{ __html: document_name }}
                        />
                      }
                      startAt="<mark>"
                      isStartAtHTML="mark"
                      limit={1}
                    />
                  </div>
                  <div className={classes.typeTag}>
                    {result_type === 'narrative' && 'Knowledge'}
                  </div>
                </div>
              ) : (
                <div
                  className={onTitleClick ? titleLink : ''}
                  onClick={() => handleTitleClick()}
                >
                  <TextLineLimiter
                    content={
                      <span
                        dangerouslySetInnerHTML={{ __html: document_name }}
                      />
                    }
                    startAt="<mark>"
                    isStartAtHTML="mark"
                    limit={1}
                    width="70%"
                  />
                </div>
              )}
            </Box>
            {['search', 'proposal'].includes(cardSource) && renderSource()}
          </Box>
        </Box>
        <Box className={clsx(content, expandContent)}>
          <div className={isEditorDrag ? dragIndicator : ''}>
            {isEditorDrag && <DragIndicatorIcon />}
            <TextLineLimiter
              content={
                <span dangerouslySetInnerHTML={{ __html: document_html }} />
              }
              startAt="<mark>"
              isStartAtHTML="mark"
              limit={2}
            />
          </div>
        </Box>
        <Grid container>
          <Grid xs>
            {isSuggestion ? (
              <Box className={footerEnd}>
                <Box className={tagsDetail}>
                  {isSuperUser && (
                    <Button
                      variant="outlined"
                      style={{
                        marginRight: '10px',
                        cursor: 'auto',
                        background: '#FFFFFF',
                        color: '#797979',
                        border: '1px solid'
                      }}
                    >
                      #Score: {data?.score || 0}
                    </Button>
                  )}
                  {!_.isEmpty(data?.metadata_?.content_ids) && (
                    <Button
                      style={{
                        background: '#DBEAFF',
                        color: selectedColor,
                        border: '0px'
                      }}
                      variant="outlined"
                      onClick={() =>
                        setShowSuggestionSource(!showSuggestionSource)
                      }
                    >
                      <SvgIcon>
                        <SimilarIcon />
                      </SvgIcon>
                      <div style={{ marginLeft: '7px' }}>
                        {showSuggestionSource ? 'Hide Source ' : 'View Source'}
                      </div>
                    </Button>
                  )}
                </Box>
                <Box className={listActionsContainer}>
                  <Button
                    disabled={disabled}
                    style={{
                      background: '#FFFFFF',
                      color: '#797979',
                      border: '1px solid'
                    }}
                    variant="outlined"
                    onClick={(e) => {
                      e.stopPropagation()
                      onSuggestion(data, true)
                    }}
                  >
                    Add to Library
                  </Button>
                  <Button
                    disabled={disabled}
                    style={{
                      marginLeft: '10px',
                      background: '#FFFFFF',
                      color: '#797979',
                      border: '1px solid'
                    }}
                    variant="outlined"
                    onClick={(e) => {
                      e.stopPropagation()
                      onSuggestion(data, false)
                    }}
                  >
                    Ignore
                  </Button>
                </Box>
              </Box>
            ) : (
              <>
                <Box className={footerEnd}>
                  <Box className={tagsDetail}>
                    {handleVariation && variationsCount > 0 && (
                      <Box>
                        <Button
                          style={{
                            background: '#DBEAFF',
                            color: selectedColor,
                            border: '0px'
                          }}
                          variant="outlined"
                          disabled={parseInt(variationsCount) < 1}
                          onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            handleVariation(id)
                          }}
                        >
                          <SvgIcon>
                            <SimilarIcon />
                          </SvgIcon>
                          <div style={{ marginLeft: '7px' }}>
                            {showVariations
                              ? `Hide ${variationsLabel} (${variationsCount})`
                              : `${variationsLabel} (${variationsCount})`}
                          </div>
                        </Button>
                      </Box>
                    )}
                    {collectionPublic.map((collection, index) => (
                      <Box
                        key={index}
                        sx={{
                          wordBreak: 'break-word',
                          padding: '4px 6px',
                          color: selectedColor,
                          border: '1px solid',
                          borderColor: selectedColor,
                          borderRadius: '4px',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                        onClick={(e) => handleTagClick(e, collection)}
                      >
                        <Tooltip title={'Visible to all'}>
                          <LanguageOutlinedIcon
                            sx={{
                              strokeWidth: 1,
                              stroke: '#ffffff',
                              fontSize: '14px'
                            }}
                          />
                        </Tooltip>
                        <Box
                          variant={'span'}
                          sx={{ fontSize: '14px', fontStyle: 'normal' }}
                        >
                          {collection}
                        </Box>
                      </Box>
                    ))}
                    {collectionPrivate.map((collection, index) => (
                      <Box
                        key={index}
                        sx={{
                          wordBreak: 'break-word',
                          padding: '4px 6px',
                          color: selectedColor,
                          border: '1px solid',
                          borderColor: selectedColor,
                          borderRadius: '4px',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                        onClick={(e) => handleTagClick(e, collection)}
                      >
                        <Tooltip title={'Visible to only you'}>
                          <LockOutlinedIcon
                            sx={{
                              strokeWidth: 1,
                              stroke: '#ffffff',
                              fontSize: '14px'
                            }}
                          />
                        </Tooltip>
                        <Box
                          variant={'span'}
                          sx={{ fontSize: '14px', fontStyle: 'normal' }}
                        >
                          {collection}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  <Box className={listActionsContainer}>
                    {data?.number_of_views > -1 && (
                      <Tooltip title="Number of Views">
                        <Box
                          sx={{
                            padding: '6.5px 8px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                            color: '#797979'
                          }}
                        >
                          <VisibilityIcon sx={{ fontSize: '18px' }} />
                          {data?.number_of_views}
                        </Box>
                      </Tooltip>
                    )}
                    {data?.created_at && (
                      <Box
                        sx={{
                          padding: '6.5px 8px',
                          color: '#797979',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px'
                        }}
                      >
                        <AccessTimeIcon sx={{ fontSize: '18px' }} />
                        {moment
                          .utc(data?.created_at)
                          .local()
                          .format('DD MMM YYYY')}
                      </Box>
                    )}
                    {isAddToCollection && !disableEdit && (
                      <AddToCollection
                        collections={collections}
                        onSelect={handleCollectionUpdate}
                        isFilled={collectionPrivate?.length > 0}
                        disabled={disabled || isUserViewer}
                      />
                    )}
                    <Tooltip title="Copy">
                      <IconButton
                        disableRipple
                        disabled={disabled}
                        onClick={(e) => {
                          e.stopPropagation()
                          copyText(document_html)
                        }}
                      >
                        <ContentCopyIcon
                          sx={{ color: '#797979', fontSize: '16px' }}
                        />
                      </IconButton>
                    </Tooltip>
                    {isDeleteable && (
                      <Tooltip title="Delete">
                        <IconButton
                          disableRipple
                          disabled={disabled || isUserViewer}
                          onClick={(e) => {
                            e.stopPropagation()
                            handleDeleteClick(id, collections)
                          }}
                        >
                          <Delete sx={{ color: '#797979', fontSize: '18px' }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </div>
      {ConfirmDialog}
      {isSuggestion && (
        <SuggestionSource
          showSuggestionSource={showSuggestionSource}
          setShowSuggestionSource={() => setShowSuggestionSource(false)}
          data={data}
          selectedImages={selectedImages}
          setSelectedImages={setSelectedImages}
          setSelectedImageIndex={setSelectedImageIndex}
          s3Obj={s3Obj}
        />
      )}
    </>
  )
}

export default LibraryCard
