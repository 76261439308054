import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  tagKey: {
    fontSize: '10px'
  },
  tagValue: {
    color: '#797979',
    marginLeft: '5px'
  },
  tagWrapper: {
    fontSize: '12px',
    border: '1px solid #d4d4d8',
    color: '#797979',
    padding: '2px 4px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 'fit-content',
    alignItems: 'center',
    borderRadius: '30px',
    gap: '3px',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#f8f8f8'
    }
  },
  tertiaryButton: {
    background: 'white !important',
    color: 'black',
    fontFamily: 'PoppinsRegular',
    fontWeight: '600',
    textTransform: 'none',
    borderRadius: '6px',
    boxShadow: 'none'
  }
}))

export { useStyles }
