import { useState, useEffect, useRef } from 'react'
import {
  getDraftStatus,
  rerunDraft,
  getPeopleList,
  getAllProjectIds,
  saveDraftConfig
} from '../../store/api'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Fade,
  Slider,
  Tooltip
} from '@mui/material'
import { toast } from 'react-toastify'
import {
  Container,
  Loader,
  Section,
  SectionFixed,
  TextInput,
  Button,
  ReactSelect
} from '../../components'
import { useStyles } from './styles'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import clsx from 'clsx'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import DeleteIcon from '@mui/icons-material/Delete'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { v4 as uuidv4 } from 'uuid'
import { useSelector } from 'react-redux'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import Lottie from 'react-lottie'
import AiLoader from '../../assets/lottie/ai.json'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { ROUTES } from '../../config/routes'
import SaveIcon from '@mui/icons-material/Save'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import HistoryIcon from '@mui/icons-material/History'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { formatDate } from '../../utils/Date'
import GenerationHistory from './GenerationHistory'

const Storyboard = (props) => {
  const {
    draftId,
    workspaceId,
    storyboards = null,
    setStoryboards = () => {},
    selectedSection = null,
    setSelectedSection = () => {},
    generateContentFromStory = () => {},
    deleteSectionFromEditor = () => {},
    positionChangeCallback = () => {},
    loadingStates = {},
    insertIntoEditor = () => {},
    showConfirmDialog = () => {}
  } = props

  const [logs, setLogs] = useState([])
  const [status, setStatus] = useState(null)
  const [storyboardCopy, setStoryboardCopy] = useState(null)
  const pollInterval = 3000
  const classes = useStyles()
  const [logsLoading, setLogsLoading] = useState(true)
  const [peopleList, setPeopleList] = useState([])
  const [projectList, setProjectList] = useState([])
  const [showSelectPeople, setShowSelectPeople] = useState(false)
  const [showSelectProject, setShowSelectProject] = useState(false)
  const storyBoardRef = useRef(null)
  const isErrored = status === 'errored'
  const isCompleted = status === 'completed'
  const [transitionController, setTransitionController] = useState(false)
  const [transitionDelayController, setTransitionDelayController] =
    useState(true)
  const [requiresSave, setRequiresSave] = useState(false)
  const [saving, setSaving] = useState(false)
  const [showFullLogs, setShowFullLogs] = useState(false)
  const [showHistory, setShowHistory] = useState(false)

  const currentUserId = useSelector(
    (state) => state.authenticate.user?.domain?.id || ''
  )
  const { LIBRARY_PROJECTS, LIBRARY_PEOPLE_RESUME } = ROUTES
  const requestController = useRef(null)

  const enableGenerateAll = Object.values(loadingStates).every(
    (state) => state === false
  )

  useEffect(() => {
    const fetchDataPeople = async () => {
      const res = await getPeopleList()
      if (res.status === 200) {
        const people = res.data
        const options = people.map((person) => {
          const label = person.name
          return {
            label,
            value: person.id,
            name: person.name,
            is_added_by_user: true
          }
        })
        setPeopleList(options)
      }
    }
    const fetchDataProjects = async () => {
      const res = await getAllProjectIds()
      if (res.status === 200) {
        const results = res?.data?.project
        const options = results.map((project) => {
          return {
            label: project?.title,
            value: project?.content_ids[0],
            name: project?.id
          }
        })
        setProjectList(options)
      }
    }
    fetchDataPeople()
    fetchDataProjects()
  }, [])

  const reRunDraft = async () => {
    showConfirmDialog({
      onConfirm: async () => {
        setStatus('initiated')
        setLogsLoading(true)
        setLogs([
          {
            log: 'Regenerating draft',
            timestamp: new Date().toISOString()
          }
        ])
        const req = {
          id: draftId,
          workspace_id: workspaceId
        }
        const res = await rerunDraft(req)
        if (res.status === 200) {
          handleAllDelete()
        } else {
          toast.error('Error regenerating draft')
          setLogs([
            {
              log: 'Regenerate failed',
              timestamp: new Date().toISOString()
            }
          ])
          setStatus('completed')
        }
        const interval = setInterval(() => {
          fetchStatus(interval)
        }, pollInterval)
      },
      confirmationMessageTitle: `Are you sure you want to regenerate the draft?`,
      confirmationMessage: `This will remove all the previously generated content.`
    })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (!requestController?.current) fetchStatus(interval)
    }, pollInterval)
    return () => clearInterval(interval)
  }, [draftId])

  const fetchStatus = async (interval) => {
    requestController.current = true
    const res = await getDraftStatus(draftId)
    if (res.status === 200) {
      const data = res.data
      const { status, logs, storyboards } = data || {}
      if (status === 'completed' || status === 'errored') {
        clearInterval(interval)
        setLogsLoading(false)
      }
      if (storyboards) {
        setStoryboards(storyboards)
        setStoryboardCopy(storyboards)
        setTransitionController(true)
        setTimeout(() => {
          setTransitionDelayController(false)
        }, 1000)
      }
      setLogs(logs)
      setStatus(status)
    } else {
      clearInterval(interval)
      setLogsLoading(false)
      toast.error('Error fetching status')
    }
    requestController.current = false
  }

  const handleReset = () => {
    setStoryboards(storyboardCopy)
    setRequiresSave(false)
    setSelectedSection(null)
    let items = Array.from(storyboardCopy)
    items = items.map((item, index) => {
      return {
        ...item,
        order: index
      }
    })
    positionChangeCallback(items)
  }

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    let items = Array.from(storyboards)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    items = items.map((item, index) => {
      return {
        ...item,
        order: index
      }
    })
    setStoryboards(items)
    positionChangeCallback(items)
    setRequiresSave(true)
  }
  useEffect(() => {
    setShowHistory(false)
  }, [selectedSection])

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? '#E5E5E5' : 'transparent'
  })

  const handleAddNewStory = () => {
    const newSection = {
      id: uuidv4(),
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      status: 'ready_to_be_generated',
      section_type: 'others',
      order: storyboards.length,
      created_by_user: currentUserId,
      metadata_: {
        section_title: '',
        section_type: 'others',
        section_summary: '',
        instructions: '',
        number_of_words: 200
      }
    }
    setStoryboards([...storyboards, newSection])
    setRequiresSave(true)
    setSelectedSection(newSection)
  }

  const handleDelete = (id) => {
    setStoryboards((prev) => prev.filter((s) => s.id !== id))
    setSelectedSection(null)
    setRequiresSave(true)
  }

  useEffect(() => {
    if (selectedSection) {
      storyBoardRef?.current?.scrollTo({
        top: 0
      })
    }
  }, [selectedSection])

  const updateSection = () => {
    setStoryboards((prev) => {
      const index = prev.findIndex((s) => s.id === selectedSection.id)
      const newStoryboards = [...prev]
      newStoryboards[index] = selectedSection
      return newStoryboards
    })
    setSelectedSection(null)
  }

  const renderSection = () => {
    const { id, metadata_ } = selectedSection
    const {
      section_title,
      section_type,
      section_summary,
      instructions,
      sub_sections = [],
      number_of_words = 200
    } = metadata_ || {}
    const isPeopleOrProject =
      section_type === 'project_resume' || section_type === 'people_resume'
    const disableGenerate = loadingStates[id]

    return (
      <Box className={classes.sectionContainer}>
        <Box className={classes.sectionLabel}>
          <Tooltip title="Back">
            <IconButton
              onClick={() => {
                updateSection()
              }}
            >
              <KeyboardBackspaceIcon />
            </IconButton>
          </Tooltip>
          <Box>
            <Tooltip title="View Generation History">
              <IconButton onClick={() => setShowHistory(true)}>
                <HistoryIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete section">
              <IconButton
                disabled={disableGenerate}
                onClick={() => handleDelete(id)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box className={classes.sectionHeaderInput}>
          <Box className={classes.sectionLabel}>Section Title</Box>
          <TextInput
            value={section_title}
            onChange={(e) => {
              setRequiresSave(true)
              setSelectedSection({
                ...selectedSection,
                metadata_: {
                  ...metadata_,
                  section_title: e.target.value
                }
              })
            }}
          />
        </Box>
        <Box className={classes.sectionHeaderInput}>
          <Box className={classes.sectionLabel}>Description</Box>
          <TextInput
            multiline
            rows={3}
            value={section_summary}
            onChange={(e) => {
              setRequiresSave(true)
              setSelectedSection({
                ...selectedSection,
                metadata_: {
                  ...metadata_,
                  section_summary: e.target.value
                }
              })
            }}
          />
        </Box>
        {!isPeopleOrProject && (
          <Box className={classes.sectionHeaderInput}>
            <Box className={classes.sectionLabel}>
              <Box>Sub Sections</Box>
              <Tooltip title="Add Sub Section">
                <IconButton
                  onClick={() => {
                    setRequiresSave(true)
                    setSelectedSection({
                      ...selectedSection,
                      metadata_: {
                        ...metadata_,
                        sub_sections: [...sub_sections, '']
                      }
                    })
                  }}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </Tooltip>
            </Box>
            {sub_sections.map((subSection, subIndex) => (
              <Box
                className={clsx(classes.sectionLabel, classes.subSectionInput)}
                key={subIndex}
              >
                <TextInput
                  value={subSection}
                  onChange={(e) => {
                    setRequiresSave(true)
                    const newSubSections = sub_sections.map((s, i) =>
                      i === subIndex ? e.target.value : s
                    )
                    setSelectedSection({
                      ...selectedSection,
                      metadata_: {
                        ...metadata_,
                        sub_sections: newSubSections
                      }
                    })
                  }}
                />
                <Tooltip title="Remove Sub Section">
                  <IconButton
                    onClick={() => {
                      setRequiresSave(true)
                      const newSubSections = sub_sections.filter(
                        (_, i) => i !== subIndex
                      )
                      setSelectedSection({
                        ...selectedSection,
                        metadata_: {
                          ...metadata_,
                          sub_sections: newSubSections
                        }
                      })
                    }}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            ))}
          </Box>
        )}
        <Box className={classes.sectionHeaderInput}>
          <Box className={classes.sectionLabel}>Instructions to AI</Box>
          <TextInput
            rows={5}
            multiline
            value={instructions}
            onChange={(e) => {
              setRequiresSave(true)
              setSelectedSection({
                ...selectedSection,
                metadata_: {
                  ...metadata_,
                  instructions: e.target.value
                }
              })
            }}
          />
        </Box>
        <Box className={classes.sectionHeaderInput}>
          <Box className={classes.sectionSlider}>
            <Box className={classes.sectionLabel}>Number of Words</Box>
            <TextInput
              type="number"
              value={number_of_words}
              onChange={(e) => {
                setRequiresSave(true)
                setSelectedSection({
                  ...selectedSection,
                  metadata_: {
                    ...metadata_,
                    number_of_words: e.target.value
                  }
                })
              }}
              onBlur={(e) => {
                const value = parseInt(e.target.value)
                if (value < 50) {
                  setRequiresSave(true)
                  setSelectedSection({
                    ...selectedSection,
                    metadata_: {
                      ...metadata_,
                      number_of_words: 50
                    }
                  })
                } else if (value > 2000) {
                  setRequiresSave(true)
                  setSelectedSection({
                    ...selectedSection,
                    metadata_: {
                      ...metadata_,
                      number_of_words: 2000
                    }
                  })
                }
              }}
            />
          </Box>
          <Slider
            marks={[
              {
                value: 50,
                label: ''
              },
              {
                value: 2000,
                label: ''
              }
            ]}
            step={10}
            value={number_of_words}
            min={50}
            max={2000}
            onChange={(e, value) => {
              setRequiresSave(true)
              setSelectedSection({
                ...selectedSection,
                metadata_: {
                  ...metadata_,
                  number_of_words: value
                }
              })
            }}
          />
        </Box>
        {isPeopleOrProject && renderPeopleOrProject()}
        <Box className={classes.sectionButtons}>
          <Button
            onClick={() => handleSingleGenerate(selectedSection)}
            disabled={disableGenerate}
          >
            {disableGenerate ? 'Generating' : 'Generate'}
          </Button>
          <Button
            variant="outline"
            disabled={disableGenerate}
            onClick={() => deleteSectionFromEditor(id)}
          >
            Remove
          </Button>
        </Box>
      </Box>
    )
  }

  const handleAllGenerate = async () => {
    storyboards.forEach((section) => {
      const { status: sectionStatus } = section || {}
      const isDisabled = sectionStatus !== 'ready_to_be_generated'
      if (isDisabled) return
      const req = {
        draft_id: draftId,
        workspace_id: workspaceId,
        storyboard_id: section.id,
        storyboard_type: section.section_type,
        storyboard_config: section.metadata_,
        requestType: 'workspaceGenerate'
      }
      generateContentFromStory(req)
    })
  }

  const handleAllDelete = () => {
    storyboards.forEach((section) => {
      deleteSectionFromEditor(section.id)
    })
  }

  const handleSingleGenerate = async (section) => {
    const req = {
      draft_id: draftId,
      workspace_id: workspaceId,
      storyboard_id: section.id,
      storyboard_type: section.section_type,
      storyboard_config: section.metadata_,
      requestType: 'workspaceGenerate'
    }
    generateContentFromStory(req)
  }

  const navigateToProject = (projectId) => {
    window.open(`${LIBRARY_PROJECTS}/${projectId}`, '_blank')
  }

  const navigateToPeople = (peopleId) => {
    window.open(`${LIBRARY_PEOPLE_RESUME}/${peopleId}`, '_blank')
  }

  const handleAddProjects = (projects) => {
    const newProjects = projects.map((p) => {
      return {
        project_name: p.label,
        collection_id: p.value,
        reason: p.reason
      }
    })
    setRequiresSave(true)
    setSelectedSection({
      ...selectedSection,
      metadata_: {
        ...selectedSection.metadata_,
        projects: newProjects
      }
    })
    setShowSelectProject(false)
  }

  const handleSave = async () => {
    setSaving(true)
    const req = {
      storyboards,
      workspace_id: workspaceId,
      draft_id: draftId
    }
    const res = await saveDraftConfig(req)
    if (res.status === 200) {
      setRequiresSave(false)
      setStoryboardCopy(storyboards)
    } else {
      toast.error('Error saving draft')
    }
    setSaving(false)
  }

  const handleAddPeople = (selectedObj) => {
    const { peopleObj } = selectedObj
    const position = Object.keys(peopleObj)
    const positions = []
    const people = []
    position.forEach((pos) => {
      const { value, number_of_people } = peopleObj[pos]
      positions.push({
        title: pos,
        number_of_people
      })
      const peopleList = value.map((p) => {
        return {
          position: pos,
          name: p.label,
          face_group_id: p.value,
          ...p
        }
      })
      people.push(...peopleList)
    })
    setRequiresSave(true)
    setSelectedSection({
      ...selectedSection,
      metadata_: {
        ...selectedSection.metadata_,
        positions,
        people_recommendations: people
      }
    })
    setShowSelectPeople(false)
  }

  const renderPeopleOrProject = () => {
    const { id, metadata_ } = selectedSection
    const {
      projects = [],
      positions = [],
      section_type,
      people_recommendations = []
    } = metadata_ || {}

    return section_type === 'project_resume' ? (
      <Box className={classes.sectionHeaderInput}>
        <Box className={classes.sectionLabel}>
          <Box>Projects</Box>
          <Tooltip title="Add Project">
            <IconButton
              onClick={() => {
                const values = projects.map((p) => {
                  return {
                    label: p.project_name,
                    value: p.collection_id,
                    reason: p.reason
                  }
                })
                setShowSelectProject(values)
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>

        {projects.map((project, index) => {
          const { project_name, collection_id, reason } = project
          return (
            <Box key={index} className={classes.subProject}>
              <Box className={classes.subProjectContainer}>
                <Box className={classes.subProjectName}>
                  {project_name}
                  <Tooltip title="Open Project in a new tab">
                    <IconButton
                      onClick={() => navigateToProject(collection_id)}
                    >
                      <OpenInNewIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                {reason && (
                  <Box className={classes.subProjectReason}>
                    <AutoAwesomeIcon />
                    {reason}
                  </Box>
                )}
              </Box>
              <Tooltip title="Remove Project">
                <IconButton
                  onClick={() => {
                    setRequiresSave(true)
                    setSelectedSection({
                      ...selectedSection,
                      metadata_: {
                        ...metadata_,
                        projects: projects.filter(
                          (p) => p.collection_id !== collection_id
                        )
                      }
                    })
                  }}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )
        })}
      </Box>
    ) : (
      <Box className={classes.sectionHeaderInput}>
        <Box className={classes.sectionLabel}>
          <Box>Positions</Box>
          <Tooltip title="Add People">
            <IconButton
              onClick={() => {
                const peopleObj = {}
                positions.forEach((pos) => {
                  const { title, number_of_people } = pos
                  peopleObj[title] = {
                    value: people_recommendations.filter(
                      (p) => p.position === title
                    ),
                    number_of_people
                  }
                })
                setShowSelectPeople({ peopleObj })
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>
        {positions.map((position, index) => {
          const { title, number_of_people } = position
          return (
            <Box key={index}>
              <Box className={classes.sectionLabel}>
                <Box>
                  <Box className={classes.sectionPeopleLabel}>{title}</Box>
                  <Box className={classes.sectionPeopleCount}>
                    <AutoAwesomeIcon /> Recommanded: {number_of_people}
                  </Box>
                </Box>
                <Tooltip title="Remove Position">
                  <IconButton
                    onClick={() => {
                      setRequiresSave(true)
                      setSelectedSection({
                        ...selectedSection,
                        metadata_: {
                          ...metadata_,
                          positions: positions.filter((p) => p.title !== title),
                          people_recommendations: people_recommendations.filter(
                            (p) => p.position !== title
                          )
                        }
                      })
                    }}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              {renderPeopleRecommendations(title, people_recommendations)}
            </Box>
          )
        })}
      </Box>
    )
  }

  const renderPeopleSelect = () => {
    const { selectedPosition = null, peopleObj = {} } = showSelectPeople || {}
    const peopleSelected = peopleObj[selectedPosition?.value]?.value
    const peopleValues = peopleSelected?.map((p) => {
      return {
        ...p,
        label: p.name,
        value: p.face_group_id
      }
    })
    const positions = Object.keys(peopleObj).map((key) => {
      return {
        label: key,
        value: key
      }
    })

    return (
      <Dialog
        open={showSelectPeople}
        onClose={() => setShowSelectPeople(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Select People</DialogTitle>
        <DialogContent>
          <Box className={classes.sectionPeopleSelect}>
            <ReactSelect
              options={positions}
              isCreatable
              isClearable
              value={selectedPosition}
              onChange={(value) => {
                setShowSelectPeople({
                  ...showSelectPeople,
                  selectedPosition: value
                })
              }}
              placeholder="Select /Create Position"
              onCreateOption={(value) => {
                setShowSelectPeople({
                  ...showSelectPeople,
                  selectedPosition: {
                    value,
                    label: value
                  },
                  peopleObj: {
                    ...peopleObj,
                    [value]: {
                      value: []
                    }
                  }
                })
              }}
            />
          </Box>
          {selectedPosition && (
            <Box className={classes.sectionPeopleSelect}>
              <ReactSelect
                isMulti
                placeholder="Select People"
                options={peopleList}
                value={peopleValues}
                onChange={(value) => {
                  setShowSelectPeople({
                    ...showSelectPeople,
                    peopleObj: {
                      ...peopleObj,
                      [selectedPosition.value]: {
                        ...peopleObj[selectedPosition.value],
                        value
                      }
                    }
                  })
                }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleAddPeople(showSelectPeople)
            }}
          >
            Add People
          </Button>
          <Button variant="outline" onClick={() => setShowSelectPeople(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderProjectSelect = () => {
    return (
      <Dialog
        open={showSelectProject}
        onClose={() => setShowSelectProject(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Select Project</DialogTitle>
        <DialogContent>
          <ReactSelect
            isMulti
            placeholder="Select Project"
            options={projectList}
            value={showSelectProject}
            onChange={(value) => {
              setShowSelectProject(value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleAddProjects(showSelectProject)
            }}
          >
            Add Project
          </Button>
          <Button variant="outline" onClick={() => setShowSelectProject(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderPeopleRecommendations = (title, people_recommendations = []) => {
    const people = people_recommendations.filter((p) => p.position === title)
    return people.map((person, index) => {
      const {
        name,
        face_group_id,
        thumbnail_url,
        qualification,
        similar_project_experience,
        experience,
        expertise,
        is_added_by_user = false
      } = person
      return (
        <Box key={index} className={classes.subProject}>
          <Box className={classes.subProjectContainer}>
            <Box className={classes.subProjectName}>
              <Box>{name}</Box>
              <Tooltip
                title="Open People in a new tab"
                onClick={() => navigateToPeople(face_group_id)}
              >
                <IconButton>
                  <OpenInNewIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box className={classes.subProjectReason}>
              {!is_added_by_user && (
                <>
                  <AutoAwesomeIcon />
                  <Box>
                    <Box>
                      Similar Project Experience: {similar_project_experience}
                    </Box>
                    <Box>Experience: {experience}</Box>
                    <Box>Expertise: {expertise}</Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
          <Tooltip title="Remove People">
            <IconButton
              onClick={() => {
                setRequiresSave(true)
                setSelectedSection({
                  ...selectedSection,
                  metadata_: {
                    ...selectedSection.metadata_,
                    people_recommendations: people_recommendations.filter(
                      (p) => p.face_group_id !== face_group_id
                    )
                  }
                })
              }}
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )
    })
  }

  return (
    <Container>
      {!selectedSection && (
        <SectionFixed>
          <Box className={classes.headerContainer}>
            {logs?.length > 0 && (
              <Box className={classes.logsTools}>
                {(isErrored || isCompleted) && (
                  <Tooltip title="Rerun Generation">
                    <IconButton onClick={() => reRunDraft()}>
                      <AutorenewIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip
                  title={showFullLogs ? 'Hide all logs' : 'Show all logs'}
                >
                  <IconButton onClick={() => setShowFullLogs(!showFullLogs)}>
                    <HistoryIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            <Box className={classes.logsContainer}>
              {showFullLogs ? (
                <Box className={classes.allLogText}>
                  {logs.map((l, index) => {
                    const { timestamp, log } = l
                    return (
                      <Box key={index} className={classes.log}>
                        <Box className={classes.logText}>
                          <Box className={classes.timestamp} component={'span'}>
                            {formatDate(timestamp)} : &nbsp;
                          </Box>
                          {log}
                        </Box>
                      </Box>
                    )
                  })}
                </Box>
              ) : (
                <>
                  <Box sx={{ width: 'fit-content' }}>
                    {logsLoading ? (
                      <Lottie
                        key={logsLoading + 'lottie_draft'}
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: AiLoader
                        }}
                        height={29}
                        width={29}
                      />
                    ) : (
                      <Lottie
                        key={logsLoading + 'lottie_draft'}
                        options={{
                          loop: false,
                          autoplay: true,
                          animationData: AiLoader,
                          initialSegment: [141, 142]
                        }}
                        segments={[141, 142]}
                        height={29}
                        width={29}
                      />
                    )}
                  </Box>
                  <Box className={classes.logText}>
                    {logs?.length > 0 ? logs[logs.length - 1].log : ''}
                    {logsLoading && (
                      <Box className="blinking-cursor" component={'span'}>
                        &nbsp;|
                      </Box>
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </SectionFixed>
      )}
      {isCompleted && (
        <Section overFlow parentRef={storyBoardRef}>
          {storyboards && !selectedSection && (
            <Box className={classes.storyToolbar}>
              <Box className={classes.sectionButtons}>
                <Button
                  disabled={!enableGenerateAll}
                  onClick={() => {
                    handleAllGenerate()
                  }}
                >
                  Generate All
                </Button>
                <Button
                  variant="outline"
                  disabled={!enableGenerateAll}
                  onClick={() => {
                    handleAllDelete()
                  }}
                >
                  Remove All
                </Button>
              </Box>
              <Box className={classes.addNewStory}>
                {requiresSave && (
                  <Tooltip title="Save Config">
                    <IconButton
                      disabled={saving}
                      onClick={() => {
                        handleSave()
                      }}
                    >
                      <SaveIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {requiresSave && (
                  <Tooltip title="Reset">
                    <IconButton
                      onClick={() => {
                        handleReset()
                      }}
                      disabled={saving}
                    >
                      <RestartAltIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Add new section">
                  <IconButton
                    disabled={saving}
                    onClick={() => {
                      handleAddNewStory()
                    }}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          )}
          {selectedSection ? (
            showHistory ? (
              <GenerationHistory
                storyboard_id={selectedSection?.id || ''}
                insertIntoEditor={insertIntoEditor}
                disabled={loadingStates[selectedSection?.id]}
                onClose={() => setShowHistory(false)}
              />
            ) : (
              renderSection()
            )
          ) : (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable" direction="vertical">
                {(provided, snapshot) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={classes.storyContainer}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {storyboards?.map((section, index) => {
                      const { metadata_, status: sectionStatus } = section || {}
                      const isDisabled =
                        sectionStatus !== 'ready_to_be_generated'
                      const {
                        section_title,
                        sub_sections = [],
                        section_type,
                        positions = [],
                        projects = []
                      } = metadata_ || {}
                      const isPeopleOrProject =
                        section_type === 'project_resume' ||
                        section_type === 'people_resume'
                      return (
                        <Draggable
                          key={index}
                          draggableId={index.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <Fade
                              in={transitionController}
                              style={{
                                transitionDelay: transitionDelayController
                                  ? `${500 + 100 * index}ms`
                                  : '0ms'
                              }}
                            >
                              <Box
                                className={
                                  isDisabled
                                    ? clsx(classes.storyDisabled, classes.story)
                                    : classes.story
                                }
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={() =>
                                  !isDisabled && setSelectedSection(section)
                                }
                              >
                                <Box className={classes.storyTitle}>
                                  {section_title}
                                </Box>
                                {isPeopleOrProject ? (
                                  section_type === 'people_resume' ? (
                                    <Box>
                                      {positions.map((position, index) => {
                                        const { title } = position
                                        return (
                                          <Box
                                            key={index}
                                            className={classes.subSection}
                                          >
                                            {title}
                                          </Box>
                                        )
                                      })}
                                    </Box>
                                  ) : (
                                    <Box>
                                      {projects.map((project, index) => {
                                        const { project_name } = project
                                        return (
                                          <Box
                                            key={index}
                                            className={classes.subSection}
                                          >
                                            {project_name}
                                          </Box>
                                        )
                                      })}
                                    </Box>
                                  )
                                ) : (
                                  sub_sections.map((subSection, subIndex) => (
                                    <Box
                                      key={subIndex}
                                      className={classes.subSection}
                                    >
                                      {subSection}
                                    </Box>
                                  ))
                                )}
                              </Box>
                            </Fade>
                          )}
                        </Draggable>
                      )
                    })}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          )}
          {storyboards && storyboards.length === 0 && (
            <Box className={classes.noStories} sx={{ textAlign: 'center' }}>
              No stories found
            </Box>
          )}
          {renderProjectSelect()}
          {renderPeopleSelect()}
        </Section>
      )}
    </Container>
  )
}

export default Storyboard
