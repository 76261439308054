import { Auth } from 'aws-amplify'
import { setLoading } from '../../store/Common/Actions'
import { userSignOut } from '../../store/Authentication/Actions'
import eventMapping from '../../config/eventMapping'
import trackEvent from '../TrackEvent/TrackEvent'
import store from '../../store/store'
import { useSelector } from 'react-redux'
import {
  userRoleSuperUser,
  userRoleAdmin,
  userRoleUser,
  userRoleAnnotator,
  userRoleViewer
} from '../../config/constants'

export const signOut = async (dispatch) => {
  /* await trackEvent(eventMapping['USER_SIGN_OUT'], "SUCCESS", { action: "logout" }); */
  dispatch(setLoading(true))
  Auth.signOut()
    .then(() => {
      store.dispatch(userSignOut())
      localStorage.clear()
      store.dispatch(setLoading(false))
    })
    .catch((err) => console.log(err))
}

export const checkUserRole = (role) => {
  const user = useSelector((state) => state.authenticate.user)
  const { domain = {} } = user || {}
  const { user_role = 'user' } = domain
  return user_role === role
}

export const checkUserRoleSuperUser = () => {
  return checkUserRole(userRoleSuperUser)
}

export const checkUserRoleAdmin = () => {
  if (checkUserRole(userRoleSuperUser)) {
    return true
  }
  return checkUserRole(userRoleAdmin)
}

export const checkUserRoleUser = () => {
  return checkUserRole(userRoleUser)
}

export const checkUserRoleAnnotator = () => {
  return checkUserRole(userRoleAnnotator)
}

export const checkUserRoleViewer = () => {
  return checkUserRole(userRoleViewer)
}

export const checkDomainAccess = (key) => {
  if (checkUserRoleSuperUser()) {
    return true
  }
  const user = useSelector((state) => state.authenticate.user)
  const { domain = {} } = user || {}
  let { domain_access = {} } = domain
  domain_access = domain_access || {}
  return domain_access?.[key] === true
}

export const checkUserRoleOwner = () => {
  const user = useSelector((state) => state.authenticate.user)
  const { domain = {} } = user || {}
  const { domain_owner = false, user_role = 'user' } = domain || {}
  return domain_owner || user_role === userRoleSuperUser
}

export const hasAccess = (group, key) => {
  const user = useSelector((state) => state.authenticate.user)
  const { domain = {} } = user || {}
  const { domain_config = {}, user_role = '' } = domain
  if (user_role === userRoleSuperUser) {
    return true
  } else if ([userRoleAdmin, userRoleUser].includes(user_role)) {
    return domain_config?.[user_role]?.[group]?.[key] === true
  } else if (user_role === userRoleAnnotator) {
    return false
  } else {
    return false
  }
}

export const userIdToName = (userId, domain) => {
  const { domain_users, id: currentUserId, user_name } = domain
  if (currentUserId === userId) {
    return user_name
  }
  const user = domain_users.find((user) => user.id === userId)
  return user?.user_name || `Unknown User (${userId})`
}
