import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  container: {},
  defaultList: {
    padding: '5px 10px',
    borderBottom: 'solid 1px #E5E5E5'
  },
  collectionsList: {
    padding: '5px 10px'
  },
  toggleButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '12px',
    fontSize: '14px',
    // padding: '10px 15px',
    borderBottom: 'solid 1px #E5E5E5',
    alignItems: 'center'
  },
  tabName: {
    fontSize: '15px',
    color: theme.palette.text.primary,
    fontWeight: 600
  },
  listWrapper: {
    padding: '0px',
    width: '100%',
    '& .Mui-selected:hover': {
      backgroundColor: '#f2f2f2'
    },
    '& .Mui-selected': {
      backgroundColor: '#f2f2f2'
    }
  },
  listButtonWrapper: {
    padding: '1px 6px',
    // minHeight: '30px',
    borderRadius: '5px',
    margin: '1px 0px',
    '&:hover': {
      backgroundColor: '#f2f2f2'
    },
    '& .Mui-selected:hover': {
      backgroundColor: '#f2f2f2'
    }
  },
  listTextWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 3px',
    width: '100%'
  },
  listTextWrapperSelected: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 5px'
  },
  shareIcon: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .MuiSvgIcon-root': {
      fontSize: '25px !important',
      margin: '-4px 0px'
    }
  },
  preIcon: {
    fontSize: '17px !important',
    marginRight: '3px'
  },
  listText: {
    marginLeft: '5px',
    fontWeight: 500,
    width: '100%',
    fontSize: '13px',
    flex: '1',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    '& .MuiTypography-root': {
      fontSize: '14px'
    }
  },
  listHeader: {
    marginLeft: '5px',
    fontFamily: 'PoppinsMedium',
    width: '100%',
    color: '#000000',
    fontSize: '13px',
    flex: '1',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical'
  },
  collectionTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  collectionTitle: {
    fontSize: '16px',
    fontWeight: '500'
  },
  iconButton: {
    padding: '0px'
  },
  iconEmpty: {
    fontSize: '10px !important',
    color: '#797979',
    width: '15px !important'
  },
  icon: {
    fontSize: '20px !important',
    color: '#797979'
  },
  collectionListWrapper: {
    paddingLeft: '10px'
  },
  emptyIcon: {
    width: '24px',
    height: '24px'
  },
  nestedCollection: {
    paddingLeft: '10px'
  },
  hideMenuListText: {
    fontSize: '12px',
    fontWeight: '500',
    color: '#000000',
    width: '100px',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    overflowWrap: 'break-word'
  },
  hideMenuDivider: {
    margin: '0px !important'
  },
  sortWrapper: {
    cursor: 'pointer'
  },
  hideMenu: {
    '& .MuiSvgIcon-root': {
      fontSize: '18px'
    },
    '& .MuiButtonBase-root': {
      padding: '3px 5px'
    },
    '& .MuiMenu-paper': {
      border: '1px solid #E5E5E5',
      marginTop: '10px'
    },
    '& .MuiList-root': {
      padding: '0px'
    }
  }
}))

export { useStyles }
