import React, { forwardRef } from 'react'
import { useStyles } from './styles'
import clsx from 'clsx'

const Button2 = forwardRef((props, ref) => {
  const classes = useStyles()

  return (
    <button
      ref={ref}
      {...props}
      className={clsx(
        classes.button2,
        props.isActive && classes.button2Active,
        props.icon && classes.iconOnly
      )}
    >
      {props.children}
    </button>
  )
})

export default Button2
