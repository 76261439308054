import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Avatar from '@mui/material/Avatar'
import IconButton from '@material-ui/core/IconButton'
import InviteSetting from '../../sections/InviteSetting'
import ProfileSetting from '../../sections/ProfileSetting'
import DomainSetting from '../../sections/DomainSetting'
import SelectDomain from '../../sections/SelectDomain'
import ChatSettings from '../../sections/ChatSettings'
import { Container, Section } from '../../components/Container'
import Tab from '../../components/Tabs'
import { useStyles } from './styles'
import { ROUTES } from '../../config/routes'
import CreateDomain from '../../sections/DomainCreate'
import CognitoIDP from '../../sections/CognitoIdentityProvider'
import {
  userRoleSuperUser,
  userRoleAdmin,
  userRoleAnnotator
} from '../../config/constants'
import Spinner from '../../components/SpinnerBackDrop'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'

import TagCenterSettings from '../../sections/TagCenterSettings'

const Setting = () => {
  const user = useSelector((state) => state.authenticate.user)
  const classes = useStyles()
  const { currentTab } = useParams()
  const { outerContainer } = classes
  const { SETTINGS_PREFIX } = ROUTES
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    trackEvent(mixpanelEvents.SETTINGS_TAB_CLICKED, 'SUCCESS', {}, {})
  }, [])

  const superUserTab = [
    {
      label: 'Profile Details',
      children: <ProfileSetting isFocused={activeTab === 0} />,
      index: 0,
      route: 'profile'
    },
    {
      label: 'Domain Settings',
      children: <DomainSetting isFocused={activeTab === 1} />,
      index: 1,
      route: 'domain'
    },
    {
      label: 'Users',
      children: <InviteSetting isFocused={activeTab === 2} />,
      index: 2,
      route: 'users'
    },
    {
      label: 'Chat Settings',
      children: <ChatSettings isFocused={activeTab === 3} />,
      index: 3,
      route: 'chat'
    },
    {
      label: 'Tag Center',
      children: <TagCenterSettings isFocused={activeTab === 4} />,
      index: 4,
      route: 'tagcenter'
    },
    {
      label: 'Change Domain',
      children: <SelectDomain isFocused={activeTab === 5} />,
      index: 5,
      route: 'changedomain'
    },
    {
      label: 'Create New Domain',
      children: <CreateDomain isSettings={true} isFocused={activeTab === 6} />,
      index: 6,
      route: 'createdomain'
    },
    {
      label: 'Create Identity Providers',
      children: <CognitoIDP isFocused={activeTab === 7} />,
      index: 7,
      route: 'identityproviders'
    }
  ]

  const annotatorTab = [
    {
      label: 'Profile Details',
      children: <ProfileSetting isFocused={activeTab === 0} />,
      index: 0,
      route: 'profile'
    },
    {
      label: 'Change Domain',
      children: <SelectDomain isFocused={activeTab === 1} />,
      index: 1,
      route: 'changedomain'
    }
  ]

  const adminTab = [
    {
      label: 'Profile Details',
      children: <ProfileSetting isFocused={activeTab === 0} />,
      index: 0,
      route: 'profile'
    },
    {
      label: 'Domain Settings',
      children: <DomainSetting isFocused={activeTab === 1} />,
      index: 1,
      route: 'domain'
    },
    {
      label: 'Users',
      children: <InviteSetting isFocused={activeTab === 2} />,
      index: 2,
      route: 'users'
    },
    {
      label: 'Chat Settings',
      children: <ChatSettings isFocused={activeTab === 3} />,
      index: 3,
      route: 'chat'
    },
    {
      label: 'Tag Center',
      children: <TagCenterSettings isFocused={activeTab === 4} />,
      index: 4,
      route: 'tagcenter'
    }
  ]

  const userTab = [
    {
      label: 'Profile Details',
      children: <ProfileSetting isFocused={activeTab === 0} />,
      index: 0,
      route: 'profile'
    }
  ]
  const getUserName = () => {
    const name = user?.domain?.user_name ? user?.domain?.user_name : ' '
    const names = name.split(' ')
    let initials = names[0].substring(0, 1).toUpperCase()
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase()
    }
    return initials
  }

  const getTab = (role = user?.domain?.user_role) => {
    switch (role) {
      case userRoleSuperUser:
        return superUserTab
      case userRoleAdmin:
        return adminTab
      case userRoleAnnotator:
        return annotatorTab
      default:
        return userTab
    }
  }

  const tabs = getTab(user?.domain?.user_role)

  const changeTab = (e) => {
    setActiveTab(e)
    const tag = tabs.find((tab) => tab.index === e)
    if (tag) {
      navigate(`${SETTINGS_PREFIX}/${tag.route}`)
    }
  }

  useEffect(() => {
    if (currentTab !== activeTab) {
      const tab = tabs.find((tab) => tab.route === currentTab)
      if (tab) {
        setActiveTab(tab.index)
      }
    }
  }, [currentTab])

  return (
    <Container>
      <Section className={outerContainer}>
        <Box id="mui-settings-tab" sx={{ height: '100%' }}>
          <Tab
            data={tabs}
            isFlex={true}
            activeTab={activeTab}
            tabChangeCallBack={(e) => changeTab(e)}
            sectionOverFlow={true}
          />
        </Box>
      </Section>
    </Container>
  )
}

export default Setting
