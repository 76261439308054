import React, { useState, useEffect } from 'react'
import Viewer from 'viewerjs'
import 'viewerjs/dist/viewer.min.css'

const ImageGallery = ({ images = [], onClose = () => {}, options = {} }) => {
  const [viewer, setViewer] = useState(null)

  const initializeViewer = () => {
    const element = document.getElementById('image-gallery')
    element.addEventListener('hidden', function () {
      onClose()
    })
    const viewerInstance = new Viewer(element, {
      toolbar: {
        zoomIn: 1,
        zoomOut: 1,
        prev: 1,
        play: {
          show: 1
        },
        next: 1,
        oneToOne: 1,
        reset: 1
      }
    })
    viewerInstance.show()
    setViewer(viewerInstance)
  }

  useEffect(() => {
    return () => {
      if (viewer) {
        viewer.destroy()
      }
    }
  }, [viewer])

  useEffect(() => {
    if (images.length > 0) {
      initializeViewer()
    }
  }, [images])

  return (
    <div
      id="image-gallery"
      style={{ display: 'none', height: '100%', width: '100%' }}
    >
      {images.map((image, index) => (
        <img
          key={index}
          src={image.src}
          data-original={image}
          alt={image?.name ? image.name : index}
        />
      ))}
    </div>
  )
}

export default ImageGallery
