import { useState, useEffect, useReducer } from 'react'
import { Grid } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { Button } from '../../components'
import { ROUTES } from '../../config/routes'
import { isEmpty } from 'lodash'
import Loader from '../../components/Loader'
import { useStyles } from './styles'
import { $generateHtmlFromNodes } from '@lexical/html'
import {
  createLibraryDocument,
  clearLibraryDocument,
  createLibraryDocumentEditor
} from '../../store/Library/Actions'
import InputTags from '../../components/InputTags'
import { NewDocument as LibraryInput } from '../../sections/NewDocument'
import Typography from '../../components/Typography'
import Box from '@material-ui/core/Box'
import LexicalEditor from '../../components/Lexical'
import { Container, Section, SectionFixed } from '../../components/Container'
import CloseIcon from '@material-ui/icons/Close'
import SaveIcon from '@material-ui/icons/Save'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import AddToCollection from '../../sections/LibraryCard/AddToCollection'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'

const CreateLibraryMeta = (props) => {
  const {
    onClose,
    currentDocment = -1,
    redirectOnCreate = false,
    htmlString,
    openEditor = false,
    metaData = {},
    title = '',
    onCreateCallback = () => {},
    setUnSavedChanges = () => {}
  } = props
  const [currentEditorState, setCurrentEditorState] = useState({})
  const [editedHtmlString, setEditedHtmlString] = useState('')
  const libraryState = useSelector((state) => state.library)
  const {
    createLibrary = {},
    libraryList = [],
    libraryCollections = {}
  } = libraryState
  const { public_collections = [], private_collections = [] } =
    libraryCollections
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const classes = useStyles()
  const location = useLocation()
  const path = location?.pathname
  const {
    cancelButton,
    container,
    editWrapper,
    inputLabel,
    libraryEditor,
    inputLabelText,
    tertiaryButton
  } = classes
  const { LIBRARY_KNOWLEDGE } = ROUTES
  const [loading, setLoading] = useState(false)
  const [newCollections, setNewCollections] = useState([])
  const [newCollectionsValues, setNewCollectionsValues] = useState([])

  const pageInput = [
    {
      id: 0,
      label: 'Knowledge Title',
      placeholder: 'Knowledge Title',
      value: title || metaData?.selectedTitle || '',
      error: false,
      errorMessage: 'Knowledge title cannot be empty'
    }
  ]
  const [pageAttributes, setPageAttributes] = useState(pageInput)

  const contentTitle = 'Knowledge Content'

  useEffect(() => {
    const responseEmpty = isEmpty(createLibrary)
    if (!responseEmpty) {
      const id = createLibrary.document_id
      setLoading(false)
      onClose()
      const path = `${LIBRARY_KNOWLEDGE}?libraryId=${id}`
      const callback = navigate(path, {
        state: { newDocument: true }
      })
      dispatch(clearLibraryDocument(callback))
    }
  }, [createLibrary])

  useEffect(() => {
    setEditedHtmlString(htmlString)
  }, [htmlString])

  const handleInputChange = (event, i) => {
    const currentPageValues = JSON.parse(JSON.stringify(pageAttributes))
    currentPageValues[i].value = event.target.value
    currentPageValues[i].error = false
    setPageAttributes(currentPageValues)
  }

  const handleCreate = () => {
    let error = false
    const currentPageValues = JSON.parse(JSON.stringify(pageAttributes))
    currentPageValues.map((inputs) => {
      if (inputs.value === '') {
        inputs.error = true
        error = true
      } else {
        inputs.error = false
      }
      return inputs
    })
    if (!error) {
      const params = {
        document_content: currentEditorState,
        document_html: editedHtmlString,
        document_name: currentPageValues[0].value,
        collections: newCollections,
        metadata: { src: 'user', ...metaData }
      }
      setLoading(true)
      dispatch(createLibraryDocument(params))
    } else setPageAttributes(currentPageValues)
  }

  const handleCreatefromEditor = () => {
    let error = false
    const currentPageValues = JSON.parse(JSON.stringify(pageAttributes))
    currentPageValues.map((inputs) => {
      if (inputs.value === '') {
        inputs.error = true
        error = true
      } else {
        inputs.error = false
      }
      return inputs
    })
    if (!error && editedHtmlString) {
      const params = {
        document_content: currentEditorState,
        document_html: editedHtmlString,
        document_name: currentPageValues[0].value,
        collections: newCollections,
        metadata: metaData
      }
      dispatch(createLibraryDocumentEditor(params))
      onClose()
    } else setPageAttributes(currentPageValues)
  }

  const handleButton = () => {
    trackEvent(mixpanelEvents.KNOWLEDGE_CONTENT_CREATED, 'SUCCESS', {}, {})
    console.log('redirectOnCreate', redirectOnCreate)
    if (redirectOnCreate) {
      handleCreate()
      setUnSavedChanges(false)
    } else {
      handleCreatefromEditor()
    }

    setTimeout(() => {
      onCreateCallback()
    }, 500)
  }

  const handleEditorChange = (currentEditorState, editor) => {
    editor.update(() => {
      setUnSavedChanges(true)
      setCurrentEditorState(currentEditorState)
      const htmlString = $generateHtmlFromNodes(editor, null)
      setEditedHtmlString(htmlString)
    })
  }

  const handleNewCollections = (e, val) => {
    setNewCollections(e)
    const newVal = {}
    val.forEach((el) => {
      newVal[el.id] = {
        ...el
      }
    })
    setNewCollectionsValues(newVal)
  }

  const collectionKeys = Object.keys(newCollectionsValues || {}).map(
    (key) => newCollectionsValues[key].name
  )

  return (
    <Container>
      <Section>
        {loading ? (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className="search-loader-container"
          >
            <Loader loading={loading} caption={'creating your collection'} />
          </Grid>
        ) : (
          <Container>
            <SectionFixed>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '10px'
                }}
              >
                <div>
                  <input
                    type="text"
                    className="editor-title"
                    value={pageAttributes[0].value}
                    onChange={(e) => handleInputChange(e, 0)}
                    placeholder="Untitled"
                  />
                </div>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Box>
                    {!_.isEmpty(newCollectionsValues) && (
                      <>
                        <InputTags
                          showLabel
                          label=""
                          tags={collectionKeys || []}
                          showTagOnly={true}
                          tagClick={() => {}}
                        />
                      </>
                    )}
                  </Box>
                  <AddToCollection
                    collections={newCollectionsValues}
                    onSelect={handleNewCollections}
                    detailed
                  />
                  <Tooltip title="Close Editor">
                    <IconButton
                      disableRipple
                      onClick={() => onClose()}
                      sx={{
                        paddingRight: '0px'
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </SectionFixed>
            <Section
              style={{
                marginBottom: '20px'
              }}
            >
              <Container>
                <Section>
                  {!openEditor ? (
                    <Box
                      className={editWrapper}
                      contentEditable="true"
                      dangerouslySetInnerHTML={{ __html: editedHtmlString }}
                      onBlur={(e) =>
                        setEditedHtmlString(e.currentTarget.innerHTML)
                      }
                    ></Box>
                  ) : (
                    <Box id="library-editor" className={libraryEditor}>
                      <LexicalEditor
                        documentHtml={editedHtmlString}
                        // unSavedChanges={unSavedChanges}
                        // setUnSavedChanges={}
                        initialEditorContent={{}}
                        handleEditorChange={handleEditorChange}
                        enableAI
                      />
                    </Box>
                  )}
                </Section>
              </Container>
            </Section>
            <SectionFixed>
              <Box
                sx={{
                  marginTop: '10px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              >
                {/* <Box>
                  {
                    !_.isEmpty(newCollectionsValues) &&
                    <>
                      <InputTags
                        showLabel
                        label={<span style={{ fontStyle: 'italic' }}>Collections:</span>}
                        tags={collectionKeys || []}
                        showTagOnly={true}
                        tagClick={() => { }}
                      />
                    </>
                  }
                </Box> */}
                <Box>
                  <Button onClick={() => onClose()} className={tertiaryButton}>
                    Cancel
                  </Button>

                  <Button
                    onClick={() => handleButton()}
                    style={{
                      marginLeft: '10px'
                    }}
                  >
                    Save Knowledge
                  </Button>
                </Box>
              </Box>
            </SectionFixed>
          </Container>
        )}
      </Section>
    </Container>
  )
}

export default CreateLibraryMeta
