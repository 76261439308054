import React, { useState, useRef, useEffect } from 'react'
import {
  useLocation,
  createSearchParams,
  useSearchParams,
  useParams,
  useNavigate
} from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import Box from '@material-ui/core/Box'
import Grid from '@mui/material/Grid'
import { useStyles } from './styles'
import Avatar from '@mui/material/Avatar'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import _ from 'lodash'
import { checkExpiry } from '../../utils/AWS'
import ImageIcon from '@mui/icons-material/Image'
import Badge from '@mui/material/Badge'
import Tooltip from '@mui/material/Tooltip'
import { resumeStyle } from './resumeStyle'
import {
  getInitialsFromUserName,
  checkArrOfObjHasAtleastOneValue
} from '../../utils/String'
import {
  Button,
  ImageRender,
  useConfirmation,
  ImageAvatarFallback,
  TextLineLimiter
} from '../../components'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import { checkUserRoleSuperUser, checkUserRoleViewer } from '../../utils/User'
import { ROUTES } from '../../config/routes'
import {
  updatePeople,
  deletePeople,
  deletePeopleMapping,
  updatePeopleTag,
  updatePeopleName
} from '../../store/People/Actions'
import { useDispatch, useSelector } from 'react-redux'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import CircularProgress from '@mui/material/CircularProgress'
import Checkbox from '@mui/material/Checkbox'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { useColor } from '../../ThemeContext'
import EditNoteIcon from '@mui/icons-material/EditNote'
import { TextareaAutosize } from '@mui/base/TextareaAutosize'
import {
  saveMasterPeopleNotes,
  updateResource,
  changeMasterPeopleName
} from '../../store/api'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import StyleIcon from '@mui/icons-material/Style'
import Drawer from '@mui/material/Drawer'
import TagCenterUpdate from '../TagCenterUpdate'
import moment from 'moment'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel
} from '@mui/material'
import { toast } from 'react-toastify'

import TextField from '@mui/material/TextField'
import { initalizeDownload } from '../../utils/DownloadFromS3/DownloadFromS3'
import DownloadIcon from '@mui/icons-material/Download'
import ReactResizeDetector from 'react-resize-detector'
import {
  Description,
  EditNote,
  Label,
  Launch,
  PlaylistRemove
} from '@mui/icons-material'
import {
  updateParsedResumeData,
  updatePeopleInfo,
  updatePeopleNotes
} from '../../store/People/Actions/UpdatePeople/UpdatePeople'
import ReactContentEditable from '../../components/ReactContentEditable'
import ResumeViewer from './ResumeViewer'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import DisplayTags from './DisplayTags'

const ResumeResult = (props) => {
  const {
    people = [],
    s3Obj,
    resumeOpen = false,
    setResumeOpen = () => {},
    setSelectedScreenshots = () => {},
    setIsResumeView = () => {},
    isMerge = false,
    selectedPeople = [],
    setSelectedPeople = () => {},
    loadDelay = 0,
    notesList = [],
    setNotesList = () => {},
    tagState = {},
    currentMasterPeopleIndex = '',
    currentResumeIndex = '',
    isIndividualResumeView = false,
    setIsIndividualResume = () => {},
    showAllPeopleDefault = true,
    viewAllPeople = () => {},
    handlePersonSelect = () => {},
    pageType = 'people',
    refresh = () => {}
  } = props

  const isSuperUser = checkUserRoleSuperUser()

  const location = useLocation()
  const pathname = location.pathname

  const classes = useStyles()
  const [selectedPerson, setSelectedPerson] = useState(
    currentMasterPeopleIndex !== '' ? currentMasterPeopleIndex : null
  )
  const [selectedPerson2, setSelectedPerson2] = useState(1)

  const [selectedResume, setSelectedResume] = useState(
    currentResumeIndex !== '' ? currentResumeIndex : null
  )
  const [showCoverImage, setShowCoverImage] = useState(false)
  const [showAllPeople, setShowAllPeople] = useState(showAllPeopleDefault)
  const [visibleItemCount, setVisibleItemCount] = useState(1)
  const [isSaving, setIsSaving] = useState(false)
  const dispatch = useDispatch()
  const [storedScrollPositionList, setStoredScrollPositionList] = useState(0)
  const [storedScrollPosition, setStoredScrollPosition] = useState(0)
  const [delayedLoading, setDelayedLoading] = useState(false)
  const [showTagDrawer, setShowTagDrawer] = useState(false)

  const [editNotes, setEditNotes] = useState(false)
  const [editedNote, setEditedNote] = useState('')
  const [updatingNotes, setUpdatingNotes] = useState(false)

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [selectedMasterDeleteValue, setSelectedMasterDeleteValue] =
    useState('option1')

  const [hovered, setHovered] = useState(false)
  const [editingNameId, setEditingNameId] = useState('')
  const [editedName, setEditedName] = useState('')

  const navigate = useNavigate()
  const { LIBRARY_PEOPLE_RESUME } = ROUTES

  const { ConfirmDialog, showConfirmDialog } = useConfirmation()
  const notesDebounce = useRef(null)

  const isUserViewer = checkUserRoleViewer()

  useEffect(() => {
    setShowAllPeople(showAllPeopleDefault)
  }, [showAllPeopleDefault])

  useEffect(() => {
    console.log(
      'selectedPersonDetails 2',
      selectedPerson,
      selectedPerson2,
      people,
      pageType
    )
  }, [selectedPerson, selectedPerson2])

  const handleSaveNotes = async (value) => {
    const userID = people[selectedPerson]?.id

    const data = {
      id: userID,
      notes: value
    }

    setEditedNote(value)

    dispatch(updatePeopleNotes(data, () => {}))
  }

  useEffect(() => {
    if (selectedPerson !== null || selectedResume !== null) {
      setEditedNote(notesList[selectedPerson]?.notes)
    }
  }, [selectedPerson])

  useEffect(() => {
    if (selectedPerson !== null || selectedResume !== null) {
      setIsResumeView(true)
    } else {
      setIsResumeView(false)
    }
  }, [selectedPerson, selectedResume])

  useEffect(() => {
    setTimeout(() => {
      setDelayedLoading(true)
    }, loadDelay)
  }, [loadDelay])

  const handleOpenPerson = (index) => {
    const person = people[index]

    if (pageType === 'dashboard') {
      trackEvent(
        mixpanelEvents.DASHBOARD_PEOPLE_RESUME_OPENED,
        'SUCCESS',
        {},
        { people_name: people?.[index]?.name }
      )
      setStoredScrollPosition(window.scrollY)
      handlePersonSelect(person)
    } else {
      trackEvent(
        mixpanelEvents.PEOPLE_MASTER_RESUME_OPENED,
        'SUCCESS',
        {},
        { people_name: people?.[index]?.name }
      )
      const scrollableDiv = document.getElementById('scrollableDiv')
      setStoredScrollPosition(scrollableDiv.scrollTop)
      setResumeOpen(true)
      const { id } = person
      setSelectedPerson(index)
      setSelectedPerson2(person)

      setEditedNote(person?.notes ?? '')
      navigate(
        `${LIBRARY_PEOPLE_RESUME}/${person?.id}/${person?.resumes[0]?.id}`
      )
    }
  }

  const handleOpenResume = (index, showCoverImage = true) => {
    trackEvent(
      mixpanelEvents.PEOPLE_RESUME_OPENED,
      'SUCCESS',
      {},
      { variation_index: index }
    )
    if (pageType === 'dashboard') {
      setStoredScrollPositionList(window.scrollY)
      setSelectedResume(index)
    } else {
      const scrollableDiv = document.getElementById('scrollableDiv')
      setStoredScrollPositionList(scrollableDiv.scrollTop)
      setSelectedResume(index)
      setShowCoverImage(showCoverImage)
      navigate(
        `${LIBRARY_PEOPLE_RESUME}/${people[selectedPerson]?.id}/${people[selectedPerson]?.resumes[index]?.id}`
      )
    }
  }

  const maintainScrollPosition = (isSelected, positionToScrollTo, type) => {
    const handlePeopleScroll = () => {
      const scrollableDiv = document.getElementById('scrollableDiv')
      if (scrollableDiv) {
        scrollableDiv.scrollTop = isSelected ? 0 : positionToScrollTo
      }
    }

    const handleOtherScroll = () => {
      window.scrollTo({
        top: positionToScrollTo,
        behavior: 'instant'
      })
    }

    if (isSelected) {
      if (pageType === 'people') {
        handlePeopleScroll()
      } else if (type === 'resume') {
        handleOtherScroll()
      }
    } else {
      if (pageType === 'people') {
        handlePeopleScroll()
      } else {
        handleOtherScroll()
      }
    }
  }

  useEffect(() => {
    maintainScrollPosition(
      selectedResume !== null,
      storedScrollPositionList,
      'resume'
    )
  }, [selectedResume])

  useEffect(() => {
    maintainScrollPosition(
      selectedPerson !== null,
      storedScrollPosition,
      'person'
    )
  }, [selectedPerson])

  const handleBackToPerson = () => {
    if (pageType === 'dashboard') {
      setSelectedResume(null)
    } else {
      setSelectedResume(null)
      const url = window.location.href
      const uuidv4Regex =
        /[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i
      const match = url.match(new RegExp(uuidv4Regex.source))
      let firstUuid = ''
      if (match && match[0]) {
        firstUuid = match[0]
      }
      navigate(`${LIBRARY_PEOPLE_RESUME}/${firstUuid}`)
    }
  }

  const handleBacktoSearch = () => {
    setSelectedPerson(null)
    setSelectedResume(null)
    setResumeOpen(false)
    navigate(`${LIBRARY_PEOPLE_RESUME}`)
  }

  const openImage = async (payload = []) => {
    const url = _.cloneDeep(payload)
    if (_.isArray(url)) {
      await Promise.all(
        url.map(async (img, index) => {
          const signedSrc = await checkExpiry(img.src, s3Obj)
          url[index].src = signedSrc
        })
      )
      setSelectedScreenshots(url)
    } else {
      const signedSrc = await checkExpiry(url, s3Obj)
      setSelectedScreenshots([{ src: signedSrc }])
    }
  }

  const downloadImage = async (payload = [], resume) => {
    let { proposal_name } = resume
    proposal_name = proposal_name
      ? proposal_name.split('.').slice(0, -1).join('.')
      : 'image'
    if (_.isArray(payload)) {
      payload.forEach(async (img, index) => {
        const fileName = `${proposal_name}_${index + 1}.png`
        initalizeDownload(img, fileName, s3Obj)
      })
    } else {
      const fileName = `${proposal_name}.png`
      initalizeDownload(payload, fileName, s3Obj)
    }
  }

  const openPdf = async (proposal_id, page_number) => {
    page_number = page_number || 0
    console.log('proposal_id', proposal_id, page_number)
    const path = `/view/pdf/${proposal_id}/${page_number}`
    if (path) {
      window.open(path, '_blank')
    }
  }

  const handleSetSelectedPeople = (id, isAdd) => {
    if (isAdd) {
      setSelectedPeople([...selectedPeople, id])
    } else {
      setSelectedPeople(selectedPeople.filter((item) => item !== id))
    }
  }

  const { selectedColor } = useColor()

  const renderTagDrawer = () => {
    const id =
      selectedResume !== null
        ? people[selectedPerson]?.resumes[selectedResume]?.id
        : people[selectedPerson]?.id
    const document_type = selectedResume !== null ? 'resume' : 'masterresume'
    return (
      selectedPerson !== null && (
        <Drawer
          anchor={'right'}
          open={showTagDrawer}
          onClose={() => setShowTagDrawer(false)}
        >
          <Box sx={{ padding: '20px', width: '400px' }}>
            <TagCenterUpdate
              id={id}
              filterTags="people"
              document_type={document_type}
              onUpdateCallback={onUpdateCallback}
              cancelCallback={() => setShowTagDrawer(false)}
            />
          </Box>
        </Drawer>
      )
    )
  }

  const onUpdateCallback = (id, mode, tagsFormated) => {
    if (mode === 'tags') {
      const document_type = selectedResume !== null ? 'resume' : 'masterresume'
      const tags = []
      tagsFormated.forEach((tag) => {
        tags.push(tag.id)
      })
      let payload = {
        type: document_type
      }
      if (document_type === 'resume') {
        payload = {
          ...payload,
          resume_id: id,
          id: people[selectedPerson]?.id,
          tags
        }
      } else {
        payload = {
          ...payload,
          id: [id],
          tags
        }
      }
      if (isIndividualResumeView) {
        if (document_type === 'resume') {
          const resumes = isIndividualResumeView[0]?.resumes || []
          resumes[selectedResume].tags = tags
          setIsIndividualResume([
            {
              ...isIndividualResumeView[0],
              resumes
            }
          ])
        } else {
          setIsIndividualResume([
            {
              ...isIndividualResumeView[0],
              tags
            }
          ])
        }
      }
      dispatch(updatePeopleTag(payload))
    }
  }

  const isMerging = status === 'merging'
  const disabled = isMerging || isSaving

  const handleOpenInEditor = (isMaster = false) => {
    const selectedPeopleData = people[selectedPerson] || {}
    const { resumes = [] } = selectedPeopleData
    const selectedResumeData = isMaster ? resumes[0] : resumes[selectedResume]
    const { proposal_id, id } = selectedResumeData || {}
    const path = `/view/pdf/${proposal_id}/0`
    if (path) {
      window.open(path, '_blank')
    }
  }

  const handleMasterMappingDelete = (
    selectedPerson,
    resumeIndex,
    operation = null
  ) => {
    const { resumes = [], id: masterId, name } = selectedPerson
    const selectedResumeData = resumes[resumeIndex]
    const { id = '' } = selectedResumeData || {}
    showConfirmDialog({
      onConfirm: () => {
        setIsSaving(true)
        const toastId = toast.loading(
          operation === 'remove' ? 'Removing Resume...' : 'Deleting Resume...'
        )
        const newResumes = resumes.filter(
          (resume, index) => index !== resumeIndex
        )
        const req = {
          group_id: masterId,
          people_id: id,
          op_type: operation
        }
        const callback = () => {
          toast.dismiss(toastId)
          setIsSaving(false)
          handleBacktoSearch()
          refresh()
          if (isIndividualResumeView) {
            setIsIndividualResume([
              {
                ...isIndividualResumeView[0],
                resumes: newResumes
              }
            ])
          }
        }
        if (operation === 'remove') {
          trackEvent(
            mixpanelEvents.PEOPLE_RESUME_REMOVED,
            'IN-PROCESS',
            {},
            { people_name: selectedPerson?.name }
          )
        } else {
          trackEvent(
            mixpanelEvents.PEOPLE_RESUME_DELETED,
            'IN-PROCESS',
            {},
            { people_name: selectedPerson?.name }
          )
        }
        dispatch(deletePeopleMapping(req, callback))
      },
      confirmationMessageTitle: (
        <>Are you sure you want to {operation} this resume ?</>
      )
    })
  }

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true)
  }

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false)
  }

  const handleMasterDeleteOptionChange = (event) => {
    setSelectedMasterDeleteValue(event.target.value)
  }

  const handleMasterDelete = () => {
    setIsSaving(true)
    setSelectedPerson(null)
    setSelectedResume(null)
    handleCloseDeleteDialog()
    const id = toast.loading('Please wait...')
    const selectedPeopleData = people[selectedPerson] || {}
    const { id: masterId } = selectedPeopleData
    const callback = () => {
      setIsSaving(false)
      toast.dismiss(id)
      navigate(`${LIBRARY_PEOPLE_RESUME}`)
      if (isIndividualResumeView) {
        handleBacktoSearch()
      }
    }
    trackEvent(
      mixpanelEvents.PEOPLE_RESUME_DELETED,
      'IN-PROCESS',
      {},
      { people_name: selectedPeopleData?.name }
    )
    const deleteType =
      selectedMasterDeleteValue === 'option1' ? 'delete_all' : 'delete_mapping'
    handleCloseDeleteDialog()
    dispatch(deletePeople(masterId, deleteType, callback))
  }

  const handleVerify = (isMaster, verified) => {
    setIsSaving(true)
    const selectedPeopleData = people[selectedPerson] || {}
    const { resumes = [], id: masterId } = selectedPeopleData
    let req = {}
    const toastId = toast.loading('Please wait...')
    if (isMaster) {
      req = {
        id: masterId,
        type: 'master',
        masterId,
        verified,
        updateObj: {
          verified
        }
      }
    } else {
      const selectedResumeData = resumes[selectedResume]
      const { id } = selectedResumeData || {}
      req = {
        id,
        type: 'people',
        masterId,
        verified,
        updateObj: {
          verified
        }
      }
    }
    const callback = () => {
      setIsSaving(false)
      toast.dismiss(toastId)
    }
    dispatch(updatePeople(req, callback))
  }

  const handleMasterResumeNameUpdate = () => {
    const id = toast.loading('Changing name...')
    const data = {
      id: editingNameId,
      employee_name: editedName
    }
    const callback = () => {
      setEditedName('')
      toast.dismiss(id)
    }
    trackEvent(
      mixpanelEvents.PEOPLE_MASTER_RESUME_NAME_UPDATED,
      'Success',
      {},
      { people_name: editedName }
    )
    dispatch(updatePeopleInfo(data, callback))
  }

  const displayPeople = _.isArray(people) ? people : []
  let count = 0
  const displayMode =
    selectedPerson !== null && selectedResume === null
      ? 'master'
      : selectedPerson !== null && selectedResume !== null
      ? 'resume'
      : 'none'

  return (
    delayedLoading &&
    !_.isEmpty(displayPeople) && (
      <Box style={{}}>
        <Box
          sx={{
            display:
              selectedPerson === null &&
              selectedResume === null &&
              displayPeople?.length > 0
                ? 'block'
                : 'none'
          }}
        >
          {pageType === 'dashboard' ? (
            <Box>
              <Box sx={{ textAlign: 'end', marginBottom: '5px' }}>
                <span
                  style={{
                    border: '1px solid',
                    fontSize: '10px',
                    opacity: '1',
                    color: 'rgb(6, 69, 173)',
                    background: 'rgb(219, 234, 255)',
                    borderRadius: '4px',
                    padding: '4px 5px'
                  }}
                >
                  BETA
                </span>
              </Box>
              <ReactResizeDetector handleWidth>
                {({ width }) => {
                  const itemWidth = 150 + 50
                  const calculatedItemCount = Math.floor(width / itemWidth)
                  const peopleList = showAllPeople
                    ? people
                    : people?.slice(0, calculatedItemCount)

                  console.log('peopleList', peopleList, people)
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        overflow: 'hidden',
                        flexWrap: 'wrap'
                      }}
                    >
                      <>
                        {peopleList.map((person, index) => {
                          count = index
                          const {
                            name,
                            profile_picture_location,
                            resumes = []
                          } = person
                          return (
                            <Tooltip
                              arrow
                              title={name}
                              key={index}
                              placement="bottom"
                            >
                              <Box
                                sx={{
                                  flexDirection: 'row',
                                  display: 'flex',
                                  flex: '0 0 150px',
                                  padding: '5px 0',
                                  paddingLeft: '10px',
                                  width: '250px',
                                  alignItems: 'center',
                                  border: 'solid 1px #949494',
                                  borderRadius: '4px',
                                  cursor: 'pointer',
                                  paddingRight: '0'
                                }}
                                onClick={() => handleOpenPerson(index)}
                              >
                                <ImageAvatarFallback
                                  name={name}
                                  profilePic={profile_picture_location}
                                  style={{ height: '50px', width: '50px' }}
                                  openImage={() => {}}
                                  s3Obj={s3Obj}
                                />
                                <Box
                                  sx={{
                                    margin: '0px 10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                  }}
                                >
                                  <Box
                                    sx={{
                                      fontWeight: '600',
                                      fontSize: '16px',
                                      color: '#0645AD',
                                      textOverflow: 'ellipsis',
                                      width: '100px',
                                      overflow: 'hidden',
                                      whiteSpace: 'nowrap'
                                    }}
                                  >
                                    {name}
                                  </Box>
                                  <Box
                                    sx={{
                                      fontSize: '12px',
                                      fontWeight: '400',
                                      marginTop: '5px',
                                      color: '#797979'
                                    }}
                                  >
                                    {resumes.length === 1
                                      ? '1 Resume'
                                      : resumes.length > 1
                                      ? `${resumes.length} Resumes`
                                      : ''}
                                  </Box>
                                </Box>
                              </Box>
                            </Tooltip>
                          )
                        })}
                        {!showAllPeopleDefault &&
                          !(visibleItemCount >= people.length) && (
                            <Box
                              style={{ height: '50px', display: 'contents' }}
                            >
                              <Button
                                variant="outlined"
                                onClick={() => viewAllPeople()}
                              >
                                {'View People'}
                              </Button>
                            </Box>
                          )}
                      </>
                    </Box>
                  )
                }}
              </ReactResizeDetector>
              <Box
                sx={{
                  marginTop: '20px',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    height: '1px',
                    backgroundColor: '#ccc'
                  }}
                />
                <Box
                  sx={{
                    flexGrow: 1,
                    height: '1px',
                    backgroundColor: '#ccc'
                  }}
                />
              </Box>
            </Box>
          ) : (
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: showAllPeople ? 'wrap' : 'nowrap',
                  gap: '15px',
                  overflow: 'hidden',
                  padding: '20px'
                }}
              >
                <>
                  {displayPeople.map((person, index) => {
                    count = index
                    const {
                      name,
                      profile_picture_location,
                      resumes = [],
                      id,
                      status,
                      version
                    } = person
                    const checked = selectedPeople.includes(id)
                    const isMerging = status === 'merging'
                    const disabled = isMerge === 'loading' || isMerging
                    const onClickBox = () => {
                      if (
                        (isMerge === true || isMerge === 'multitag') &&
                        !isMerging
                      ) {
                        handleSetSelectedPeople(id, !checked)
                      } else if (isMerge === false) {
                        handleOpenPerson(index)
                      }
                    }
                    return (
                      <Box
                        key={index}
                        sx={{
                          position: 'relative',
                          display: 'flex',
                          height: '120px',
                          width: '20%',
                          padding: '10px',
                          alignItems: 'center',
                          border: 'solid 1px #949494',
                          borderRadius: '4px',
                          cursor: 'pointer',
                          '&:hover': {
                            '& .edit-icon': {
                              opacity: 1
                            }
                          }
                        }}
                        onClick={() => {
                          editingNameId === '' && onClickBox()
                        }}
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                      >
                        {isMerge && (
                          <Box sx={{ right: 0, top: 0, position: 'absolute' }}>
                            <Checkbox
                              disabled={disabled}
                              sx={{
                                padding: '0px',
                                color: 'black',
                                right: '0',
                                '&.Mui-checked': {
                                  color: 'black'
                                }
                              }}
                              checked={checked}
                            />
                          </Box>
                        )}
                        <Box>
                          <ImageAvatarFallback
                            name={name}
                            profilePic={profile_picture_location}
                            style={{ height: '100px', width: '100px' }}
                            openImage={() => {}}
                            s3Obj={s3Obj}
                          />
                        </Box>
                        <Box
                          sx={{
                            margin: '0px 10px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                          }}
                        >
                          {editingNameId === id ? (
                            <TextField
                              id="standard-helperText"
                              label=""
                              defaultValue={editedName}
                              size="small"
                              variant="standard"
                              onChange={(event) =>
                                setEditedName(event.target.value)
                              }
                              sx={{
                                '& input': {
                                  padding: 0,
                                  color: '#0645AD',
                                  fontWeight: '600'
                                }
                              }}
                            />
                          ) : (
                            <Box
                              sx={{
                                fontWeight: '600',
                                fontSize: '16px',
                                color: '#0645AD',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 4,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                wordBreak: 'break-word'
                              }}
                            >
                              {name}
                            </Box>
                          )}
                          <Box
                            sx={{
                              fontSize: '14px',
                              fontWeight: '400',
                              marginTop: '5px',
                              color: '#797979'
                            }}
                          >
                            {resumes.length === 1
                              ? '1 Resume'
                              : resumes.length > 1
                              ? `${resumes.length} Resumes`
                              : ''}
                          </Box>
                        </Box>
                        {hovered && isSuperUser && (
                          <Box
                            className="edit-icon"
                            sx={{
                              position: 'absolute',
                              top: 5,
                              left: 5,
                              opacity: 0,
                              transition: 'opacity 0.3s'
                            }}
                          >
                            v{version}
                          </Box>
                        )}
                        {hovered && !isMerge && editingNameId === '' && (
                          <Tooltip title="Edit name" arrow>
                            <IconButton
                              className="edit-icon"
                              sx={{
                                position: 'absolute',
                                top: 5,
                                right: 5,
                                opacity: 0,
                                transition: 'opacity 0.3s'
                              }}
                              onClick={(e) => {
                                e.stopPropagation()
                                setEditedName(name)
                                setEditingNameId(id)
                              }}
                              disabled={isUserViewer}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                        {editingNameId === id && (
                          <>
                            <Tooltip title="Save" arrow>
                              <IconButton
                                sx={{ position: 'absolute', top: 5, right: 30 }}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setEditingNameId('')
                                  handleMasterResumeNameUpdate()
                                }}
                              >
                                <DoneIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Cancel" arrow>
                              <IconButton
                                sx={{ position: 'absolute', top: 5, right: 5 }}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setEditingNameId('')
                                  setEditedName('')
                                }}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                      </Box>
                    )
                  })}
                </>
              </Box>
            </Box>
          )}
        </Box>
        {renderTagDrawer()}
        {displayMode === 'resume' && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100vh',
              overflow: 'hidden'
            }}
          >
            <Box
              sx={{
                width: '180px',
                minWidth: '180px',
                flex: 1,
                borderRight: '1px solid #e4e4e7'
              }}
            >
              {/* {!isIndividualResumeView && pageType === 'people' && ( */}
              <Box
                onClick={() => handleBacktoSearch()}
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  // color: '#0645AD',
                  padding: '10px 14px',
                  paddingBottom: '10px',
                  cursor: 'pointer',
                  borderBottom: '1px solid #e4e4e7',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <ArrowBackIosIcon
                  sx={{
                    width: '16px',
                    height: '16px'
                  }}
                />
                <p
                  style={{
                    margin: '0',
                    fontSize: '14px'
                  }}
                >
                  Resumes
                </p>
              </Box>
              {/* )} */}
              <Box
                id="resume_list_parent"
                sx={{
                  height: 'calc(100% - 126px)',
                  overflowY: 'auto'
                }}
              >
                {editNotes ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <TextareaAutosize
                      minRows={10}
                      style={{
                        border: 'none',
                        fontFamily: 'inherit',
                        padding: '5px',
                        maxWidth: '94%',
                        borderRadius: '6px',
                        outline: 'none'
                      }}
                      onChange={(e) => {
                        handleSaveNotes(e.target.value, selectedPerson)
                      }}
                      placeholder="Type your notes here..."
                      value={editedNote}
                    />
                  </div>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      gap: '0px',
                      padding: '14px',
                      justifyContent: 'center'
                    }}
                  >
                    {people[selectedPerson]?.resumes?.map((resume, index) => {
                      const {
                        proposal_id,
                        thumbnail_url = '',
                        screenshots = [],
                        page_number = 1,
                        proposal_name = '',
                        version = ''
                      } = resume
                      const coverImage = _.isEmpty(screenshots)
                        ? thumbnail_url
                        : screenshots[0]?.src
                      const imagePayload = _.isEmpty(screenshots)
                        ? thumbnail_url
                        : screenshots
                      const displayName = proposal_name || proposal_id
                      return (
                        <>
                          <Box
                            sx={{
                              padding: '5px ',
                              border:
                                selectedResume === index
                                  ? 'solid 1.5px'
                                  : 'solid 1.5px #e4e4e7',
                              borderRadius: '4px',
                              '&:hover': {
                                translateY: '-2px'
                              },
                              height: '120px'
                            }}
                            id={'resume_' + index}
                            key={'resume_' + index}
                            onClick={() => handleOpenResume(index)}
                          >
                            <Box
                              sx={{
                                justifyContent: 'center',
                                height: '100%',
                                maxheight: '240px',
                                overflow: 'hidden',
                                // border: '1px solid #E5E5E5',
                                display: 'flex',
                                position: 'relative',
                                cursor: 'pointer',
                                '& .overlayButtons': {
                                  position: 'absolute',
                                  top: '50%',
                                  left: '50%',
                                  transform: 'translate(-50%, -50%)',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                  width: '100%',
                                  borderRadius: '5px',
                                  transition: 'opacity 0.3s ease'
                                },
                                '& .overlayText': {
                                  position: 'absolute',
                                  top: '10%',
                                  left: '20%',
                                  transform: 'translate(-50%, -50%)',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  backgroundColor: 'rgba(0, 0, 0, 0.9)',
                                  color: '#fff',
                                  padding: '5px 10px',
                                  borderRadius: '5px',
                                  opacity: 0,
                                  transition: 'opacity 0.3s ease'
                                },
                                '&:hover img': {
                                  opacity: '0.5'
                                },
                                '&:hover .overlayButtons': {
                                  opacity: '1 !important'
                                },
                                '&:hover .overlayText': {
                                  opacity: 1
                                },
                                '&:hover::before': {
                                  content: '""',
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                  zIndex: 1
                                }
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  height: '100%',
                                  '& .lazy-load-image-background': {
                                    height: '100%'
                                  }
                                }}
                              >
                                <ImageRender
                                  style={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'contain',
                                    overflow: 'hidden'
                                  }}
                                  showLoader={false}
                                  src={coverImage}
                                  s3Obj={s3Obj}
                                  effect="blur"
                                />
                              </Box>
                              <Box
                                className="overlayButtons"
                                sx={{
                                  zIndex: 2
                                }}
                              >
                                <Tooltip title="Open screenshot" arrow>
                                  <IconButton
                                    onClick={() => openImage(imagePayload)}
                                  >
                                    <ImageIcon
                                      sx={{
                                        height: '18px',
                                        width: '18px',
                                        color: '#D3D3D3'
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Open proposal" arrow>
                                  <IconButton
                                    onClick={() =>
                                      openPdf(proposal_id, page_number)
                                    }
                                  >
                                    <OpenInNewRoundedIcon
                                      sx={{
                                        height: '18px',
                                        width: '18px',
                                        color: '#D3D3D3'
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                              {isSuperUser && (
                                <Box className="overlayText" sx={{ zIndex: 2 }}>
                                  v{version}
                                </Box>
                              )}
                            </Box>
                          </Box>
                          <Tooltip title={displayName} arrow>
                            <Box
                              sx={{
                                marginBottom: '8px',
                                cursor: 'pointer'
                              }}
                              onClick={() => openPdf(proposal_id, page_number)}
                            >
                              <TextLineLimiter
                                content={displayName}
                                limit={1}
                                wordBreak={'break-word'}
                                showMore={false}
                              />
                            </Box>
                          </Tooltip>
                        </>
                      )
                    })}
                  </Box>
                )}
                <Dialog
                  open={openDeleteDialog}
                  onClose={handleCloseDeleteDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="options"
                          name="options"
                          value={selectedMasterDeleteValue}
                          onChange={handleMasterDeleteOptionChange}
                        >
                          <FormControlLabel
                            value="option1"
                            control={<Radio />}
                            label="Delete master resume along with all individual resumes"
                          />
                          <FormControlLabel
                            value="option2"
                            control={<Radio />}
                            label="Delete only master resume and keep all individual resumes"
                          />
                        </RadioGroup>
                      </FormControl>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
                    <Button
                      onClick={() => {
                        handleMasterDelete()
                      }}
                      autoFocus
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>

              <div
                style={{
                  padding: '10px',
                  borderTop: '1px solid #e4e4e7',
                  // backgroundColor: '#e4e4e7',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '14px',
                  gap: '5px',
                  justifyContent: 'center',
                  cursor: 'pointer'
                }}
                onClick={() => setEditNotes(!editNotes)}
              >
                <EditNote
                  sx={{
                    height: '16px',
                    width: '16px'
                  }}
                />
                Notes
              </div>
              <div
                style={{
                  padding: '10px',
                  borderTop: '1px solid #e4e4e7',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '14px',
                  gap: '5px',
                  justifyContent: 'center',
                  cursor: 'pointer'
                }}
              >
                <DisplayTags
                  tags={people[selectedPerson]?.resumes[selectedResume]?.tags}
                  tagState={tagState}
                  setShowTagDrawer={setShowTagDrawer}
                />
              </div>
            </Box>
            <Box
              sx={{
                width: '100%'
              }}
              key={
                displayMode === 'resume' ? 'resume' : selectedPerson + '_resume'
              }
              // sx={{ display: displayMode === 'resume' ? 'block' : 'none' }}
            >
              <ResumeViewer
                selectedResumeData={
                  people[selectedPerson]?.resumes[selectedResume]
                }
                selectedResumeIndex={selectedResume}
                openImage={openImage}
                s3Obj={s3Obj}
                selectedPeopleData={people[selectedPerson]}
                handleMasterMappingDelete={handleMasterMappingDelete}
                setShowTagDrawer={setShowTagDrawer}
                tagState={tagState}
                handlePDFViewer={openPdf}
                key={selectedResume}
                downloadImage={downloadImage}
              />
            </Box>
          </div>
        )}
        {ConfirmDialog}
      </Box>
    )
  )
}

export default ResumeResult
