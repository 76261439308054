import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LexicalEditor from '../../components/Lexical'
import { Box, Grid, Typography } from '@mui/material'
import _, { isArray, isEmpty, isEqual, cloneDeep, set } from 'lodash'
import { Container, SectionFixed, Section } from '../../components/Container'
import { updateLibraryDocument } from '../../store/Library/Actions'
import Loader from '../../components/Loader'
import { $generateHtmlFromNodes } from '@lexical/html'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { useStyles } from './styles'
import Tooltip from '@mui/material/Tooltip'
import clsx from 'clsx'
import LibraryVariations from '../../sections/LibraryVariations'
import TextLineLimiter from '../../components/TextLineLimiter'
import TextField from '@mui/material/TextField'
import Info from '../../assets/images/Info.svg'
import History, {
  ReactComponent as HistoryIcon
} from '../../assets/images/History.svg'
import SimilarityIcon from '../../assets/images/Varion-Icon-2.svg'
import { ReactComponent as CloseArrow } from '../../assets/images/close-arrow.svg'
import { ReactComponent as OpenArrow } from '../../assets/images/open-arrow.svg'
import privateVisible from '../../assets/images/private-blue.svg'
import publicVisible from '../../assets/images/public-blue.svg'
import closeBlue from '../../assets/images/close-blue.svg'
import ToolBar from '../../components/ToolBar'
import { Button, Image } from '../../components'
import Dialog from '../../components/Dialog'
import Avatar from '@mui/material/Avatar'
import { fetchLibraryVariations, getLibraryDoc } from '../../store/api'
import AddToCollection from '../../sections/LibraryCard/AddToCollection'
import Checkbox from '@mui/material/Checkbox'
import SvgIcon from '@material-ui/core/SvgIcon'
import useDidMountEffect from '../../hooks'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { useColor } from '../../ThemeContext'
import CircularProgress from '@mui/material/CircularProgress'
import moment from 'moment'
import {
  UPDATE_LIBRARY_VARIAITONS,
  UPDATE_LIBRARY_COLLECTIONS_VIEWS
} from '../../store/actionTypes'
import VisibilityIcon from '@mui/icons-material/Visibility'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { ReactComponent as SimilarIcon } from '../../assets/images/Similar_Icon.svg'
import { getInitialsFromUserName } from '../../utils/String'
import formatTimeAgo from '../../utils/Date/formatTimeAgo'
import { checkUserRoleViewer } from '../../utils/User'

const LibraryEditor = (props) => {
  const {
    onClose = () => {},
    libraryId = '',
    unSavedChanges = false,
    setUnSavedChanges = () => {},
    setDocumentController = () => {},
    documentController = null,
    onUpdateCallback = () => {}
  } = props

  const auth = useSelector((state) => state.authenticate)
  const domain = auth?.user?.domain
  const { domain_id = '', user_name = '' } = domain || {}
  const libraryState = useSelector((state) => state.library)
  const { libraryCollections = {} } = libraryState

  const optionList = [
    {
      id: 0,
      title: 'Info',
      src: Info,
      active: false,
      enable: true,
      displayLabel: 'Information'
    },
    {
      id: 1,
      title: 'History',
      src: History,
      active: false,
      enable: true,
      displayLabel: 'Version History'
    },
    {
      id: 2,
      title: 'Variation',
      src: SimilarityIcon,
      active: false,
      enable: true,
      displayLabel: 'Variations'
    }
  ]

  const dispatch = useDispatch()
  const classes = useStyles()

  const [loading, setLoading] = useState(true)
  const [mode, setMode] = useState('view')
  const [activeToolBar, setActiveToolBar] = useState(0)
  const [toolBarOption, setToolBarOption] = useState(optionList)
  const [currentDocument, setCurrentDocument] = useState({})
  const [editorChanges, setEditorChanges] = useState({})
  const [similarities, setSimilarities] = useState(null)
  const [fetchSent, setFetchSent] = useState(false)
  const [showSidePane, setShowSidePane] = useState(true)
  const [editorLoaded, setEditorLoaded] = useState(false)
  const [saving, setSaving] = useState(false)
  const [collectionOptions, setCollectionOptions] = useState([])
  const [collectionSearchKey, setCollectionSearchKey] = useState('')
  const [showCollectionDropDown, setShowCollectionDropDown] = useState(false)
  const [showCollectionFilter, setShowCollectionFilter] = useState(false)
  const [editorLoading, setEditorLoading] = useState(false)
  const [library_document, setLibraryDocument] = useState({})
  const [loadingSimilarities, setLoadingSimilarities] = useState(false)

  const [createdBy, setCreatedBy] = useState('')
  const isUserViewer = checkUserRoleViewer()
  const currentUserName = useSelector(
    (state) => state.authenticate.user?.domain?.user_name || ''
  )
  const [viewerAccessDisabled, setViewerAccessDisabled] = useState(false)

  const {
    document_name = '',
    contributors = [],
    document_content,
    document_html,
    version,
    document_history,
    updated_by_user,
    updated_at,
    collections = [],
    versionIndex = -1,
    number_of_views = 1,
    created_at
  } = currentDocument
  const editMode = mode === 'edit'

  const { selectedColor } = useColor()
  const rootRef = useRef(null)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      if (libraryId) {
        if (documentController) {
          documentController.abort()
        }
        const newController = new AbortController()
        setDocumentController(newController)
        const res = await getLibraryDoc(libraryId, {
          signal: newController.signal
        })
        if (res.status === 200) {
          setLibraryDocument(res.data)
          const { number_of_views = 1 } = res.data || {}
          dispatch({
            type: UPDATE_LIBRARY_COLLECTIONS_VIEWS,
            payload: {
              id: libraryId,
              number_of_views
            }
          })
        }
        setFetchSent(true)
      }
    }
    if (libraryId) {
      fetchData()
    }
  }, [libraryId])

  useEffect(() => {
    if (fetchSent && !_.isEmpty(library_document)) {
      setLoading(false)
      console.log('library_document', library_document)
      setCurrentDocument({
        ...library_document,

        version: 'latest',
        versionIndex: -1
      })
      const { id } = library_document || {}
      fetchSimilarities(id)
    }
  }, [library_document, fetchSent])

  useEffect(() => {
    const newCollectionOption = []
    const { public_collections = [], private_collections = [] } =
      libraryCollections || {}
    if (public_collections.length > 0) {
      public_collections.forEach((data) => {
        newCollectionOption.push({
          id: data.id,
          name: data?.name,
          visibility: data?.visibility,
          visible_users: data?.visible_users
        })
      })
    }
    if (private_collections.length > 0) {
      private_collections.forEach((data) => {
        newCollectionOption.push({
          id: data.id,
          name: data?.name,
          visibility: data?.visibility,
          visible_users: data?.visible_users
        })
      })
    }

    setCollectionOptions(newCollectionOption)
  }, [libraryCollections])

  const fetchSimilarities = async (id) => {
    if (id) {
      setLoadingSimilarities(true)
      const res = await fetchLibraryVariations({ id, domain_id })
      if (res.status === 200) {
        const { resultsets = [] } = res?.data || {}
        setSimilarities(resultsets)
        setLoadingSimilarities(false)
        dispatch({
          type: UPDATE_LIBRARY_VARIAITONS,
          payload: {
            [id]: resultsets
          }
        })
      }
    }
  }

  const handleValueChange = (value, key) => {
    setCurrentDocument((prevDocument) => ({
      ...prevDocument,
      [key]: value
    }))
    setUnSavedChanges(true)
  }

  const handleEditorChange = async (currentState, editor) => {
    // if (!editorLoaded) {
    //   setUnSavedChanges(true)
    // }
    editor.update(() => {
      const htmlString = $generateHtmlFromNodes(editor, null)
      setEditorChanges((prevDocument) => ({
        ...prevDocument,
        document_content: currentState,
        document_html: htmlString
      }))
      if (editMode) {
        setUnSavedChanges(true)
      }
      setEditorLoaded(false)
    })
  }

  const handleCancelChanges = () => {
    setCurrentDocument({
      ...library_document,
      version: 'latest',
      versionIndex: -1
    })
    setMode('view')
    setUnSavedChanges(false)
    setEditorLoading(true)
    setEditorChanges({})
    setTimeout(() => {
      setEditorLoading(false)
    }, 100)
  }

  const handleEditButton = () => {
    setMode('edit')
  }

  const handleSaveButton = async () => {
    let { id, document_content, document_html, collections, document_name } =
      currentDocument
    if (!_.isEmpty(editorChanges)) {
      document_content = editorChanges.document_content
      document_html = editorChanges.document_html
    }
    const newCollectionData = Object.keys(collections)
    const data = {
      id,
      document_content,
      document_html,
      document_name,
      updated_at: new Date().toISOString(),
      updated_by_user: user_name,
      collections: newCollectionData,
      collectionValues: collections
    }
    setSaving(true)
    setUnSavedChanges(false)
    const callback = () => {
      const { user_name, email, id: userId } = domain || {}
      const { contributors = [], document_history = [] } = currentDocument
      const index = _.findIndex(contributors, { id: userId })
      if (index === -1) {
        contributors.push({
          id: userId,
          name: user_name,
          email,
          role: 'Contributor'
        })
      }
      const newHistory = _.cloneDeep(document_history)
      newHistory.unshift({
        name: library_document?.created_by_user,
        library_data: { ...library_document }
      })
      setCurrentDocument((prevDocument) => ({
        ...prevDocument,
        document_content,
        document_html,
        collections: newCollectionData,
        updated_at: new Date().toISOString(),
        updated_by_user: user_name,
        version: 'latest',
        versionIndex: -1,
        contributors,
        document_history: newHistory
      }))
      setLibraryDocument((prevDocument) => ({
        ...prevDocument,
        document_content,
        document_html,
        collections: newCollectionData,
        updated_at: new Date().toISOString(),
        updated_by_user: user_name,
        version: 'latest',
        versionIndex: -1,
        contributors,
        document_history: newHistory
      }))
      setEditorChanges({})
      setEditorLoading(true)
      setTimeout(() => {
        setEditorLoading(false)
        setMode('view')
      }, 100)
      onUpdateCallback({
        id,
        document_html,
        document_name,
        file_type: 'knowledge'
      })
      setSaving(false)
    }
    dispatch(updateLibraryDocument({ ...data }, callback))
  }

  const handleRemoveCollection = (id) => {
    const modifiedCollection = { ...collections }
    delete modifiedCollection[id]
    setCurrentDocument((prevDocument) => ({
      ...prevDocument,
      collections: modifiedCollection
    }))
    setUnSavedChanges(true)
  }

  const handleAddCollection = (collection) => {
    if (collections[collection.id]) {
      const modifiedCollection = { ...collections }
      delete modifiedCollection[collection.id]
      setCurrentDocument((prevDocument) => ({
        ...prevDocument,
        collections: modifiedCollection
      }))
    } else {
      const modifiedCollection = { ...collections }
      modifiedCollection[collection.id] = {
        name: collection.name,
        visibility: collection.visibility,
        visible_users: collection.visible_users
      }
      setCurrentDocument((prevDocument) => ({
        ...prevDocument,
        collections: modifiedCollection
      }))
    }
    setUnSavedChanges(true)
  }

  const handleNewCollectionData = (createdCollection) => {
    const updatedCollectionList = cloneDeep(collections)
    updatedCollectionList[createdCollection.id] = {
      name: createdCollection.name,
      visibility: createdCollection.visibility,
      visible_users: createdCollection.visible_users
    }
    setCurrentDocument((prevDocument) => ({
      ...prevDocument,
      collections: updatedCollectionList
    }))
    setUnSavedChanges(true)
  }

  const formatDate = (utcDate, showTime = true) => {
    const localDate = moment.utc(utcDate).local()
    if (!showTime) {
      const formattedDate = localDate.format('MMMM D, YYYY')
      return formattedDate
    }

    const formattedDate = localDate.format('MMMM D [at] h:mm A')
    return formattedDate
  }

  const handleCreateNewCollections = () => {
    setShowCollectionDropDown(true)
    setShowCollectionFilter(true)
  }

  const handleRevertHistory = (historyData, versionType, index) => {
    const { library_data } = historyData
    const { document_history, ...rest } = library_data
    setCurrentDocument((prevDocument) => ({
      ...prevDocument,
      ...rest,
      version: versionType,
      versionIndex: index
    }))
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        rootRef.current &&
        !rootRef.current.contains(event.target) &&
        !event.target.closest('li')
      ) {
        setShowCollectionDropDown(false)
        setCollectionSearchKey('')
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const renderHistoryCard = (historyData, versionType, index) => {
    const isSelected = versionIndex === index
    const updated_by_name = historyData?.name
    const updated_at_date_time = historyData?.library_data?.updated_at
    return (
      <Grid
        className={
          isSelected
            ? clsx(classes.historyCardWrapper, classes.historySelectedCard)
            : classes.historyCardWrapper
        }
        onClick={() => handleRevertHistory(historyData, versionType, index)}
      >
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
          <Grid item className={classes.historyDate}>
            {formatDate(updated_at_date_time)}
          </Grid>
          <Grid className={classes.versionName}>
            {versionType ? `${versionType} version` : ''}
          </Grid>
        </Grid>
        <Grid className={classes.historyEditedBy}>
          edited by{' '}
          <span className={classes.historyUserName}>{updated_by_name}</span>
        </Grid>
      </Grid>
    )
  }

  const renderToolBarContent = () => {
    switch (activeToolBar) {
      case 0:
        return (
          <Box className={classes.tagInputs}>
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {' '}
                <Typography className={classes.labelName}>
                  Collections
                </Typography>
              </div>
              {Object.keys(collections).length === 0 && (
                <div style={{ color: '#797979', fontSize: '12px' }}>
                  No collection added{' '}
                </div>
              )}
              <div
                className={classes.collectionEditContainer}
                style={{
                  marginTop: '4px'
                }}
              >
                {Object.entries(collections)
                  .reverse()
                  .map((data, index) => {
                    const { name = '', visibility = '' } = data[1]
                    return (
                      <div className={classes.collectionCard} key={index}>
                        <Image
                          src={
                            visibility === 'private'
                              ? privateVisible
                              : publicVisible
                          }
                          className={classes.collectionVisiblity}
                        />
                        <Tooltip title={name}>
                          <p className={classes.collectionCardTitle}>{name}</p>
                        </Tooltip>
                        {editMode && !saving && (
                          <Image
                            src={closeBlue}
                            className={classes.removeCollection}
                            handleClick={() => {
                              handleRemoveCollection(data[0])
                            }}
                          />
                        )}
                      </div>
                    )
                  })}
              </div>

              <div
                style={{
                  position: 'relative',
                  marginTop: '3px'
                }}
              >
                {editMode && !saving && (
                  <TextField
                    sx={{ width: '100%', marginTop: '4px' }}
                    size="small"
                    value={collectionSearchKey}
                    placeholder={'+ Add to collections'}
                    onClick={() => {
                      setShowCollectionDropDown(true)
                    }}
                    onChange={(e) => {
                      setCollectionSearchKey(e.target.value)
                    }}
                  />
                )}

                {showCollectionDropDown && (
                  <Box
                    id="root-input"
                    ref={rootRef}
                    style={{
                      position: 'absolute',
                      top: '43px',
                      borderRadius: '4px',
                      background: 'white',
                      maxHeight: '350px',
                      overflow: 'auto',
                      border: '1px solid #0000001a',
                      zIndex: '1000',
                      width: '100%'
                    }}
                  >
                    <div>
                      {collectionOptions.map((collection, index) => {
                        const { name = '', visibility = '', id } = collection
                        const isChecked = Object.keys(collections).includes(id)
                        return (
                          <div key={index}>
                            <div
                              onClick={() => {
                                handleAddCollection(collection)
                              }}
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                borderBottom: '1px solid #0000001a',
                                padding: '0 10px 10px',
                                paddingRight: '15px'
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <Checkbox
                                  size="small"
                                  checked={isChecked}
                                  sx={{
                                    color: '#949494',
                                    '&.Mui-checked': {
                                      color: selectedColor
                                    }
                                  }}
                                />{' '}
                                <p
                                  style={{
                                    margin: '0 10px',
                                    whiteSpace: 'break-spaces'
                                  }}
                                >
                                  {name}
                                </p>
                              </div>
                              <Image
                                src={
                                  visibility === 'private'
                                    ? privateVisible
                                    : publicVisible
                                }
                                className={classes.collectionDropDown}
                              />
                            </div>
                          </div>
                        )
                      })}
                      <div
                        style={{
                          background: 'white',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'sticky',
                          bottom: '0'
                        }}
                      >
                        {!showCollectionFilter ? (
                          <Button
                            style={{ background: selectedColor, width: '100%' }}
                            onClick={handleCreateNewCollections}
                          >
                            Create New Collection
                          </Button>
                        ) : (
                          <AddToCollection
                            collections={collections}
                            onSelect={handleAddCollection}
                            view="addOnly"
                            newCollectionLabel="Create New Collection"
                            setShowCollectionFilter={setShowCollectionFilter}
                            handleNewCollectionData={handleNewCollectionData}
                          />
                        )}
                      </div>
                    </div>
                  </Box>
                )}
              </div>
            </div>
            <div id="text-input" style={{ position: 'relative' }}>
              <Box>
                <Typography className={classes.labelName}>
                  <span>Contributors</span>
                </Typography>
                <Grid
                  container
                  direction={'row'}
                  alignItems="center"
                  sx={{ gap: '1px' }}
                >
                  {contributors &&
                    contributors.map((contributor, index) => {
                      const { name = '', email = '', role = '' } = contributor
                      return (
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            gap: 4,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                            padding: '5px 0'
                          }}
                        >
                          <Tooltip title={email} placement="top-start">
                            <div
                              style={{
                                display: 'flex',
                                width: '100%',
                                alignItems: 'center',
                                gap: '5px'
                                // justifyContent: "space-between",
                              }}
                            >
                              <Avatar
                                style={{
                                  backgroundColor: getColorsForInitials(name),
                                  color: 'white',
                                  fontSize: '12px'
                                }}
                                className={classes.avatarWrapper}
                              >
                                {getInitialsFromUserName(name.toUpperCase())}
                              </Avatar>
                              <p className={classes.infoTextWrapper}>{name}</p>
                            </div>
                          </Tooltip>

                          <div>
                            <p
                              className={classes.infoTextWrapper}
                              style={{
                                color: '#6b7280'
                              }}
                            >
                              {role}
                            </p>
                          </div>
                        </div>
                      )
                    })}
                </Grid>
              </Box>
            </div>

            <Box>
              <Typography
                className={classes.labelName}
                style={
                  {
                    // marginBottom: "4px"
                  }
                }
              >
                Insights
              </Typography>
              <div
                // key={index}
                style={{
                  display: 'flex',
                  gap: 4,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  padding: '5px 0'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    gap: '5px'
                    // justifyContent: "space-between",
                  }}
                >
                  <VisibilityIcon sx={{ fontSize: '16px', color: '#52525b' }} />
                  <p
                    style={{
                      fontSize: '12px',
                      margin: '0'
                    }}
                  >
                    {number_of_views}
                  </p>
                </div>

                <div>
                  <p
                    className={classes.infoTextWrapper}
                    style={{
                      color: '#6b7280'
                    }}
                  >
                    Views
                  </p>
                </div>
              </div>
              <div
                // key={index}
                style={{
                  display: 'flex',
                  gap: 4,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  padding: '5px 0'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    // width: "100%",
                    alignItems: 'center',
                    gap: '5px'
                    // justifyContent: "space-between",
                  }}
                >
                  <AccessTimeIcon sx={{ fontSize: '16px' }} />
                  <p
                    style={{
                      fontSize: '12px',
                      margin: '0'
                    }}
                  >
                    {formatDate(created_at)}
                  </p>
                </div>

                <div>
                  <p
                    className={classes.infoTextWrapper}
                    style={{
                      color: '#6b7280'
                    }}
                  >
                    Created on
                  </p>
                </div>
              </div>
              <Grid container direction={'column'}>
                <Grid item>
                  {created_at && (
                    <Box
                      sx={{
                        padding: '8px',
                        color: '#797979',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px'
                      }}
                    ></Box>
                  )}
                </Grid>
                <Grid item>
                  {similarities && similarities.length > 0 && (
                    <Box
                      sx={{
                        padding: '8px',
                        color: '#797979',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px'
                      }}
                    >
                      <SvgIcon>
                        <SimilarIcon />
                      </SvgIcon>
                      Found <b>{similarities.length}</b> variations
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        )
      case 1:
        return (
          <div>
            {renderHistoryCard(
              {
                library_data: { ...library_document },
                name: library_document.updated_by_user
              },
              'latest',
              -1
            )}
            {document_history.map((history, index) => {
              return renderHistoryCard(
                history,
                document_history.length - 1 === index ? 'original' : '',
                index
              )
            })}
          </div>
        )
      case 2:
        return loadingSimilarities ? (
          <Loader loading={loadingSimilarities} caption="" flex />
        ) : (
          <LibraryVariations similarities={similarities} />
        )
      default:
        return <></>
    }
  }

  const handleOptions = (optionId) => {
    const modifiedOption = optionList

    if (optionId === activeToolBar) {
      setShowSidePane(false)
      setActiveToolBar(null)
      modifiedOption[optionId].active = false
      setToolBarOption(modifiedOption)
    } else {
      setShowSidePane(true)
      setActiveToolBar(optionId)
      modifiedOption[optionId].active = true
      setToolBarOption(modifiedOption)
    }
  }

  useEffect(() => {
    const created_by = contributors.map((contributor) => {
      if (contributor.role === 'Creator') {
        return contributor.name
      }
      return ''
    })
    if (created_by) {
      setCreatedBy(created_by)
    }
  }, [contributors])

  useEffect(() => {
    const isViewerAccessDisabled = isUserViewer && createdBy !== currentUserName
    setViewerAccessDisabled(isViewerAccessDisabled)
  }, [currentUserName, createdBy])

  return (
    <Box className={classes.libraryContainer}>
      {loading ? (
        <Loader
          loading={loading}
          caption="opening your library document"
          flex
        />
      ) : (
        <Container>
          <Section overFlow>
            <Grid
              container
              sx={{ height: '100%', width: '100%' }}
              direction={'row'}
            >
              <Grid
                item
                className={
                  showSidePane
                    ? clsx(classes.contentContainer, classes.contentCustomWidth)
                    : clsx(classes.contentContainer, classes.contentFullWidth)
                }
              >
                <Container>
                  <SectionFixed
                    style={{
                      padding: '8px 18px',
                      paddingBottom: editMode && '10px'
                    }}
                  >
                    <div>
                      <div className={classes.editorAction}>
                        <div className={clsx(classes.editorTitle)}>
                          <div>
                            <input
                              type="text"
                              style={{
                                width: '100%',
                                pointerEvents: editMode ? 'auto' : 'none'
                              }}
                              className="editor-title"
                              value={document_name}
                              onChange={(e) =>
                                handleValueChange(
                                  e.target.value,
                                  'document_name'
                                )
                              }
                              placeholder="Narrative Title"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </SectionFixed>
                  <Box
                    id="library-editor"
                    className={
                      editMode
                        ? classes.libraryEditor
                        : clsx(classes.libraryEditor, classes.libraryContent)
                    }
                  >
                    {editorLoading ? (
                      <Loader loading={editorLoading} caption="" flex />
                    ) : (
                      <div
                        style={{
                          height: '98%',
                          padding: '0 16px'
                        }}
                        onDoubleClick={() => {
                          !viewerAccessDisabled && handleEditButton()
                        }}
                      >
                        <LexicalEditor
                          isEditable={editMode && !saving}
                          documentHtml={document_html}
                          initialEditorContent={
                            !_.isEmpty(document_content) ? document_content : {}
                          }
                          handleEditorChange={handleEditorChange}
                          enableAI
                          pageType={'library'}
                        />
                      </div>
                    )}
                  </Box>
                  <Grid
                    container
                    direction={'column'}
                    className={classes.currentVersionInfo}
                  >
                    <Grid
                      container
                      alignItems={'flex-end'}
                      justifyContent="space-between"
                      direction={'row'}
                    >
                      <Grid item className={classes.editorLastUpdates}>
                        <div
                          style={{
                            fontSize: '12px',
                            color: '#3f3f46',
                            fontWeight: 600,
                            letterSpacing: '0.03em'
                          }}
                        >
                          {similarities && similarities.length > 0 ? (
                            <span>Found {similarities.length} variations</span>
                          ) : (
                            <span>No variations found</span>
                          )}
                        </div>

                        <Box>
                          <div
                            style={{
                              fontSize: '12px',
                              color: '#18181b',
                              fontWeight: 600,
                              letterSpacing: '0.03em'
                            }}
                          >
                            <span
                              style={{
                                fontSize: '12px'
                              }}
                            >
                              Last edited by {updated_by_user}
                            </span>{' '}
                            -{' '}
                            <span
                              style={{
                                fontWeight: 500,
                                color: '#52525b'
                              }}
                            >
                              {formatTimeAgo(updated_at)}
                            </span>
                          </div>
                        </Box>
                        {versionIndex > -1 && (
                          <SvgIcon>
                            <HistoryIcon />
                          </SvgIcon>
                        )}
                      </Grid>
                      <Grid item className={classes.actionButtonWrapper}>
                        {editMode && (
                          <Tooltip title="Cancel changes">
                            <Button
                              className={classes.cancelButton}
                              onClick={handleCancelChanges}
                            >
                              Cancel
                            </Button>
                          </Tooltip>
                        )}
                        {!editMode ? (
                          <>
                            <Tooltip title="Edit Changes">
                              <Button
                                className={classes.buttonWrapper}
                                onClick={handleEditButton}
                                disabled={viewerAccessDisabled}
                              >
                                <Box className={classes.buttonText}>Edit</Box>
                              </Button>
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            {version === 'latest' ? (
                              <Tooltip title="Save Changes">
                                <Button
                                  disabled={saving}
                                  className={
                                    unSavedChanges
                                      ? classes.buttonWrapper
                                      : clsx(
                                          classes.buttonWrapper,
                                          classes.disableSave
                                        )
                                  }
                                  onClick={() => handleSaveButton()}
                                >
                                  <Box className={classes.buttonText}>
                                    {saving ? (
                                      <CircularProgress size={16} />
                                    ) : (
                                      'Save'
                                    )}
                                  </Box>
                                </Button>
                              </Tooltip>
                            ) : (
                              <>
                                <Tooltip title="Restore Changes">
                                  <Button
                                    className={classes.buttonWrapper}
                                    disabled={saving}
                                    onClick={() => handleSaveButton()}
                                  >
                                    <Box className={classes.buttonText}>
                                      {saving ? (
                                        <CircularProgress size={16} />
                                      ) : (
                                        'Restore'
                                      )}
                                    </Box>
                                  </Button>
                                </Tooltip>
                              </>
                            )}
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
              {showSidePane && (
                <Grid
                  container
                  className={
                    showSidePane
                      ? classes.infoPanel
                      : clsx(classes.infoPanel, classes.toolBarOptions)
                  }
                >
                  <Grid container className={classes.infoInnerContainer}>
                    {showSidePane && (
                      <Grid item className={classes.infoPanelTitle}>
                        {toolBarOption?.[activeToolBar]?.displayLabel ?? ''}
                      </Grid>
                    )}
                    {showSidePane && (
                      <Grid item className={classes.narrativeContent}>
                        {renderToolBarContent()}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
              <Grid item className={classes.toolBarContainer}>
                <Grid container direction="column">
                  <Grid item style={{ display: 'contents' }}>
                    <Tooltip title="Close Editor">
                      <Grid
                        style={{
                          borderBottom: '1px solid #EEEEEE',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '100%'
                        }}
                      >
                        <IconButton disableRipple onClick={() => onClose()}>
                          <CloseIcon sx={{ color: 'grey' }} />
                        </IconButton>
                      </Grid>
                    </Tooltip>
                    {/* <IconButton
                      disableRipple
                      onClick={() => {
                        setShowSidePane(!showSidePane)
                      }}
                    >
                      <SvgIcon className={classes.collapseIcon}>
                        {showSidePane ? <OpenArrow /> : <CloseArrow />}
                      </SvgIcon>
                    </IconButton> */}
                  </Grid>
                  <Grid item className={classes.toolbarWrapper}>
                    {' '}
                    <ToolBar
                      optionList={toolBarOption}
                      handleOptions={handleOptions}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Section>
        </Container>
      )}
    </Box>
  )
}

export default LibraryEditor

const getColorsForInitials = (name) => {
  const colors = [
    '#FFC0CB',
    '#FFA07A',
    '#FFD700',
    '#FF4500',
    '#FF6347',
    '#FF69B4',
    '#FF1493',
    '#FF00FF',
    '#FF00FF',
    '#FF69B4',
    '#FF6347',
    '#FF4500',
    '#FFD700',
    '#FFA07A',
    '#FFC0CB'
  ]

  const charCode = name.charCodeAt(0)
  return colors[charCode % colors.length]
}
