import { FETCH_TAGS } from '../../actionTypes'
import { getTagCenter } from '../../api'
import { toast } from 'react-toastify'

const getTags = (callback) => async (dispatch) => {
  const res = await getTagCenter()
  if (res.status === 200) {
    const { data } = res
    const formatedData = formatData(data)
    dispatch({
      type: FETCH_TAGS,
      payload: formatedData,
      callback
    })
  } else {
    toast.error(
      res?.response?.data?.error || 'Something went wrong while fetching tags'
    )
    dispatch({
      type: FETCH_TAGS,
      payload: null,
      callback
    })
  }
}

const formatData = (data) => {
  const formatedData = {}
  data.forEach((element) => {
    if (formatedData[element.key]?.data) {
      formatedData[element.key].data.push(element)
    } else {
      formatedData[element.key] = {
        data: [element],
        type: element.type,
        value_type: element?.value_type,
        key_type: element?.key_type,
        editable: element?.editable !== false,
        id: element.id
      }
    }
  })
  return formatedData
}

export default getTags
