import { Label } from '@mui/icons-material'
import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useStyles } from './styles'

const DisplayTags = (props) => {
  const { tags = [], tagState = {}, setShowTagDrawer = () => {} } = props
  const [tagsObj, setTagsObj] = useState({})
  const classes = useStyles()

  useEffect(() => {
    const tagMap = {}
    tags.forEach((tag) => {
      const valueObj = tagState[tag]
      const { key, value, type = '' } = valueObj || {}
      const val = type?.includes('date') ? moment(value).format('ll') : value
      if (tagMap[key]) {
        tagMap[key] = [...tagMap[key], val]
      } else {
        tagMap[key] = [val]
      }
    })
    setTagsObj(tagMap)
  }, [tags])

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '2px'
      }}
      onClick={() => {
        setShowTagDrawer(true)
      }}
    >
      {Object.keys(tagsObj).map((key, index) => {
        const tagData = tagsObj[key]
        if (index > 1) return null
        return (
          <Box key={index} sx={{}} className={classes.tagWrapper}>
            <div
              style={{
                height: '8px',
                width: '8px',
                borderRadius: '100%',
                backgroundColor: getColorsForInitials(index)
              }}
            ></div>
            <Box className={classes.tagKey}>{key}</Box>
            {/* <Box className={classes.tagValue}>{tagData?.join(', ')}</Box> */}
          </Box>
        )
      })}

      {Object.keys(tagsObj).length > 2 && (
        <div
          style={{
            fontSize: '10px',
            padding: '2px 6px',
            borderRadius: '30px',
            border: '1px solid #c084fc',
            background: '#f3e8ff',
            color: '#581c87',
            cursor: 'pointer'
          }}
        >
          +{Object.keys(tagsObj).length - 2}
        </div>
      )}
      {Object.keys(tagsObj).length === 0 && (
        <div
          style={{
            fontSize: '10px',
            padding: '2px 6px',
            borderRadius: '30px',
            border: '1px solid #c084fc',
            background: '#f3e8ff',
            color: '#581c87',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '2px'
          }}
        >
          <Label
            sx={{
              height: '12px',
              width: '12px'
            }}
          />{' '}
          Add tag
        </div>
      )}
    </Box>
  )
}

export default DisplayTags

const getColorsForInitials = (i) => {
  const colors = ['#8338ec', '#007f5f', '#00296b']

  const charCode = name.charCodeAt(0)
  return colors[i]
}
