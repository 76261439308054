import { FETCH_LIBRARY, CLEAR_LIBRARY, ADD_LIBRARY } from '../../../actionTypes'
import {
  searchLibrary,
  getLibrarySuggestions,
  fetchLibraryVariations
} from '../../../api'
import { PageSize } from '../../../../config/constants'
import { textToHtml } from '../../../../utils/CopyHTML'

const formatResponse = (res, page, size) => {
  const { resultsets = [], count, has_more } = res || {}
  const libobj = []
  resultsets.forEach((ar) => {
    const {
      created_by_user,
      created_for_domain,
      es_id,
      created_at,
      library_id,
      data = {}
    } = ar
    const {
      narrative_content = '',
      narrative_title = '',
      tags = [],
      collections = {},
      metadata_,
      ...rest
    } = data
    libobj.push({
      id: library_id,
      document_name: narrative_title,
      document_html: narrative_content,
      tags,
      es_id,
      collections,
      created_by_user,
      created_for_domain,
      metadata_,
      created_at,
      status: false,
      ...rest
    })
  })
  return {
    libraryList: libobj,
    currentPage: page,
    totalCount: count,
    pageSize: size,
    has_more
  }
}

const formatResponseSuggestion = (res, page, size) => {
  const { result = [], has_more_data } = res
  result.forEach((el, index) => {
    result[index].document_html = textToHtml(el?.document_html)
  })
  return {
    libraryList: result,
    currentPage: page,
    totalCount: null,
    pageSize: size,
    has_more: has_more_data
  }
}

const fetchLibrary =
  (data, callback = () => {}, axiosProps = {}) =>
  async (dispatch) => {
    const {
      keyword = '',
      collection = '',
      domain_id,
      page_num = 1,
      page_size = PageSize,
      collectionType,
      active_status = true,
      ...rest
    } = data
    const dispatchType = page_num === 1 ? FETCH_LIBRARY : ADD_LIBRARY
    if (page_num === 1) {
      dispatch({
        type: CLEAR_LIBRARY
      })
    }

    if (collectionType === 'suggestion') {
      const body = {
        active_status,
        page_num,
        page_size,
        ...rest
      }
      if (keyword) {
        body.search_keyword = keyword
      }
      const res = await getLibrarySuggestions(body, axiosProps)
      if (res.status === 200) {
        const { result = [] } = res.data
        const formatedResponse = formatResponseSuggestion(
          res.data,
          page_num,
          page_size
        )
        if (keyword) {
          formatedResponse.search = keyword
        }
        dispatch({
          type: dispatchType,
          payload: formatedResponse,
          callback: () => {
            callback(result)
          }
        })
      } else {
        dispatch({
          type: dispatchType,
          payload: {
            libraryList: [],
            search: keyword,
            currentPage: 1,
            totalCount: 0,
            pageSize: PageSize,
            has_more: false
          },
          callback: () => {
            callback()
          }
        })
      }
    } else {
      const data = {
        search_keyword: keyword,
        collection,
        collectionType,
        page_num,
        page_size,
        ...rest
      }
      const res = await searchLibrary(data, axiosProps)
      if (res.status === 200) {
        const formatedResponse = formatResponse(res.data, page_num, page_size)
        const { resultsets = [] } = res.data
        if (keyword) {
          formatedResponse.search = keyword
        }
        dispatch({
          type: dispatchType,
          payload: formatedResponse,
          callback: () => {
            callback(resultsets)
          }
        })
      } else {
        dispatch({
          type: dispatchType,
          payload: {
            libraryList: [],
            search: keyword,
            currentPage: 1,
            totalCount: 0,
            pageSize: PageSize,
            has_more: false
          },
          callback: () => {
            callback()
          }
        })
      }
    }
  }

export { fetchLibrary }
