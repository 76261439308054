import {
  UPDATE_CHILDREN_COLLECTIONS,
  UPDATE_COLLECTIONS
} from '../../../actionTypes'
import { getCollections } from '../../../api'
import { toast } from 'react-toastify'

const collections =
  (req, callback, setLoadingState = false) =>
  async (dispatch) => {
    if (setLoadingState) {
      dispatch({
        type: UPDATE_COLLECTIONS,
        payload: { id: req, status: 'loading' },
        callback
      })
    }
    const res = await getCollections(req)
    if (res.status === 200) {
      const { data = [] } = res
      dispatch({
        type: UPDATE_CHILDREN_COLLECTIONS,
        payload: { children: data, parent_id: req },
        callback
      })
    } else {
      toast.error('Unable to get collection')
      callback && callback()
    }
    if (setLoadingState) {
      dispatch({
        type: UPDATE_COLLECTIONS,
        payload: { id: req, status: 'active' },
        callback
      })
    }
  }

export default collections
